.breadcrumb {
	color: #656565;
	font-weight: 400;
	@include font-size(14);
	padding-bottom: 40px;
	&.mb{
		margin-bottom: 90px;
		@include mq {
			margin-bottom: 40px;
		}
	}
	@include mq {
		padding: 15px 0 20px;
		@include font-size(12);
	}
	a, span {
		margin: 0 3px 0 5px;
		@include mq {
			margin: 0 3px 0 4px;
		}
	}
	.inner > a:first-child {
		color: #db3146;
		margin-right: 11px;
		@include mq {
			margin-right: 6px;
		}
		i {
			color: #222529;
			@include font-size(20);
			margin: 0 9px 0 -8px;
			@include mq {
				@include font-size(14);
				margin: 0 6px 0 -8px;
			}
		}
	}
}

.layout {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	&-l {
		width: 21.666666666%;
		padding-bottom: 70px;
		@include mq(ipd) {
			width: 100%;
			order: 1;
			padding-bottom: 40px;
		}
	}
	&-r {
		width: 78.333333334%;
		padding-left: 37px;
		padding-bottom: 58px;
		@include mq(ipd) {
			padding-left: 0;
			width: 100%;
			padding-bottom: 24px;
		}
	}
	&.has-icon {
		.layout-r {
			@include mq(ipd-min) {
				padding-bottom: 190px;
			}
		}
	}
}

.wg {
	&-search {
		margin-bottom: 64px;
		position: relative;
		@include mq {
			margin-bottom: 50px;
		}
		input {
			vertical-align: middle;
			border: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			font-weight: 400;
			@include font-size(18);
			padding: 0 59px 0 29px;
			height: 53px;
			//line-height: 53px;
			color: #666666;
			background-color: #f4f4f4;
			width: 100%;
			border-radius: 999px;
			@include mq {
				@include font-size(16);
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				padding-bottom: 1px;
			}
			&::-ms-clear {
				display: none;
			}
			&::placeholder {
				color: #666666;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: #666666;
			}
			&::-ms-input-placeholder {
				color: #666666;
			}
		}
		a,
		&__btn{
			position: absolute;
			z-index: 1;
			top: 50%;
			transform: translateY(-50%);
			right: 24px;
			border: none;
			background: none;
		}
	}
	&-menu {
		&__ttl {
			text-align: center;
			color: #333333;
			@include font-size(18);
			font-weight: 500;
			line-height: 1.78;
			margin-bottom: 33px;
			@include mq {
				@include font-size(16);
				margin-bottom: 30px;
			}
		}
		&__block {
			margin-bottom: 46px;
			@include mq {
				margin-bottom: 30px;
			}
			&:last-child {
				margin-bottom: 34px;
				@include mq {
					margin-bottom: 30px;
				}
			}
			.ttl {
				color: #ffffff;
				@include font-size(18);
				font-weight: 500;
				line-height: 1.78;
				text-align: center;
				padding: 6px;
				background-color: #df6383;
				@include mq {
					@include font-size(16);
				}
			}
			.list {
				border: 1px solid #cdcdcd;
				border-top: none;
				li {
					color: #333333;
					@include font-size(16);
					font-weight: 500;
					padding: 20px 29px;
					position: relative;
					border-bottom: 1px solid #cdcdcd;
					@include mq {
						@include font-size(14);
						padding: 15px 29px;
					}
					&:after {
						content: '';
						width: 6px;
						height: 6px;
						border: 1px solid #cdcdcd;
						border-bottom-color: transparent;
						border-left-color: transparent;
						transform: translateY(-50%) rotate(45deg);
						top: 50%;
						right: 27px;
						position: absolute;
						z-index: 1;
					}
					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
	&-image {
		text-align: center;
		li {
			margin-bottom: 10px;
		}
	}
	&-social {
		padding: 7px 0 15px;
		display: flex;
		margin: 14px 1px 0 auto;
		@include mq {
			margin: 15px 0 0 0;
		}
		a{
			display: block;
			width: 52px;
			height: 52px;
			border-radius: 52px;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			color: #FFF;
			@include font-size(24);
			background: #698dbd;
			&:hover{
				color: #FFF;
			}
		}
		li {
			margin-left: 13px;
			&:nth-of-type(2){
				a{
					/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#912edd+0,fe2075+44,fdcd55+100 */
					background: #912edd; /* Old browsers */
					background: -moz-linear-gradient(top,  #912edd 0%, #fe2075 44%, #fdcd55 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top,  #912edd 0%,#fe2075 44%,#fdcd55 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom,  #912edd 0%,#fe2075 44%,#fdcd55 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#912edd', endColorstr='#fdcd55',GradientType=0 ); /* IE6-9 */

				}
			}
			&:nth-of-type(3){
				a{
					background: #5bb5e3;
				}
			}
			&:nth-of-type(4){
				a{
					background: #d2122a;
				}
			}
		}
	}
}

.sec-list-product {
	padding: 4px 0 0;
}

.bear-msg {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	z-index: 1;
	right: 0;
	bottom: 0;
	@include mq(ipd) {
		position: relative;
		width: 100%;
		order: 2;
	}
	&__ttl {
		color: #333333;
		@include font-size(20);
		line-height: 1.6;
		font-weight: 500;
		padding: 0 10px 15px 0;
		@include mq {
			@include font-size(18);
		}
	}
}

.list-product {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px -12px;
	@include mq(sm) {
		margin: 0 -7px 0;
	}
	a {
		display: block;
	}
	.item {
		width: 25%;
		padding: 0 12px;
		margin-bottom: 60px;
		@include mq(xl) {
			width: 33.33333%;
		}
		@include mq(sm) {
			width: 50%;
			padding: 0 7px;
			margin-bottom: 30px;
		}
		&__img {
			position: relative;
			margin-bottom: 15px;
			@include mq {
				margin-bottom: 10px;
			}
			position: relative;
			padding-top: 100%;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;'
			}
			.type {
				position: absolute;
				top: 8px;
				right: 9px;
				width: 47px;
				height: 47px;
				background: #323232;
				color: white;
				font-family: $font-lato;
				@include font-size(30);
				font-weight: bold;
				line-height: 1;
				border-radius: 5px;
				text-align: center;
				small {
					display: block;
					font-weight: 500;
					@include font-size(12);
				}
			}
		}
		&__ranking {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $font-lato;
			@include font-size(24);
			font-weight: bold;
			line-height: 1;
			color: white;
			width: 48px;
			height: 48px;
			background: rgba(#db3146, 0.9);
			border-radius: 50%;
			margin: -39px 0 26px -1px;
			position: relative;
			z-index: 1;
		}
		&__status {
			font-family: $font-lato;
			@include font-size(14);
			line-height: 1;
			font-weight: 500;
			display: inline-flex;
			justify-content: center;
			text-align: center;
			text-transform: uppercase;
			min-width: 66px;
			color: white;
			padding: 2px 5px;
			background: #db3146;
			margin: 0 0 5px;
		}
		&__ttl {
			color: #323232;
			@include font-size(16);
			font-weight: 500;
			line-height: 1.37;
			@include mq {
				@include font-size(14);
			}
		}
		&__price {
			color: #db3146;
			@include font-size(24);
			line-height: 1.3;
			font-weight: 500;
			margin-bottom: 16px;
			@include mq {
				@include font-size(20);
				margin-bottom: 10px;
			}
			small {
				@include font-size(14);
				@include mq {
					@include font-size(12);
				}
			}
		}
		&:first-child, &:nth-of-type(2), &:nth-of-type(3) {
			.item__ranking {
				border-radius: 0;
				background-color: transparent;
				width: 47px;
				background-position: top left;
				background-repeat: no-repeat;
				margin-top: -45px;
				padding-top: 6px;
			}
		}
		&:first-child {
			.item__ranking {
				background-image: url("../img/index/list-product-ranking-01.png");
			}
		}
		&:nth-of-type(2) {
			.item__ranking {
				background: url("../img/index/list-product-ranking-02.png") no-repeat;
			}
		}
		&:nth-of-type(3) {
			.item__ranking {
				background: url("../img/index/list-product-ranking-03.png") no-repeat;
			}
		}
		&:first-child, &:nth-of-type(2), &:nth-of-type(3) {
			.item__img:after {
				content: '';
				position: absolute;
				z-index: 1;
				width: 51px;
				height: 44px;
				top: 8px;
				right: 10px;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
	&.ranking{
		.item:first-child .item__img:after {
			background-image: url("../img/detail/ico-1st.png");
		}
		.item:nth-of-type(2) .item__img:after {
			background-image: url("../img/detail/ico-2nd.png");
		}
		.item:nth-of-type(3) .item__img:after {
			background-image: url("../img/detail/ico-3rd.png");
		}
	}
}

.tool-bar {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.filter-func {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	color: #333333;
	font-weight: 500;
	@include font-size(18);
	line-height: 1.78;
	margin-bottom: 16px;
	padding-right: 20px;
	@include mq {
		@include font-size(16);
	}
	li {
		padding-right: 26px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			height: 18px;
			width: 1px;
			background-color: #333333;
			right: 12px;
			top: 52%;
			transform: translateY(-50%);
		}
		&:last-child {
			padding-right: 0;
			&:after {
				display: none;
			}
		}
		&.active {
			color: #dd3042;
		}
	}
}

.pager-func {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	.txt {
		flex-shrink: 0;
		color: #333333;
		@include font-size(18);
		line-height: 1.78;
		font-weight: 400;
		padding-right: 24px;
		margin-bottom: 6px;
		@include mq {
			@include font-size(16);
			padding-right: 10px;
		}
	}
	.wrap {
		display: flex;
		align-items: center;
		margin-bottom: 6px;
		a, span {
			width: 26px;
			height: 26px;
			margin-left: 7px;
			line-height: 22px;
			color: #666666;
			@include font-size(18);
			text-align: center;
			font-weight: 400;
			border-radius: 2px;
			border: 1px solid #cdcdcd;
			@include mq {
				@include font-size(16);
				width: 24px;
				height: 24px;
				margin-left: 4px;
				line-height: 20px;
			}
		}
		.current {
			color: #ffffff;
			background-color: #df6383;
			border-color: #df6383;
		}
		.no-bg {
			border: none;
			width: 20px;
			margin-left: 0;
		}
		.more {
			border: none;
			width: 22px;
			margin-left: 8px;
		}
		.next {
			position: relative;
			&:after {
				content: '';
				width: 6px;
				height: 6px;
				border: 1px solid #cdcdcd;
				border-bottom-color: transparent;
				border-left-color: transparent;
				transform: translate(-50%, -50%) rotate(45deg);
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 48%;
			}
		}
		ul{
			li{
				display: inline-block;
				span{
					border: none;
				}
			}
		}
	}
}

.prd-lst-none{
	@include font-size(16);
}