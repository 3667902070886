.lp-content{
	@include font-size(21);
	max-width: 1200px;
	margin: 0 auto;
	color: #333333;
	@include mq() {
		@include font-size(16);
	}
	.inner{
		max-width: 1130px;
	}
	.mv{
		position: relative;
		background: url("../img/lp/b/mv.png") no-repeat center;
		background-size: cover;
		min-height: 660px;
		text-align: right;
		@include mq() {
			background: none;
			min-height: inherit;
		}
		&__logo{
			position: absolute;
			top: 30px;
			left: 50px;
			max-width: 187px;
			@include mq() {
				display: none;
			}
		}
		&__inner{
			max-width: 597px;
			float: right;
			padding-top: 190px;
			@include mq {
				float: none;
				text-align: center;
				padding-top: 20px;
				max-width: 100%;
			}
		}
		&__ttl{
			display: inline-block;
			text-align: left;
			@include font-size(42);
			font-weight: bold;
			margin-bottom: 25px;
			letter-spacing: 0;
			@include mq {
				@include font-size(24);
				text-align: center;
			}
			small{
				display: block;
				@include font-size(24);
				margin-bottom: 15px;
				@include mq {
					margin-bottom: 10px;
					@include font-size(16);
				}
				span{
					color: #db3146;
					text-shadow:0px 0px 3px #ffffff,0px 0px 4px #ffffff;
				}
			}
		}
		&__tag{
			@include mq {
				width: 80%;
			}
		}
		.social-list{
			position: absolute;
			top: 20px;
			right: 50px;
			display: flex;
			margin: 14px 1px 0 auto;
			@include mq {
				margin: 15px 0 0 0;
				display: none;
			}
			a{
				display: block;
				width: 26px;
				height: 26px;
				border-radius: 26px;
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				color: #FFF;
				@include font-size(14);
				background: #698dbd;
				&:hover{
					color: #FFF !important;
				}
			}
			li {
				margin-left: 5px;
				&:nth-of-type(2){
					a{
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#912edd+0,fe2075+44,fdcd55+100 */
						background: #912edd; /* Old browsers */
						background: -moz-linear-gradient(top,  #912edd 0%, #fe2075 44%, #fdcd55 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top,  #912edd 0%,#fe2075 44%,#fdcd55 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom,  #912edd 0%,#fe2075 44%,#fdcd55 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#912edd', endColorstr='#fdcd55',GradientType=0 ); /* IE6-9 */

					}
				}
				&:nth-of-type(3){
					a{
						background: #5bb5e3;
					}
				}
				&:nth-of-type(4){
					a{
						background: #d2122a;
					}
				}
			}
		}
	}
	.sec-about{
		background: #a4dacf url("../img/lp/b/bg.png") no-repeat center top;
		background-size: 100% auto;
		padding-bottom: 70px;
		@include mq() {
			padding-bottom: 45px;
		}
		.concept-block{
			padding: 80px 0 70px;
			text-align: center;
			@include mq() {
				padding: 40px 0 30px;
			}
			&__ttl{
				border-bottom: 1px solid #333;
				display: inline-block;
				@include font-size(16);
				margin-bottom: 45px;
				@include mq() {
					margin-bottom: 30px;
				}
			}
			&__ttl02{
				@include font-size(30);
				font-weight: bold;
				line-height: 1.7;
				margin-bottom: 35px;
				@include mq() {
					@include font-size(24);
					margin-bottom: 25px;
				}
				small{
					display: block;
					@include font-size(21);
					margin-bottom: 10px;
					@include mq() {
						@include font-size(17);
					}
				}
			}
			&__txt{
				line-height: 2.2;
				@include mq() {
					text-align: left;
					line-height: 1.8;
				}
			}
		}
		.kodawari-block{
			position: relative;
			background: #FFF;
			padding: 85px 60px 65px;
			@include mq() {
				padding: 40px 20px 10px;
			}
			&__ttl{
				text-align: center;
				font-weight: bold;
				color: #db3146;
				@include font-size(30);
				margin-bottom: 70px;
				@include mq() {
					@include font-size(24);
					margin-bottom: 30px;
				}
			}
			&__en{
				position: absolute;
				top: -53px;
				left: 0;
				right: 0;
				text-align: center;
				color: #94d2c5;
				opacity: 0.15;
				@include font-size(100);
				font-family: 'Lato', sans-serif;
				font-weight: 900;
				@include mq() {
					@include font-size(40);
					top: -18px;
				}
			}
			&__row{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				.item{
					text-align: center;
					width: 30.9%;
					@include mq() {
						width: 100%;
						margin-bottom: 30px;
					}
					&__content{
						border: 1px solid #dddddd;
						border-top: none;
						padding: 35px 10px;
						@include mq() {
							padding: 25px 15px;
						}
					}
					&__img{
						position: relative;
						img{
							width: 100%;
							height: auto;
						}
						span{
							position: absolute;
							top: -30px;
							left: -15px;
							display:-webkit-box; // old
							display:-moz-box; // old
							display:-ms-flexbox; // ie
							display:-webkit-flex; // new
							display:flex; // new
							justify-content: center;
							align-items: center;
							width: 86px;
							height: 86px;
							color: #FFF;
							border-radius: 100px;
							background: #db3146;
							font-weight: bold;
							@include font-size(18);
							@include mq() {
								width: 65px;
								height: 65px;
								top: -10px;
								left: -10px;
								@include font-size(15);
							}
						}
					}
					&__ttl{
						display: inline;
						@include font-size(20);
						font-weight: bold;
						line-height: 1.6;
						background:linear-gradient(transparent 60%, #ffdaad 60%);
						@include mq() {
							@include font-size(18);
						}
					}
					&__txt{
						text-align: left;
						line-height: 1.7;
						max-width: 230px;
						margin: 30px auto 0;
						@include font-size(16);
						@include mq() {
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
	.sec-ttl{
		text-align: center;
		@include font-size(40);
		font-weight: bold;
		margin-bottom: 25px;
		@include mq() {
			@include font-size(24);
		}
		span{
			color: #db3146;
		}
		.sub-ttl{
			display: inline-block;
			color: #d73652;
			@include font-size(25);
			margin-bottom: 20px;
			font-weight: 500;
			@include mq() {
				@include font-size(20);
				margin-bottom: 10px;
			}
			&::after,
			&::before{
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin: 0 20px;
				width: 1px;
				height: 15px;
				background: #d73652;
			}
			&::after{
				-moz-transform: rotate(25deg);
				-webkit-transform: rotate(25deg);
				-o-transform: rotate(25deg);
				-ms-transform: rotate(25deg);
			}
			&::before{
				-moz-transform: rotate(-25deg);
				-webkit-transform: rotate(-25deg);
				-o-transform: rotate(-25deg);
				-ms-transform: rotate(-25deg);
			}
		}
		small{
			display: block;
			@include font-size(24);
			margin-bottom: 10px;
			@include mq() {
				@include font-size(17);
			}
		}
	}
	.lead{
		max-width: 700px;
		margin: 0 auto 50px;
		font-weight: bold;
		text-align: center;
		line-height: 2;
		@include mq() {
			max-width: 100%;
			text-align: left;
			margin-bottom: 35px;
		}
		&--style02{
			font-weight: normal;
			text-align: left;
			max-width: 660px;
			small{
				display: block;
				@include font-size(14);
				padding-top: 10px;
			}
		}
	}
	.sec-wakeari{
		padding: 115px 0 0;
		@include mq() {
			padding: 50px 0 0;
		}
		.omoi-block{
			background: url("../img/lp/b/wakeari-bg.png") no-repeat center top;
			min-height: 500px;
			background-size: cover;
			padding: 45px 50px 30px 52%;
			@include mq() {
				background: none;
				padding: 0;
				min-height: inherit;
				margin-bottom: 40px;
			}
			&__img{
				@include mq() {
					margin: 0 -15px 25px;
					img{
						width: 100%;
					}
				}
			}
			&__desc{
				margin-bottom: 30px;
				position: relative;
				left: -30px;
				@include mq() {
					width: 230px;
					left: 0;
					margin-bottom: 10px;
					margin-top: -50px;
				}
			}
			&__ttl{
				color: #db3146;
				font-weight: bold;
				line-height: 1.6;
				@include font-size(33);
				margin-bottom: 30px;
				@include mq() {
					@include font-size(22);
					margin-bottom: 20px;
				}
			}
			&__txt{
				line-height: 1.9;
				@include font-size(18);
				@include mq() {
					@include font-size(16);
				}
			}
		}
		.example-block{
			background: #f3efea;
			padding: 70px 45px 55px;
			@include mq() {
				padding: 40px 20px 15px;
			}
			&__ttl{
				position: relative;
				text-align: center;
				@include font-size(24);
				font-weight: bold;
				margin-bottom: 55px;
				@include mq() {
					@include font-size(18);
					margin-bottom: 30px;
				}
				span{
					position: relative;
					z-index: 2;
					display: inline-block;
					padding: 0 20px;
					background: #f3efea;
				}
				&::after{
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background: #333;
					position: absolute;
					top: 50%;
					z-index: 1;
				}
			}
			&__row{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				.item{
					text-align: center;
					width: 22.6%;
					margin-bottom: 35px;
					@include mq() {
						width: 48%;
						margin-bottom: 30px;
					}
					&__ttl{
						@include font-size(18);
						margin: 25px 0 0;
						line-height: 1.6;
						@include mq() {
							@include font-size(13);
							margin: 15px 0 0;
						}
					}
				}
			}
		}
	}
	.sec-otoku{
		padding: 75px 0 65px;
		text-align: center;
		@include mq() {
			padding: 50px 0 30px;
		}
		.sec-ttl{
			position: relative;
			display: inline-block;
			&::after{
				position: absolute;
				right: -105px;
				bottom: 0;
				content: "";
				display: block;
				background: url(../img/lp/b/otoku-object.png) no-repeat 0 0;
				width: 79px;
				height: 120px;
				background-size: 100% auto;
				@include mq() {
					display: none;
				}
			}
		}
		.cost-block{
			max-width: 1000px;
			margin: 0 auto;
			&__mv{
				@include mq() {
					margin: 0 -15px;
				}
				img{
					width: 100%;
				}
			}
			&__ttl{
				position: relative;
				max-width: 615px;
				margin: -30px auto 55px;
				background: #db3146;
				color: #FFF;
				@include font-size(36);
				font-weight: bold;
				line-height: 1.7;
				padding: 25px 0;
				@include mq() {
					margin-top: 0;
					max-width: inherit;
					margin: 0 -15px 50px;
					@include font-size(24);
				}
				small{
					display: block;
					@include font-size(21);
					@include mq() {
						@include font-size(16);
					}
				}
				&::after{
					position: absolute;
					left: 50%;
					bottom: -22px;
					margin-left: -11px;
					content: "";
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 22px 11.5px 0 11.5px;
					border-color: #db3145 transparent transparent transparent;
				}
			}
			&__row{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 0 30px;
				@include mq() {
					padding: 0 20px;
				}
				.item{
					width: 255px;
					text-align: left;
					@include mq(lg) {
						width: 33.333%;
						padding: 0 15px;
					}
					@include mq() {
						width: 100%;
						padding: 0;
						margin-bottom: 30px;
					}
					img{
						display: block;
						width: 251px;
						margin: 0 auto 25px;
						@include mq() {
							margin-bottom: 20px;
						}
					}
					&__ttl{
						font-weight: bold;
						color: #db3146;
						@include font-size(21);
						margin-bottom: 15px;
						@include mq() {
							margin-bottom: 10px;
							@include font-size(17);
						}
						span{
							color: #94d2c5;
							@include font-size(30);
							font-family: 'Lato', sans-serif;
							font-weight: 900;
							margin-right: 10px;
							@include mq() {
								@include font-size(24);
							}
						}
					}
					&__txt{
						line-height: 1.8;
					}
				}
			}
		}
	}
	.sec-episode{
		background: url("../img/lp/b/episode-bg.png") no-repeat center;
		background-size: cover;
		padding: 55px 0 70px;
		@include mq() {
			padding: 55px 0 50px;
		}
		.box{
			position: relative;
			padding: 65px 80px 55px;
			background: rgba(255,255,255,0.85);
			@include mq() {
				padding: 40px 20px 30px;
			}
		}
		.img-ttl{
			max-width: 288px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			display: inline-block;
			top: -20px;
			@include mq() {
				max-width: 230px;
			}
		}
		.lead{
			max-width: inherit;
		}
		.intro-block{
			margin-bottom: 40px;
			&__content{
				background: url("../img/lp/b/episode-bg02.png") no-repeat center;
				background-size: cover;
				padding: 45px 55px 50px;
				color: #FFF;
				@include mq() {
					padding: 30px 15px 35px;
				}
			}
			&__ttl{
				text-align: center;
				@include font-size(30);
				font-weight: bold;
				line-height: 1.8;
				margin-bottom: 20px;
				@include mq() {
					@include font-size(22);
				}
				small{
					display: block;
					@include font-size(21);
					@include mq() {
						@include font-size(15);
					}
				}
			}
			&__txt{
				line-height: 1.9;
			}
		}
		.point-block{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			align-items: center;
			&__ttl{
				width: 235px;
				@include mq(lg) {
					margin-bottom: 30px;
				}
				@include mq() {
					margin: 0 auto 25px;
					right: -20px;
					position: relative;
				}
			}
			&__row{
				width: calc(100% - 235px);
				padding-left: 30px;
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				@include mq(lg) {
					width: 100%;
					padding-left: 0;
				}
				.item{
					display:-webkit-box; // old
					display:-moz-box; // old
					display:-ms-flexbox; // ie
					display:-webkit-flex; // new
					display:flex; // new
					position: relative;
					width: 31%;
					background: #db3146;
					justify-content: center;
					align-items: center;
					text-align: center;
					color: #FFF;
					border-radius: 100%;
					@include font-size(24);
					line-height: 1.8;
					font-weight: bold;
					@include mq() {
						width: 100%;
						@include font-size(18);
						border-radius: 30px;
						padding: 10px;
						line-height: 1.6;
						margin-bottom: 10px;
						height: 80px;
					}
					&::after{
						content: "";
						display: block;
						padding-top: 100%;
						@include mq() {
							display: none;
						}
					}
					small{
						display: block;
						@include font-size(18);
						@include mq() {
							@include font-size(14);
						}
					}
					&__txt{
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						margin: auto;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	.sec-voice{
		padding: 100px 0 90px;
		text-align: center;
		@include mq() {
			padding: 50px 0 ;
		}
		.sec-ttl{
			position: relative;
			display: inline-block;
			margin-bottom: 45px;
			@include mq() {
				margin-bottom: 30px;
			}
			&::after{
				position: absolute;
				left: -105px;
				bottom: 0;
				content: "";
				display: block;
				background: url(../img/lp/b/voice-ico.png) no-repeat 0 0;
				width: 86px;
				height: 65px;
				background-size: 100% auto;
				@include mq() {
					display: none;
				}
			}
		}
		.voice-block{
			background: #f4f8fa;
			padding: 50px;
			@include mq() {
				padding: 35px 20px 10px;
			}
			&__row{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				.item{
					width: 48%;
					border: 1px solid #c9c9c9;
					background: #FFF;
					margin-bottom: 40px;
					padding: 40px;
					text-align: left;
					@include mq() {
						width: 100%;
						padding: 25px;
						margin-bottom: 25px;
					}
					&__head{
						display:-webkit-box; // old
						display:-moz-box; // old
						display:-ms-flexbox; // ie
						display:-webkit-flex; // new
						display:flex; // new
						justify-content: space-between;
						border-bottom: 1px solid #9d9092;
						padding-bottom: 15px;
						margin-bottom: 30px;
						@include mq() {
							flex-wrap: wrap;
						}
						.img{
							width: 76px;
							@include mq() {
								margin: 0 auto;
							}
							span{
								display: block;
								@include font-size(14);
								text-align: center;
								padding-top: 10px;
							}
						}
						.ttl{
							width: calc(100% - 76px);
							color: #db3146;
							padding-left: 30px;
							@include font-size(24);
							font-weight: bold;
							padding-top: 10px;
							@include mq() {
								width: 100%;
								@include font-size(18);
								padding-left: 0;
							}
						}
					}
					&__txt{
						line-height: 1.9;
					}
				}
			}
		}
	}
	.sec-item-search{
		padding-bottom: 100px;
		@include mq() {
			padding-bottom: 60px;
		}
		.ttl{
			background: #482f32;
			padding: 20px 0;
			@include font-size(24);
			color: #FFF;
			text-align: center;
			margin-bottom: 90px;
			@include mq() {
				@include font-size(18);
				padding: 15px 0;
				margin-bottom: 30px;
			}
		}
		.category-block{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: 40px;
			margin-bottom: 40px;
			border-bottom: 1px solid #e4e4e4;
			align-items: center;
			&__img{
				width: 53.6%;
				@include mq() {
					width: 100%;
					margin-bottom: 25px;
				}
			}
			&__content{
				width: 46.4%;
				padding-left: 45px;
				@include mq() {
					width: 100%;
					padding-left: 0;
				}
			}
			&__ttl{
				@include font-size(40);
				font-weight: bold;
				color: #db3146;
				margin-bottom: 25px;
				@include mq() {
					@include font-size(22);
					margin-bottom: 15px;
				}
			}
			&__txt{
				line-height: 1.8;
				margin-bottom: 40px;
			}
			&__btn{
				a{
					position: relative;
					display: block;
					border-radius: 40px;
					background: #db3146;
					color: #FFF;
					text-align: center;
					@include font-size(24);
					padding: 20px 0;
					@include mq() {
						@include font-size(16);
					}
					&:hover{
						color: #FFF !important;
					}
					&::after{
						position: absolute;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
						right: 20px;
						display: block;
						content: "\f054";
						font-weight: 900;
						font-family:"Font Awesome 5 Free";
						@include font-size(17);
						@include mq() {
							@include font-size(14);
						}
					}
				}
			}
		}
	}
	.more-btn{
		max-width: 530px;
		margin: 0 auto;
		a{
			position: relative;
			display: block;
			background: #482f32;
			color: #FFF;
			text-align: center;
			@include font-size(24);
			height: 113px;
			line-height: 113px;
			@include mq() {
				height: 80px;
				line-height: 80px;
				@include font-size(16);
			}
			&:hover{
				color: #FFF !important;
			}
			&::after{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				right: 20px;
				display: block;
				content: "\f054";
				font-weight: 900;
				font-family:"Font Awesome 5 Free";
				@include font-size(18);
				@include mq() {
					@include font-size(16);
				}
			}
		}
	}
}