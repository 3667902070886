.sec-main-virual {
	height: 242px;
	margin-bottom: 31px;
	background-color: #db3146;
	display: flex;
	align-items: center;
	@include mq {
		height: 121px;
		margin-bottom: 0;
	}
}

.sec-category {
	padding: 78px 0 150px;
	@include mq {
		padding: 10px 0 35px;
	}
}

.list-category {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -16px;
	@include mq {
		margin: 0 -7px;
	}
	a {
		display: block;
	}
	.item {
		padding: 0 16px;
		width: 33.33333%;
		margin-bottom: 33px;
		@include mq(xl) {
			width: 50%;
		}
		@include mq {
			padding: 0 7px;
			margin-bottom: 15px;
		}
		@media screen and (max-width: 680px) {
			width: 100%;
		}
		&__body {
			min-height: 235px;
			padding: 21px 30px 50px;
			@media screen and (max-width: 1300px) {
				padding: 20px 20px 30px;
				min-height: auto;
			}
			@include mq {
				padding: 20px 15px;
			}
		}
		&__ttl {
			text-align: center;
			color: #000000;
			@include font-size(24);
			font-weight: 500;
			line-height: 1.33;
			margin-bottom: 37px;
			@include mq {
				@include font-size(20);
				margin-bottom: 15px;
			}
			@include mq(xs) {
				@include font-size(18);
			}
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px;
			&--full{
				li{
					width: 100% !important;
				}
			}
			li {
				padding: 0 10px;
				width: 50%;
				color: #000000;
				@include font-size(18);
				font-weight: 400;
				line-height: 1.83;
				@include mq {
					@include font-size(16);
				}
				@include mq(xs) {
					@include font-size(14);
				}
				&.full{
					width: 100%;
				}
			}
		}
		&--green .item__body {
			background-color: #a5d8ce;
		}
		&--yellow .item__body {
			background-color: #ebbc83;
		}
		&--brown .item__body {
			background-color: #b4aeaf;
		}
		&--pink .item__body {
			background-color: #e3809c;
		}
		&--blue .item__body {
			background-color: #a6c1d3;
		}
		&--red .item__body {
			background-color: #ea8686;
		}
	}
}