.sec-guide{
	margin-bottom: 250px;
	@include mq() {
		margin-bottom: 80px;
	}
	.anchor-block{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 115px;
		@include mq() {
			margin-bottom: 40px;
		}
		.item{
			width: 31.5%;			
			margin-bottom: 35px;
			@include mq() {
				width: 49%;
				margin-bottom: 10px;
			}
			a{
				display: block;
				text-align: center;
				@include font-size(24);
				padding: 15px 0;
				border: 1px solid #333333;
				@include mq(lg) {
					@include font-size(18);
				}
				@include mq() {
					@include font-size(12);
				}
				&:hover{
					background: #333333;
					color: #FFF;
				}
			}
			i{
				margin-right: 15px;
				@include mq() {
					margin-right: 5px;
				}
			}
		}
	}
	.intro-block{
		margin-bottom: 110px;
		@include mq() {
			margin-bottom: 55px;
		}
		.ttl{
			@include font-size(24);
			border-bottom: 1px solid #000;
			padding-bottom: 15px;
			margin-bottom: 50px;
			@include mq() {
				@include font-size(17);
				margin-bottom: 30px;
			}
		}
		.ttl02{
			@include font-size(24);
			background: #94d2c5;
			padding: 10px 15px;
			margin-bottom: 30px;
			color: #FFF;
			@include mq() {
				@include font-size(17);
			}
		}
		table{
			width: 100%;
			th,td{
				width: 50%;
				border: 1px solid #e0e0e0;
				padding: 25px 20px;
				text-align: center;
				@include font-size(21);
				@include mq() {
					@include font-size(13);
					padding: 15px 5px;
				}
			}
			th{
				background: #f3f3f3;
				font-weight: 500;
			}
		}
		p{
			@include font-size(18);
			margin-bottom: 35px;
			line-height: 1.7;
			@include mq() {
				@include font-size(14);
				margin-bottom: 25px;
			}
		}
		.section{
			margin-bottom: 70px;
			@include mq() {
				margin-bottom: 30px;
			}
		}
	}
}