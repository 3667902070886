/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&display=swap");
html {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html.no-scroll {
  overflow: hidden;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  outline: none;
}

input::-ms-clear, textarea::-ms-clear, select::-ms-clear {
  display: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

.inner {
  display: block;
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
  color: inherit !important;
  text-decoration: none !important;
}

@media screen and (min-width: 1025px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

@media screen and (min-width: 375px) {
  .sp-xs {
    display: none !important;
  }
}

@media screen and (min-width: 376px) {
  .sp-375 {
    display: none !important;
  }
}

.ttl-comp01 {
  border-bottom: 1px solid #333333;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.4;
  padding-bottom: 13px;
  margin-bottom: 41px;
}

@media screen and (max-width: 834px) {
  .ttl-comp01 {
    font-size: 12px;
    font-size: 1.2rem;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}

.ttl-comp01 .en {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #db3146;
  margin-right: 18px;
}

@media screen and (max-width: 834px) {
  .ttl-comp01 .en {
    font-size: 20px;
    font-size: 2rem;
    margin-right: 10px;
  }
}

.ttl-comp02 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2;
}

@media screen and (max-width: 1200px) {
  .ttl-comp02 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .ttl-comp02 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 374px) {
  .ttl-comp02 {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.ttl-comp02 .en {
  font-family: "Lato", sans-serif;
  font-size: 48px;
  font-size: 4.8rem;
  line-height: 1;
  font-weight: bold;
  color: #db3146;
  display: block;
  margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .ttl-comp02 .en {
    font-size: 40px;
    font-size: 4rem;
  }
}

@media screen and (max-width: 834px) {
  .ttl-comp02 .en {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 374px) {
  .ttl-comp02 .en {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.ttl-comp02--line {
  position: relative;
  margin-bottom: 36px;
}

@media screen and (max-width: 834px) {
  .ttl-comp02--line {
    margin-bottom: 30px;
  }
}

.ttl-comp02--line:before {
  position: absolute;
  content: '';
  height: 1px;
  background: #333333;
  left: 0;
  right: 0;
  top: 48.5%;
}

.ttl-comp02--line .ttl-comp02__wrap {
  background: white;
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  padding-right: 17px;
}

@media screen and (max-width: 640px) {
  .ttl-comp02--line .ttl-comp02__wrap {
    display: block;
    padding: 0;
  }
}

.ttl-comp02--line .ttl-comp02__wrap .en {
  margin-right: 17px;
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .ttl-comp02--line .ttl-comp02__wrap .en {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.ttl-comp02--line .txt-more {
  position: absolute;
  padding-left: 15px;
  background: white;
  right: 0;
  top: 46%;
  transform: translateY(-50%);
  z-index: 1;
}

@media screen and (max-width: 640px) {
  .ttl-comp02--line .txt-more {
    bottom: 0;
    top: auto;
    transform: translate(0);
  }
}

.ttl-comp02--line .txt-more:hover {
  opacity: 1;
}

.ttl-comp02--ico {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}

.ttl-comp02--ico .ttl-wrap {
  position: relative;
}

.ttl-comp02--ico .ttl-wrap .ico {
  position: absolute;
  left: 0;
  top: -12px;
  transform: translateX(-124%);
}

.ttl-comp03 {
  padding-left: 20px;
  color: #000000;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 31px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .ttl-comp03 {
    font-size: 20px;
    font-size: 2rem;
    padding-left: 15px;
    margin-bottom: 20px;
  }
}

.ttl-comp03:before {
  content: '';
  position: absolute;
  width: 6px;
  top: 4px;
  bottom: 1px;
  left: 0;
  background-color: #db3146;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .ttl-comp03:before {
    width: 3px;
  }
}

.ttl-comp04 {
  color: #ffffff;
  font-weight: 500;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.46;
  padding: 9px 31px 11px;
  margin-bottom: 39px;
}

@media screen and (max-width: 834px) {
  .ttl-comp04 {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 6px 15px 8px;
    margin-bottom: 30px;
  }
}

.ttl-comp04--green {
  background-color: #95d2c6;
}

.ttl-comp04--yellow {
  background-color: #eabb82;
}

.ttl-comp04--red {
  background-color: #e98585;
}

.ttl-comp04--blue {
  background-color: #a5c0d2;
}

.ttl-comp05 {
  color: #FFFFFF;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 834px) {
  .ttl-comp05 {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.ttl-comp05 small {
  display: block;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
  margin: 11px 0 13px;
}

@media screen and (max-width: 834px) {
  .ttl-comp05 small {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 4px 0 2px;
  }
}

.ttl-comp06 {
  text-align: center;
  color: #000000;
  font-weight: 500;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 59px;
}

@media screen and (max-width: 834px) {
  .ttl-comp06 {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

.ttl-comp07 {
  color: #000000;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  padding-bottom: 18px;
  border-bottom: 1px solid #000000;
  margin-bottom: 49px;
}

@media screen and (max-width: 834px) {
  .ttl-comp07 {
    padding-bottom: 10px;
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

.second-head {
  background: #db3146;
  height: 240px;
  color: #FFF;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .second-head {
    height: 120px;
  }
}

.second-head__ttl {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.2;
}

@media screen and (max-width: 834px) {
  .second-head__ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.second-head__ttl span {
  display: block;
}

.second-head__ttl span.en {
  font-weight: bold;
}

.second-head__ttl small {
  font-size: 14px;
  font-size: 1.4rem;
}

.txt-basic {
  line-height: 2;
  font-size: 21px;
  font-size: 2.1rem;
  letter-spacing: -0.5px;
}

@media screen and (max-width: 1200px) {
  .txt-basic {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .txt-basic {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: 374px) {
  .txt-basic {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.txt-more {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .txt-more {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .txt-more {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.txt-more .fas {
  margin-left: 6px;
  font-size: 17px;
  font-size: 1.7rem;
}

@media screen and (max-width: 1200px) {
  .txt-more .fas {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 834px) {
  .txt-more .fas {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.btn-comp03 {
  cursor: pointer;
  display: block;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4;
  padding: 13px 10px 14px;
  background-color: #dd3042;
  display: block;
  font-family: "Noto Sans JP", sans-serif;
  border: none;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.btn-comp03:hover {
  opacity: 0.7;
  color: #FFF !important;
}

@media screen and (max-width: 834px) {
  .btn-comp03 {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 8px 5px 10px;
  }
}

@media screen and (max-width: 374px) {
  .btn-comp03 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 835px) {
  .btn-comp03--md {
    padding: 18px 10px 19px;
  }
}

.btn-comp03--xl {
  font-size: 24px;
  font-size: 2.4rem;
  padding: 48px;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  max-width: 610px;
}

@media screen and (max-width: 834px) {
  .btn-comp03--xl {
    padding: 20px;
  }
}

.btn-comp03--black {
  background-color: #333333;
}

.btn-comp04 {
  display: inline-block;
  border-radius: 6px;
  color: #323232;
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #eeeeee;
  padding: 11px 25px 13px;
}

@media screen and (max-width: 834px) {
  .btn-comp04 {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 11px 25px 12px;
  }
}

.btn-comp04 i {
  margin-left: 7px;
}

body {
  color: #323232;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
}

.header {
  height: 290px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4;
  padding-top: 23px;
  background: white;
  position: relative;
  z-index: 999;
}

@media screen and (max-width: 1200px) {
  .header {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 834px) {
  .header {
    padding: 10px 0;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.header__img {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
}

.header__img span {
  display: block;
  text-align: center;
}

.header__top {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

@media screen and (max-width: 834px) {
  .header__top {
    margin-bottom: 0;
  }
}

.header__logo {
  padding-right: 15px;
  color: #000;
  text-decoration: none;
}

@media screen and (max-width: 834px) {
  .header__logo img {
    width: 254px;
  }
}

.header__logo span {
  display: block;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .header__logo span {
    margin-bottom: 5px;
    font-size: 10px;
  }
}

.header-search {
  margin-left: auto;
  width: 100%;
  max-width: 291px;
  position: relative;
  margin-top: 10px;
}

@media screen and (max-width: 834px) {
  .header-search {
    margin: 30px auto;
  }
}

.header-search__inp {
  display: block;
  width: 100%;
  border-radius: 100px;
  height: 54px;
  background: #f4f4f4;
  border: none;
  padding: 3px 60px 5px 27px;
  color: #878787;
  font-weight: 500;
  font-family: inherit;
  outline: none;
}

@media screen and (max-width: 834px) {
  .header-search__inp {
    height: 40px;
  }
}

.header-search__btn {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 24px;
  font-size: 24px;
  font-size: 2.4rem;
  color: #999999;
  background: transparent;
  border: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}

@media screen and (min-width: 835px) {
  .header-search__btn:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 834px) {
  .header-search__btn {
    font-size: 20px;
    font-size: 2rem;
    right: 10px;
  }
}

@media screen and (min-width: 835px) {
  .header__nav {
    display: flex;
    align-items: flex-start;
  }
}

@media screen and (max-width: 834px) {
  .header__nav {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;
    background: white;
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 999;
  }
  .header__nav.on {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 834px) {
  .header__img {
    text-align: center;
    margin-bottom: 30px;
    display: none;
  }
}

.header-menu {
  display: flex;
  margin-left: auto;
  text-align: center;
  padding-top: 20px;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .header-menu {
    padding-top: 30px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu {
    padding: 0;
    margin: 0 -15px 50px;
    flex-wrap: wrap;
    align-items: stretch;
    border-top: 1px solid #323232;
    border-bottom: 1px solid #323232;
  }
}

.header-menu__item {
  margin-left: 25px;
}

@media screen and (max-width: 1200px) {
  .header-menu__item {
    margin-left: 20px;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item {
    width: 50%;
    margin: 0;
    border-bottom: 1px solid #323232;
  }
  .header-menu__item:nth-child(odd) {
    border-right: 1px solid #323232;
  }
}

@media screen and (max-width: 834px) {
  .header-menu__item a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 10px;
  }
}

.header-menu__item a:hover {
  opacity: 1;
}

.header-menu__item a:hover .ico {
  color: #db3146;
}

.header-menu__item a .ico {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  display: block;
  margin-bottom: 2px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 1200px) {
  .header-menu__item a .ico {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu__item a .ico {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.header-menu__item.active a .ico {
  color: #db3146;
}

.header__control {
  display: block;
  width: 30px;
  height: 20px;
  position: relative;
  margin-left: auto;
}

@media screen and (min-width: 835px) {
  .header__control {
    display: none;
  }
}

.header__control span {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #323232;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.header__control span:first-child {
  top: 0;
}

.header__control span:nth-of-type(2) {
  top: 46%;
}

.header__control span:last-child {
  bottom: 0;
}

.header__control.close span:first-child {
  transform: rotate(45deg);
  top: 46%;
}

.header__control.close span:nth-of-type(2) {
  opacity: 0;
}

.header__control.close span:last-child {
  transform: rotate(-45deg);
  bottom: 46%;
}

@media screen and (min-width: 835px) {
  .header .fnav-list {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .header .fnav-list .fnav-list__item {
    min-height: 75px;
  }
}

@media screen and (max-width: 834px) {
  .header .fnav-list .fnav-list__wrap {
    padding: 10px 5px 5px;
  }
}

@media screen and (max-width: 834px) {
  .header .fnav-list .fnav-list__wrap .img {
    min-height: 20px;
  }
}

@media screen and (max-width: 834px) {
  .header .fnav-list .fnav-list__wrap .txt {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 834px) {
  .main-page {
    margin-top: 60px;
  }
}

.sec-info {
  padding: 132px 0 123px;
  border-top: 1px solid #dddddd;
}

@media screen and (max-width: 1200px) {
  .sec-info {
    padding: 90px 0;
  }
}

@media screen and (max-width: 834px) {
  .sec-info {
    padding: 50px 0;
  }
}

.info-flex {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
}

@media screen and (max-width: 1200px) {
  .info-flex {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.info-flex .txt-lg {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  font-weight: bold;
  display: block;
  margin-bottom: 2px;
}

@media screen and (max-width: 1200px) {
  .info-flex .txt-lg {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.info-flex__item {
  width: calc(33.33% - 32px);
  margin-right: 48px;
}

@media screen and (min-width: 1025px) {
  .info-flex__item:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .info-flex__item {
    width: calc(33.33% - 10px);
    margin-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .info-flex__item {
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 50px;
  }
  .info-flex__item:nth-child(2n+2) {
    margin-right: 0;
  }
  .info-flex__item:last-child {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 834px) {
  .info-flex__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .info-flex__item:last-child {
    margin-bottom: 0;
  }
  .info-flex__item .fc table {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .info-flex__item .fc .fc-col-header-cell-cushion {
    padding: 6px;
  }
}

.info-new__item {
  border-bottom: 1px dotted #cccccc;
  padding-bottom: 32px;
  margin-bottom: 31px;
}

@media screen and (max-width: 834px) {
  .info-new__item {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.info-new__item:last-child {
  margin-bottom: 0;
}

.info-new__item a {
  display: block;
}

.info-new__item .date {
  display: block;
  font-family: "Lato", sans-serif;
  color: #db3146;
  margin-bottom: 8px;
}

.info-mail__txt {
  margin-bottom: 41px;
}

@media screen and (max-width: 834px) {
  .info-mail__txt {
    margin-bottom: 15px;
  }
}

.info-mail__form {
  position: relative;
}

.info-mail__form .inp {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6;
  font-family: "Noto Sans JP", sans-serif;
  width: 100%;
  display: block;
  background: white;
  border: 1px solid #bbbbbb;
  height: 67px;
  color: #878787;
  padding: 5px 135px 6px 25px;
  border-radius: 0;
}

@media screen and (max-width: 1200px) {
  .info-mail__form .inp {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .info-mail__form .inp {
    height: 45px;
  }
}

.info-mail__form .btn {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6;
  font-family: "Noto Sans JP", sans-serif;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: none;
  background: #333333;
  color: white;
  text-align: center;
  width: 129px;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 1200px) {
  .info-mail__form .btn {
    font-size: 14px;
    font-size: 1.4rem;
    width: 100px;
  }
}

.info-mail__form .btn:hover {
  opacity: 0.7;
}

.info-mail__form .btn.mac {
  top: -2px;
  bottom: -2px;
}

.fnav-list {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  text-align: center;
  margin-bottom: 125px;
}

@media screen and (max-width: 1200px) {
  .fnav-list {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 834px) {
  .fnav-list {
    margin-bottom: 50px;
  }
}

.fnav-list__item {
  width: 20%;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  min-height: 120px;
}

@media screen and (max-width: 834px) {
  .fnav-list__item {
    width: 25%;
    min-height: 100px;
  }
}

@media screen and (max-width: 640px) {
  .fnav-list__item {
    width: 50%;
  }
}

.fnav-list__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 11px;
}

@media screen and (max-width: 1200px) {
  .fnav-list__wrap {
    flex-wrap: wrap;
  }
}

.fnav-list__wrap .img {
  margin-right: 12px;
}

@media screen and (max-width: 1200px) {
  .fnav-list__wrap .img {
    width: 100%;
    min-height: 54px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-right: 0;
  }
}

@media screen and (max-width: 834px) {
  .fnav-list__wrap .img {
    min-height: 30px;
  }
}

.fnav-list__wrap .txt {
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 1.4;
}

@media screen and (max-width: 1200px) {
  .fnav-list__wrap .txt {
    font-size: 18px;
    font-size: 1.8rem;
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .fnav-list__wrap .txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.fnav-list__wrap .txt .en {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
  margin-top: 0;
}

@media screen and (max-width: 1200px) {
  .fnav-list__wrap .txt .en {
    font-size: 10px;
    font-size: 1rem;
  }
}

.footer {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.67;
}

.footer__inner {
  width: 100%;
  max-width: 1614px;
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (max-width: 1770px) {
  .footer__inner {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1200px) {
  .footer__inner {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.footer__top {
  background: #f9f9f9;
  border-top: 1px solid #dddddd;
  padding: 99px 0 15px;
}

@media screen and (max-width: 834px) {
  .footer__top {
    padding: 50px 0 15px;
  }
}

.footer__top .footer__inner {
  display: flex;
  flex-wrap: wrap;
}

.footer__top .footer__inner .item {
  width: calc(33.33% - 28px);
  margin-right: 42px;
}

@media screen and (max-width: 1770px) {
  .footer__top .footer__inner .item {
    width: calc(33.33% - 10px);
    margin-right: 15px;
  }
}

@media screen and (min-width: 1201px) {
  .footer__top .footer__inner .item:nth-of-type(3n+3) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .footer__top .footer__inner .item {
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .footer__top .footer__inner .item:nth-of-type(2n+2) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .footer__top .footer__inner .item {
    width: 100%;
    margin-right: 0;
  }
  .footer__top .footer__inner .item:last-child {
    margin-bottom: 0;
  }
}

.footer__top .footer__inner .item .ttl {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.4;
  font-weight: 500;
  border-bottom: 1px solid #b7b6b6;
  padding-bottom: 11px;
  margin-bottom: 38px;
}

@media screen and (max-width: 1770px) {
  .footer__top .footer__inner .item .ttl {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1200px) {
  .footer__top .footer__inner .item .ttl {
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 20px;
  }
}

.footer__top .footer__inner .item .list li {
  margin-bottom: 47px;
}

@media screen and (max-width: 834px) {
  .footer__top .footer__inner .item .list li {
    margin-bottom: 20px;
  }
}

.footer__top .footer__inner .item .list li:last-child {
  margin-bottom: 0;
}

.footer__top .footer__inner .item .list li .txt-lg {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.72;
  font-weight: 500;
  display: block;
}

@media screen and (max-width: 1770px) {
  .footer__top .footer__inner .item .list li .txt-lg {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1200px) {
  .footer__top .footer__inner .item .list li .txt-lg {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.footer__top .footer__inner .item .list li .txt-lg + img {
  margin: 9px 0 26px 2px;
  display: block;
}

.footer__bottom {
  background: #db3146;
  color: white;
  padding: 41px 0 48px;
}

@media screen and (max-width: 834px) {
  .footer__bottom {
    padding: 50px 0;
  }
}

.footer__bottom .footer__inner {
  max-width: 1660px;
}

@media screen and (min-width: 1771px) {
  .footer__bottom .footer__inner {
    padding-left: 38px;
  }
}

@media screen and (min-width: 1441px) {
  .footer__bottom .footer__inner {
    display: flex;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner {
    text-align: center;
  }
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner .left {
    margin: 0 0 30px;
  }
}

.footer__bottom .footer__inner .left .logo-f {
  margin: 0 0 33px 8px;
  display: block;
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner .left .logo-f {
    margin: 0 0 30px;
  }
}

@media screen and (min-width: 1025px) and (-ms-high-contrast: active), screen and (min-width: 1025px) and (-ms-high-contrast: none) {
  .footer__bottom .footer__inner .left .info[href^="tel"] {
    display: inline-block;
  }
}

.footer__bottom .footer__inner .right {
  margin-left: auto;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 500;
  padding-top: 26px;
}

@media screen and (max-width: 1770px) {
  .footer__bottom .footer__inner .right {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner .right {
    padding: 0;
  }
}

@media screen and (max-width: 1200px) {
  .footer__bottom .footer__inner .right {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.footer__bottom .footer__inner .right .menu {
  display: flex;
  margin-bottom: 117px;
}

@media screen and (max-width: 1770px) {
  .footer__bottom .footer__inner .right .menu {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner .right .menu {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.footer__bottom .footer__inner .right .menu li {
  margin-left: 21px;
}

@media screen and (max-width: 1770px) {
  .footer__bottom .footer__inner .right .menu li {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner .right .menu li {
    margin: 0 5px 10px;
  }
}

.footer__bottom .footer__inner .right .menu li a {
  display: block;
  padding: 6px 15px 6px 12px;
  border-radius: 2px;
}

@media screen and (min-width: 835px) {
  .footer__bottom .footer__inner .right .menu li a:hover {
    opacity: 1;
    background: white;
    color: #db3146 !important;
  }
}

.footer__bottom .footer__inner .right .menu li.active a {
  opacity: 1;
  background: white;
  color: #db3146 !important;
}

.footer__bottom .footer__inner .right .copyright {
  font-family: "Lato", sans-serif;
  text-align: right;
  letter-spacing: 0.2px;
  color: #eeeeee;
}

@media screen and (max-width: 1440px) {
  .footer__bottom .footer__inner .right .copyright {
    text-align: center;
  }
}

.footer__txt {
  margin-top: 50px;
}

@media screen and (max-width: 1770px) {
  .footer__txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1200px) {
  .footer__txt {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.footer__txt a {
  text-decoration: underline;
  text-underline-position: under;
}

.footer__txt a:hover {
  text-decoration: underline !important;
}

.sec-mv {
  z-index: 1;
  position: relative;
}

.mv-ttl {
  text-align: center;
  padding: 15px 35px 19px 15px;
  background: #db3146;
  color: white;
  letter-spacing: -0.4px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.6;
}

@media screen and (max-width: 1200px) {
  .mv-ttl {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 834px) {
  .mv-ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 374px) {
  .mv-ttl {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.mv-ttl strong {
  font-weight: bold;
  margin: 0 10px 0 24px;
  letter-spacing: 1.25px;
}

.mv-ttl .txt-lg {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.2;
}

@media screen and (max-width: 1200px) {
  .mv-ttl .txt-lg {
    font-size: 24px;
    font-size: 2.4rem;
    margin: 0 10px;
  }
}

@media screen and (max-width: 834px) {
  .mv-ttl .txt-lg {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 640px) {
  .mv-ttl .txt-lg {
    margin: 0;
  }
}

@media screen and (max-width: 374px) {
  .mv-ttl .txt-lg {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.mv-ttl .txt-lg02 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4;
}

@media screen and (max-width: 1200px) {
  .mv-ttl .txt-lg02 {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 834px) {
  .mv-ttl .txt-lg02 {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 374px) {
  .mv-ttl .txt-lg02 {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.mv-ttl small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.7;
  letter-spacing: 0;
}

@media screen and (max-width: 1200px) {
  .mv-ttl small {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .mv-ttl small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.mv-slider:before, .mv-slider:after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  width: 16.15%;
  z-index: 1;
  background: rgba(60, 60, 60, 0.5);
}

@media screen and (max-width: 834px) {
  .mv-slider:before, .mv-slider:after {
    width: 8%;
  }
}

@media screen and (max-width: 640px) {
  .mv-slider:before, .mv-slider:after {
    width: 40px;
  }
}

.mv-slider:before {
  left: 0;
}

.mv-slider:after {
  right: 0;
}

.mv-slider .slick-arrow {
  z-index: 2;
  width: 21px;
  height: 33px;
  top: 49.6%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-arrow {
    width: 15px;
    height: 24px;
  }
}

.mv-slider .slick-arrow:before {
  display: none;
}

.mv-slider .slick-prev {
  left: 13.35%;
  background-image: url("../img/index/mv-arrow-prev.png");
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-prev {
    left: 5%;
  }
}

@media screen and (max-width: 640px) {
  .mv-slider .slick-prev {
    left: 20px;
  }
}

.mv-slider .slick-next {
  right: 13.65%;
  background-image: url("../img/index/mv-arrow-next.png");
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-next {
    right: 5%;
  }
}

@media screen and (max-width: 640px) {
  .mv-slider .slick-next {
    right: 20px;
  }
}

.mv-slider .slick-dots {
  bottom: -45px;
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-dots {
    bottom: -30px;
  }
}

.mv-slider .slick-dots li {
  margin: 0 11px 0 10px;
}

@media screen and (max-width: 1200px) {
  .mv-slider .slick-dots li {
    margin: 0 7px;
  }
}

.mv-slider .slick-dots li button {
  padding: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #c8c8c8;
}

@media screen and (max-width: 834px) {
  .mv-slider .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}

.mv-slider .slick-dots li button:before {
  display: none;
}

.mv-slider .slick-dots li.slick-active button {
  background: #666666;
}

.mv-slider__item {
  outline: none !important;
}

.mv-slider__item img {
  display: block;
  width: 100%;
}

.sec-advertise {
  background: #eeeeee;
  padding: 180px 0 72px;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .sec-advertise {
    padding: 80px 0 50px;
  }
}

.advertise-sale {
  margin-bottom: 52px;
}

@media screen and (max-width: 834px) {
  .advertise-sale {
    margin-bottom: 50px;
  }
}

.advertise-sale__item {
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .advertise-sale__item {
    margin-bottom: 30px;
  }
}

.advertise-sale__item:last-child {
  margin-bottom: 0;
}

.advertise-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -17.5px;
}

@media screen and (max-width: 1024px) {
  .advertise-list {
    margin: 0 -10px;
  }
}

@media screen and (max-width: 640px) {
  .advertise-list {
    margin: 0;
  }
}

.advertise-list__item {
  width: 33.33%;
  padding: 0 17.5px;
  margin-bottom: 35px;
  min-height: 240px;
}

@media screen and (max-width: 1024px) {
  .advertise-list__item {
    padding: 0 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 990px) {
  .advertise-list__item {
    width: 50%;
  }
}

@media screen and (max-width: 834px) {
  .advertise-list__item {
    width: 48%;
    padding: 0;
    margin-right: 4%;
    margin-bottom: 30px;
  }
  .advertise-list__item:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 835px) {
  .advertise-list__item--second .advertise-list__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 835px) and (-ms-high-contrast: active), screen and (min-width: 835px) and (-ms-high-contrast: none) {
  .advertise-list__item--second .advertise-list__wrap {
    position: relative;
    display: block;
  }
}

@media screen and (min-width: 835px) {
  .advertise-list__item--second .advertise-list__wrap .content {
    width: 90%;
    max-width: 298px;
    height: 63.5%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 10px 10px 10px;
  }
}

@media screen and (min-width: 835px) and (-ms-high-contrast: active), screen and (min-width: 835px) and (-ms-high-contrast: none) {
  .advertise-list__item--second .advertise-list__wrap .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 835px) {
  .advertise-list__item--second .advertise-list__wrap .content .txt {
    min-height: auto;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 834px) {
  .advertise-list__item--second .advertise-list__wrap .content .txt {
    top: 6.5vw;
    position: relative;
  }
}

.advertise-list__wrap {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .advertise-list__wrap {
    overflow: hidden;
  }
}

.advertise-list__wrap .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .advertise-list__wrap .img {
    position: static;
  }
}

.advertise-list__wrap .img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.advertise-list__wrap .content {
  width: 70%;
  max-width: 205px;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  padding: 10px 20px 35px 34px;
  height: 100%;
  flex-wrap: wrap;
  background: rgba(148, 210, 197, 0.8);
}

@media screen and (max-width: 834px) {
  .advertise-list__wrap .content {
    width: 100%;
    max-width: 100%;
    text-align: center;
    padding: 15px 15px 20px;
    display: block;
  }
}

.advertise-list__wrap .content--orange {
  background: rgba(245, 184, 110, 0.8);
}

.advertise-list__wrap .content--pink {
  background: rgba(224, 99, 131, 0.8);
}

.advertise-list__wrap .content--grayish {
  background: rgba(163, 155, 155, 0.8);
}

.advertise-list__wrap .content--blue {
  background: rgba(149, 182, 201, 0.8);
}

.advertise-list__wrap .content--red {
  background: rgba(219, 49, 70, 0.8);
}

.advertise-list__wrap .content .txt {
  width: 100%;
  font-weight: bold;
  color: white;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.43;
  margin-bottom: 20px;
  min-height: 96px;
}

@media screen and (max-width: 1200px) {
  .advertise-list__wrap .content .txt {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .advertise-list__wrap .content .txt {
    min-height: inherit;
  }
}

.advertise-list__wrap .content .txt small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.875;
  display: block;
  margin-bottom: 2px;
}

@media screen and (max-width: 1200px) {
  .advertise-list__wrap .content .txt small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.advertise-list__wrap .content .txt-lg {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.2;
}

@media screen and (max-width: 1200px) {
  .advertise-list__wrap .content .txt-lg {
    font-size: 21px;
    font-size: 2.1rem;
  }
}

.advertise-list__wrap .content .btn {
  display: block;
  width: 100%;
  max-width: 137px;
  border: 1px solid white;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
  line-height: 1.4;
  padding: 10px 10px 10px 10px;
  background: none;
  border-radius: 0;
}

@media screen and (max-width: 834px) {
  .advertise-list__wrap .content .btn {
    margin: 0 auto;
  }
}

.advertise-list__wrap .content .btn:hover {
  color: white !important;
}

.sec-making {
  padding: 94px 0 91px;
}

@media screen and (max-width: 834px) {
  .sec-making {
    padding: 50px 0;
  }
}

.making-bnr {
  margin-bottom: 56px;
}

@media screen and (max-width: 1200px) {
  .making-bnr {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 834px) {
  .making-bnr {
    margin-bottom: 20px;
  }
}

.making-bnr .img {
  margin-bottom: 22px;
}

@media screen and (max-width: 834px) {
  .making-bnr .img {
    margin-bottom: 15px;
  }
}

.making-bnr .txt {
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .making-bnr .txt {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .making-bnr .txt {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.making__item {
  margin-bottom: 141px;
}

@media screen and (max-width: 834px) {
  .making__item {
    margin-bottom: 50px;
  }
}

.making__item:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .making__item .list-product {
    margin-bottom: -30px;
  }
}

.recommend-box__item {
  margin-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .recommend-box__item {
    margin-bottom: 50px;
  }
}

.recommend-box__item:last-child {
  margin-bottom: 0;
}

.recommend-box__head {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .recommend-box__head {
    margin-bottom: 30px;
  }
}

.recommend-box__head .txt-more {
  margin-left: auto;
}

.recommend-box__head .ttl {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .recommend-box__head .ttl {
    font-size: 21px;
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 834px) {
  .recommend-box__head .ttl {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.recommend-box__head .ttl .en {
  margin-left: 10px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .recommend-box__head .ttl .en {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.list-product.list-product--ranking {
  counter-reset: ranking;
}

.list-product.list-product--ranking .item__img {
  margin-bottom: 44px;
}

@media screen and (max-width: 834px) {
  .list-product.list-product--ranking .item__img {
    margin-bottom: 30px;
  }
}

.list-product.list-product--ranking .item__img:before {
  counter-increment: ranking;
  content: counter(ranking);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1;
  color: white;
  width: 48px;
  height: 48px;
  background: rgba(219, 49, 70, 0.9);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  bottom: -21px;
  left: -3px;
}

.list-product.list-product--ranking .item:first-child .item__img:before, .list-product.list-product--ranking .item:nth-of-type(2) .item__img:before, .list-product.list-product--ranking .item:nth-of-type(3) .item__img:before {
  border-radius: 0;
  background-color: transparent;
  width: 47px;
  background-position: top left;
  background-repeat: no-repeat;
  left: -1px;
  bottom: -18px;
  padding-top: 5px;
  height: 43px;
}

.list-product.list-product--ranking .item:first-child .item__img:before {
  background-image: url(../img/index/list-product-ranking-01.png);
}

.list-product.list-product--ranking .item:nth-of-type(2) .item__img:before {
  background: url(../img/index/list-product-ranking-02.png) no-repeat;
}

.list-product.list-product--ranking .item:nth-of-type(3) .item__img:before {
  background: url(../img/index/list-product-ranking-03.png) no-repeat;
}

.about-head {
  padding: 121px 0 139px;
  background: url("../img/index/about-head-bg.jpg") no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1200px) {
  .about-head {
    padding: 100px 0;
  }
}

@media screen and (max-width: 834px) {
  .about-head {
    padding: 50px 0;
  }
}

.about-head__box {
  width: 100%;
  max-width: 600px;
}

.about-head__box .flex {
  display: flex;
  margin-bottom: 60px;
}

@media screen and (max-width: 640px) {
  .about-head__box .flex {
    display: block;
    margin-bottom: 30px;
  }
}

.about-head__box .flex .social-list {
  display: flex;
  margin: 14px 1px 0 auto;
}

@media screen and (max-width: 834px) {
  .about-head__box .flex .social-list {
    margin: 15px 0 0 0;
  }
}

.about-head__box .flex .social-list a {
  display: block;
  width: 52px;
  height: 52px;
  border-radius: 52px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 24px;
  font-size: 2.4rem;
  background: #698dbd;
}

.about-head__box .flex .social-list a:hover {
  color: #FFF;
}

.about-head__box .flex .social-list li {
  margin-left: 13px;
}

.about-head__box .flex .social-list li:nth-of-type(2) a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#912edd+0,fe2075+44,fdcd55+100 */
  background: #912edd;
  /* Old browsers */
  background: -moz-linear-gradient(top, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#912edd', endColorstr='#fdcd55',GradientType=0 );
  /* IE6-9 */
}

.about-head__box .flex .social-list li:nth-of-type(3) a {
  background: #5bb5e3;
}

.about-head__box .flex .social-list li:nth-of-type(4) a {
  background: #d2122a;
}

.about__body {
  background: #eeeeee;
  padding: 123px 0 0;
}

@media screen and (max-width: 1200px) {
  .about__body {
    padding-top: 100px;
  }
}

@media screen and (max-width: 834px) {
  .about__body {
    padding-top: 50px;
  }
}

.about__body .inner {
  padding-bottom: 280px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .about__body .inner {
    padding-bottom: 210px;
  }
}

.about__body .inner .about-object {
  position: absolute;
  bottom: -3px;
  right: 32px;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .about__body .inner .about-object {
    width: calc(100% - 30px);
    max-width: 360px;
  }
}

@media screen and (max-width: 640px) {
  .about__body .inner .about-object {
    right: 15px;
  }
}

.about-list__item {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 89px;
}

@media screen and (max-width: 834px) {
  .about-list__item {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .about-list__item {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 30px;
  }
}

.about-list__item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 641px) {
  .about-list__item:nth-child(even) {
    flex-direction: row-reverse;
  }
  .about-list__item:nth-child(even) .about-list__content {
    margin-left: 0;
    margin-right: auto;
    padding: 7px 0 0 0;
  }
}

.about-list__img {
  width: 49.85%;
}

@media screen and (max-width: 640px) {
  .about-list__img {
    width: 100%;
    margin-bottom: 15px;
  }
}

.about-list__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.about-list__content {
  width: 45.45%;
  margin-left: auto;
  padding: 19px 0 0 0;
}

@media screen and (max-width: 1200px) {
  .about-list__content {
    width: 48%;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .about-list__content {
    width: 100%;
  }
}

.about-list__content .ttl {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.6;
  font-weight: bold;
  color: #db3146;
  margin-bottom: 29px;
}

@media screen and (max-width: 1200px) {
  .about-list__content .ttl {
    font-size: 21px;
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 834px) {
  .about-list__content .ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .about-list__content .ttl {
    margin-bottom: 15px;
  }
}

.sec-review {
  padding: 160px 0 100px;
}

@media screen and (max-width: 1200px) {
  .sec-review {
    padding: 100px 0;
  }
}

@media screen and (max-width: 834px) {
  .sec-review {
    padding: 50px 0;
  }
}

.review-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -9px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8;
}

@media screen and (max-width: 1200px) {
  .review-list {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .review-list {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.review-list__item {
  width: 33.33%;
  padding: 0 9px;
  margin-bottom: 22px;
}

@media screen and (max-width: 990px) {
  .review-list__item {
    width: 50%;
  }
}

@media screen and (max-width: 640px) {
  .review-list__item {
    width: 100%;
  }
}

.review-list__wrap {
  border: 1px solid #dddddd;
  padding: 20px 36px 72px 29px;
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .review-list__wrap {
    padding: 15px 15px 60px;
  }
}

.review-list__head {
  display: flex;
  align-items: stretch;
  margin-bottom: 26px;
}

.review-list__img {
  width: 121px;
}

@media screen and (max-width: 1200px) {
  .review-list__img {
    width: 100px;
  }
}

.review-list__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.review-list__r {
  width: calc(100% - 121px);
  padding: 7px 0 17px 23px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .review-list__r {
    width: calc(100% - 100px);
  }
}

.review-list__r:before {
  position: absolute;
  content: '';
  height: 1px;
  right: 0;
  left: 20px;
  background: #e7e7e7;
  bottom: 1px;
}

.review-list__r .ttl {
  display: block;
  display: -webkit-box;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 1.45;
  height: 63px;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1200px) {
  .review-list__r .ttl {
    font-size: 18px;
    font-size: 1.8rem;
    height: 55px;
  }
}

@media screen and (max-width: 834px) {
  .review-list__r .ttl {
    font-size: 16px;
    font-size: 1.6rem;
    height: 49px;
  }
}

.review-list__r .evaluation {
  display: flex;
  white-space: nowrap;
  width: 100%;
  margin-top: auto;
}

.review-list__r .evaluation .txt {
  position: relative;
  top: 3px;
}

.review-list__r .evaluation .start {
  display: flex;
  margin-left: auto;
}

@media screen and (max-width: 640px) {
  .review-list__r .evaluation .start {
    margin-left: 0;
  }
}

.review-list__r .evaluation .start li {
  margin-left: 5px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .review-list__r .evaluation .start li img {
    width: 100%;
  }
}

.review-list__txt {
  letter-spacing: -0.5px;
}

.review-list__btn {
  font-size: 24px;
  font-size: 2.4rem;
  position: absolute;
  bottom: 21px;
  left: 30px;
  right: 34px;
  background: #eeeeee;
  color: #bbbbbb;
  text-align: center;
  padding: 2px 10px 3px;
  line-height: 1;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 1200px) {
  .review-list__btn {
    bottom: 15px;
    left: 15px;
    right: 15px;
  }
}

.review-list__btn:hover {
  opacity: 0.7;
}

.review-popup {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.29);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.review-popup__wrap {
  width: 90%;
  max-width: 757px;
  max-height: 90%;
  background: white;
  position: relative;
  box-shadow: 0 0 13px rgba(11, 5, 8, 0.08);
}

.review-popup__content {
  display: block;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.review-popup__close {
  position: absolute;
  background: #bbbbbb;
  color: white;
  width: 59px;
  height: 59px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 23px;
  font-size: 2.3rem;
  top: -29px;
  right: -31px;
}

@media screen and (max-width: 834px) {
  .review-popup__close {
    width: 40px;
    height: 40px;
    right: -15px;
    top: -15px;
  }
}

.review-popup__item {
  display: none;
}

.review-popup__item.active {
  display: block;
}

.review-popup__list {
  width: 100%;
  padding: 57px 15px 135px;
  max-width: 672px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .review-popup__list {
    padding: 50px 15px;
  }
}

.review-popup__list .review-list__head {
  margin-bottom: 46px;
}

@media screen and (max-width: 834px) {
  .review-popup__list .review-list__head {
    margin-bottom: 30px;
  }
}

.review-popup__list .review-list__img {
  width: 28.2%;
}

.review-popup__list .review-list__r {
  width: 68.6%;
  padding-top: 4px;
  display: block;
}

.review-popup__list .review-list__r .ttl {
  margin-bottom: -4px;
}

.review-popup__list .review-list__r .evaluation {
  padding-left: 7px;
}

.review-popup__list .review-list__r .evaluation .start {
  margin-left: -2px;
}

.review-popup.on {
  opacity: 1;
  pointer-events: auto;
}

.breadcrumb {
  color: #656565;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
  padding-bottom: 40px;
}

.breadcrumb.mb {
  margin-bottom: 90px;
}

@media screen and (max-width: 834px) {
  .breadcrumb.mb {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 834px) {
  .breadcrumb {
    padding: 15px 0 20px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.breadcrumb a, .breadcrumb span {
  margin: 0 3px 0 5px;
}

@media screen and (max-width: 834px) {
  .breadcrumb a, .breadcrumb span {
    margin: 0 3px 0 4px;
  }
}

.breadcrumb .inner > a:first-child {
  color: #db3146;
  margin-right: 11px;
}

@media screen and (max-width: 834px) {
  .breadcrumb .inner > a:first-child {
    margin-right: 6px;
  }
}

.breadcrumb .inner > a:first-child i {
  color: #222529;
  font-size: 20px;
  font-size: 2rem;
  margin: 0 9px 0 -8px;
}

@media screen and (max-width: 834px) {
  .breadcrumb .inner > a:first-child i {
    font-size: 14px;
    font-size: 1.4rem;
    margin: 0 6px 0 -8px;
  }
}

.layout {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.layout-l {
  width: 21.666666666%;
  padding-bottom: 70px;
}

@media screen and (max-width: 1024px) {
  .layout-l {
    width: 100%;
    order: 1;
    padding-bottom: 40px;
  }
}

.layout-r {
  width: 78.333333334%;
  padding-left: 37px;
  padding-bottom: 58px;
}

@media screen and (max-width: 1024px) {
  .layout-r {
    padding-left: 0;
    width: 100%;
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 1025px) {
  .layout.has-icon .layout-r {
    padding-bottom: 190px;
  }
}

.wg-search {
  margin-bottom: 64px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .wg-search {
    margin-bottom: 50px;
  }
}

.wg-search input {
  vertical-align: middle;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0 59px 0 29px;
  height: 53px;
  color: #666666;
  background-color: #f4f4f4;
  width: 100%;
  border-radius: 999px;
}

@media screen and (max-width: 834px) {
  .wg-search input {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .wg-search input {
    padding-bottom: 1px;
  }
}

.wg-search input::-ms-clear {
  display: none;
}

.wg-search input::placeholder {
  color: #666666;
  opacity: 1;
}

.wg-search input:-ms-input-placeholder {
  color: #666666;
}

.wg-search input::-ms-input-placeholder {
  color: #666666;
}

.wg-search a, .wg-search__btn {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  border: none;
  background: none;
}

.wg-menu__ttl {
  text-align: center;
  color: #333333;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.78;
  margin-bottom: 33px;
}

@media screen and (max-width: 834px) {
  .wg-menu__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}

.wg-menu__block {
  margin-bottom: 46px;
}

@media screen and (max-width: 834px) {
  .wg-menu__block {
    margin-bottom: 30px;
  }
}

.wg-menu__block:last-child {
  margin-bottom: 34px;
}

@media screen and (max-width: 834px) {
  .wg-menu__block:last-child {
    margin-bottom: 30px;
  }
}

.wg-menu__block .ttl {
  color: #ffffff;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.78;
  text-align: center;
  padding: 6px;
  background-color: #df6383;
}

@media screen and (max-width: 834px) {
  .wg-menu__block .ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.wg-menu__block .list {
  border: 1px solid #cdcdcd;
  border-top: none;
}

.wg-menu__block .list li {
  color: #333333;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 20px 29px;
  position: relative;
  border-bottom: 1px solid #cdcdcd;
}

@media screen and (max-width: 834px) {
  .wg-menu__block .list li {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 15px 29px;
  }
}

.wg-menu__block .list li:after {
  content: '';
  width: 6px;
  height: 6px;
  border: 1px solid #cdcdcd;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  right: 27px;
  position: absolute;
  z-index: 1;
}

.wg-menu__block .list li:last-child {
  border-bottom: none;
}

.wg-image {
  text-align: center;
}

.wg-image li {
  margin-bottom: 10px;
}

.wg-social {
  padding: 7px 0 15px;
  display: flex;
  margin: 14px 1px 0 auto;
}

@media screen and (max-width: 834px) {
  .wg-social {
    margin: 15px 0 0 0;
  }
}

.wg-social a {
  display: block;
  width: 52px;
  height: 52px;
  border-radius: 52px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 24px;
  font-size: 2.4rem;
  background: #698dbd;
}

.wg-social a:hover {
  color: #FFF;
}

.wg-social li {
  margin-left: 13px;
}

.wg-social li:nth-of-type(2) a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#912edd+0,fe2075+44,fdcd55+100 */
  background: #912edd;
  /* Old browsers */
  background: -moz-linear-gradient(top, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#912edd', endColorstr='#fdcd55',GradientType=0 );
  /* IE6-9 */
}

.wg-social li:nth-of-type(3) a {
  background: #5bb5e3;
}

.wg-social li:nth-of-type(4) a {
  background: #d2122a;
}

.sec-list-product {
  padding: 4px 0 0;
}

.bear-msg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 1024px) {
  .bear-msg {
    position: relative;
    width: 100%;
    order: 2;
  }
}

.bear-msg__ttl {
  color: #333333;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
  font-weight: 500;
  padding: 0 10px 15px 0;
}

@media screen and (max-width: 834px) {
  .bear-msg__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.list-product {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -12px;
}

@media screen and (max-width: 640px) {
  .list-product {
    margin: 0 -7px 0;
  }
}

.list-product a {
  display: block;
}

.list-product .item {
  width: 25%;
  padding: 0 12px;
  margin-bottom: 60px;
}

@media screen and (max-width: 1200px) {
  .list-product .item {
    width: 33.33333%;
  }
}

@media screen and (max-width: 640px) {
  .list-product .item {
    width: 50%;
    padding: 0 7px;
    margin-bottom: 30px;
  }
}

.list-product .item__img {
  position: relative;
  margin-bottom: 15px;
  position: relative;
  padding-top: 100%;
}

@media screen and (max-width: 834px) {
  .list-product .item__img {
    margin-bottom: 10px;
  }
}

.list-product .item__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.list-product .item__img .type {
  position: absolute;
  top: 8px;
  right: 9px;
  width: 47px;
  height: 47px;
  background: #323232;
  color: white;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  border-radius: 5px;
  text-align: center;
}

.list-product .item__img .type small {
  display: block;
  font-weight: 500;
  font-size: 12px;
  font-size: 1.2rem;
}

.list-product .item__ranking {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1;
  color: white;
  width: 48px;
  height: 48px;
  background: rgba(219, 49, 70, 0.9);
  border-radius: 50%;
  margin: -39px 0 26px -1px;
  position: relative;
  z-index: 1;
}

.list-product .item__status {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  min-width: 66px;
  color: white;
  padding: 2px 5px;
  background: #db3146;
  margin: 0 0 5px;
}

.list-product .item__ttl {
  color: #323232;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.37;
}

@media screen and (max-width: 834px) {
  .list-product .item__ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.list-product .item__price {
  color: #db3146;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 16px;
}

@media screen and (max-width: 834px) {
  .list-product .item__price {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 10px;
  }
}

.list-product .item__price small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .list-product .item__price small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.list-product .item:first-child .item__ranking, .list-product .item:nth-of-type(2) .item__ranking, .list-product .item:nth-of-type(3) .item__ranking {
  border-radius: 0;
  background-color: transparent;
  width: 47px;
  background-position: top left;
  background-repeat: no-repeat;
  margin-top: -45px;
  padding-top: 6px;
}

.list-product .item:first-child .item__ranking {
  background-image: url("../img/index/list-product-ranking-01.png");
}

.list-product .item:nth-of-type(2) .item__ranking {
  background: url("../img/index/list-product-ranking-02.png") no-repeat;
}

.list-product .item:nth-of-type(3) .item__ranking {
  background: url("../img/index/list-product-ranking-03.png") no-repeat;
}

.list-product .item:first-child .item__img:after, .list-product .item:nth-of-type(2) .item__img:after, .list-product .item:nth-of-type(3) .item__img:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 51px;
  height: 44px;
  top: 8px;
  right: 10px;
  background-repeat: no-repeat;
  background-size: contain;
}

.list-product.ranking .item:first-child .item__img:after {
  background-image: url("../img/detail/ico-1st.png");
}

.list-product.ranking .item:nth-of-type(2) .item__img:after {
  background-image: url("../img/detail/ico-2nd.png");
}

.list-product.ranking .item:nth-of-type(3) .item__img:after {
  background-image: url("../img/detail/ico-3rd.png");
}

.tool-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-func {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.78;
  margin-bottom: 16px;
  padding-right: 20px;
}

@media screen and (max-width: 834px) {
  .filter-func {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.filter-func li {
  padding-right: 26px;
  position: relative;
}

.filter-func li:after {
  content: '';
  position: absolute;
  z-index: 1;
  height: 18px;
  width: 1px;
  background-color: #333333;
  right: 12px;
  top: 52%;
  transform: translateY(-50%);
}

.filter-func li:last-child {
  padding-right: 0;
}

.filter-func li:last-child:after {
  display: none;
}

.filter-func li.active {
  color: #dd3042;
}

.pager-func {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.pager-func .txt {
  flex-shrink: 0;
  color: #333333;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.78;
  font-weight: 400;
  padding-right: 24px;
  margin-bottom: 6px;
}

@media screen and (max-width: 834px) {
  .pager-func .txt {
    font-size: 16px;
    font-size: 1.6rem;
    padding-right: 10px;
  }
}

.pager-func .wrap {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.pager-func .wrap a, .pager-func .wrap span {
  width: 26px;
  height: 26px;
  margin-left: 7px;
  line-height: 22px;
  color: #666666;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid #cdcdcd;
}

@media screen and (max-width: 834px) {
  .pager-func .wrap a, .pager-func .wrap span {
    font-size: 16px;
    font-size: 1.6rem;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    line-height: 20px;
  }
}

.pager-func .wrap .current {
  color: #ffffff;
  background-color: #df6383;
  border-color: #df6383;
}

.pager-func .wrap .no-bg {
  border: none;
  width: 20px;
  margin-left: 0;
}

.pager-func .wrap .more {
  border: none;
  width: 22px;
  margin-left: 8px;
}

.pager-func .wrap .next {
  position: relative;
}

.pager-func .wrap .next:after {
  content: '';
  width: 6px;
  height: 6px;
  border: 1px solid #cdcdcd;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: translate(-50%, -50%) rotate(45deg);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 48%;
}

.pager-func .wrap ul li {
  display: inline-block;
}

.pager-func .wrap ul li span {
  border: none;
}

.prd-lst-none {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (min-width: 835px) {
  .sec-product-detail .ttl-comp04 {
    padding-left: 39px;
    padding-right: 39px;
  }
}

.sec-product-detail .list-product {
  margin-bottom: -20px;
}

.sec-product-detail .ttl-comp04--green {
  background-color: #94D1C5;
}

.product-detail__img-for .slick-list {
  margin: 0 -13px 15px;
}

@media screen and (max-width: 834px) {
  .product-detail__img-for .slick-list {
    margin: 0 -7px 15px;
  }
}

.product-detail__img-for .item {
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .product-detail__img-for .item {
    padding: 0 7px;
    margin-bottom: 15px;
  }
}

.product-detail__img-for .item:focus {
  outline: none;
}

.product-detail__img-for .item::after {
  content: "";
  display: block;
  padding-top: 55%;
}

.product-detail__img-for .item img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  max-height: inherit;
  height: 100%;
}

.product-detail__img-nav .slick-list {
  margin: 0 -13px 15px;
}

@media screen and (max-width: 834px) {
  .product-detail__img-nav .slick-list {
    margin: 0 0 15px;
  }
}

.product-detail__img-nav .slick-track {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  transform: none !important;
}

.product-detail__img-nav .slick-track:before, .product-detail__img-nav .slick-track:after {
  display: none;
}

.product-detail__img-nav .item {
  width: 23.5% !important;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 2%;
  float: none;
  height: auto;
}

.product-detail__img-nav .item:nth-child(4n) {
  margin-right: 0;
}

.product-detail__img-nav .item::after {
  content: "";
  display: block;
  padding-top: 65%;
}

.product-detail__img-nav .item img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .product-detail__img-nav .item {
    width: 32% !important;
    margin-bottom: 15px;
  }
  .product-detail__img-nav .item:nth-child(4n) {
    margin-right: 1.9%;
  }
  .product-detail__img-nav .item:nth-child(3n) {
    margin-right: 0;
  }
}

.product-detail__img-nav .item:focus {
  outline: none;
}

.product-detail__value {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 12px 63px -24px;
}

@media screen and (max-width: 834px) {
  .product-detail__value {
    margin: 0 -7px 20px;
  }
}

.product-detail__value .item {
  display: flex;
  padding: 0 24px;
  margin-bottom: 8px;
  width: 50%;
}

@media screen and (max-width: 834px) {
  .product-detail__value .item {
    padding: 0 7px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
  .product-detail__value .item {
    width: 100%;
  }
}

.product-detail__value .item--lg {
  width: 100%;
}

.product-detail__value .item__label {
  color: #323232;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
  padding: 20px 10px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  width: 80px;
}

@media screen and (max-width: 834px) {
  .product-detail__value .item__label {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.product-detail__value .item__content {
  width: calc(100% - 80px);
  padding-left: 19px;
  display: flex;
  align-items: center;
}

.product-detail__value .item__price {
  color: #db3146;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  padding: 0 0 10px 2px;
}

@media screen and (max-width: 834px) {
  .product-detail__value .item__price {
    font-size: 20px;
    font-size: 2rem;
    padding: 0 0 0 0;
  }
}

.product-detail__value .item__price small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .product-detail__value .item__price small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.product-detail__value .item__select {
  width: 100%;
  border: 1px solid #e6e6e6;
  background-color: #FFFFFF;
  padding: 0 44px 0 23px;
  height: 40px;
  vertical-align: middle;
  color: #666666;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  background: url("../img/detail/ico-select-dropdown.png") no-repeat right center;
}

@media screen and (max-width: 834px) {
  .product-detail__value .item__select {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.product-detail__value .item__select::-ms-expand {
  display: none;
}

.product-detail__value .item__select--number {
  width: auto;
  padding: 16px 44px 20px 9px;
}

.product-detail__value .item input[type="text"] {
  width: 100px;
  border: 1px solid #e6e6e6;
  background-color: #FFFFFF;
  padding: 0 44px 0 23px;
  height: 40px;
  vertical-align: middle;
  color: #666666;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .product-detail__value .item input[type="text"] {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.product-detail__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px 78px;
}

@media screen and (max-width: 834px) {
  .product-detail__btn {
    margin: 0 -7px 50px;
  }
}

@media screen and (max-width: 640px) {
  .product-detail__btn {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.product-detail__btn .item {
  width: 100%;
  max-width: 340px;
  padding: 0 10px;
}

@media screen and (max-width: 834px) {
  .product-detail__btn .item {
    padding: 0 7px;
  }
}

@media screen and (max-width: 640px) {
  .product-detail__btn .item {
    max-width: none;
    margin-bottom: 10px;
  }
}

.product-detail__btn .item button, .product-detail__btn .item a {
  width: 100%;
}

.product-detail__content {
  border: 1px solid #e6e6e6;
  padding: 46px 67px 57px;
  color: #333333;
  margin-bottom: 75px;
  font-size: 21px;
  font-size: 2.1rem;
}

@media screen and (max-width: 834px) {
  .product-detail__content {
    padding: 30px 15px;
    margin-bottom: 50px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.product-detail__content .ttl {
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: 27px;
}

@media screen and (max-width: 834px) {
  .product-detail__content .ttl {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

.product-detail__content .txt {
  font-weight: 300;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.56;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .product-detail__content .txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.product-detail__content .list br {
  display: none;
}

.product-detail__content .list span {
  font-weight: 300;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.56;
  display: block;
  margin-bottom: 6px;
}

@media screen and (max-width: 834px) {
  .product-detail__content .list span {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.product-detail__content .list dl {
  display: flex;
  margin-bottom: 2px;
  font-weight: 300;
  font-size: 18px;
  font-size: 1.8rem;
  background-color: #f7f7f7;
}

@media screen and (max-width: 834px) {
  .product-detail__content .list dl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.product-detail__content .list dt {
  width: 34%;
  text-align: center;
  background-color: #e5e5e5;
  padding: 8px 8px 7px;
}

@media screen and (max-width: 834px) {
  .product-detail__content .list dt {
    padding: 8px 8px 9px;
  }
}

.product-detail__content .list dd {
  width: 66%;
  padding: 8px 10% 7px;
}

@media screen and (max-width: 834px) {
  .product-detail__content .list dd {
    padding: 8px 10px;
  }
}

.sec-main-virual {
  height: 242px;
  margin-bottom: 31px;
  background-color: #db3146;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .sec-main-virual {
    height: 121px;
    margin-bottom: 0;
  }
}

.sec-category {
  padding: 78px 0 150px;
}

@media screen and (max-width: 834px) {
  .sec-category {
    padding: 10px 0 35px;
  }
}

.list-category {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}

@media screen and (max-width: 834px) {
  .list-category {
    margin: 0 -7px;
  }
}

.list-category a {
  display: block;
}

.list-category .item {
  padding: 0 16px;
  width: 33.33333%;
  margin-bottom: 33px;
}

@media screen and (max-width: 1200px) {
  .list-category .item {
    width: 50%;
  }
}

@media screen and (max-width: 834px) {
  .list-category .item {
    padding: 0 7px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 680px) {
  .list-category .item {
    width: 100%;
  }
}

.list-category .item__body {
  min-height: 235px;
  padding: 21px 30px 50px;
}

@media screen and (max-width: 1300px) {
  .list-category .item__body {
    padding: 20px 20px 30px;
    min-height: auto;
  }
}

@media screen and (max-width: 834px) {
  .list-category .item__body {
    padding: 20px 15px;
  }
}

.list-category .item__ttl {
  text-align: center;
  color: #000000;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 37px;
}

@media screen and (max-width: 834px) {
  .list-category .item__ttl {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 374px) {
  .list-category .item__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.list-category .item__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.list-category .item__list--full li {
  width: 100% !important;
}

.list-category .item__list li {
  padding: 0 10px;
  width: 50%;
  color: #000000;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.83;
}

@media screen and (max-width: 834px) {
  .list-category .item__list li {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 374px) {
  .list-category .item__list li {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.list-category .item__list li.full {
  width: 100%;
}

.list-category .item--green .item__body {
  background-color: #a5d8ce;
}

.list-category .item--yellow .item__body {
  background-color: #ebbc83;
}

.list-category .item--brown .item__body {
  background-color: #b4aeaf;
}

.list-category .item--pink .item__body {
  background-color: #e3809c;
}

.list-category .item--blue .item__body {
  background-color: #a6c1d3;
}

.list-category .item--red .item__body {
  background-color: #ea8686;
}

.sec-company {
  padding: 80px 0 208px;
}

@media screen and (max-width: 834px) {
  .sec-company {
    padding: 10px 0 35px;
  }
}

.list-company-profile {
  border: 1px solid #e0e0e0;
  margin-bottom: 73px;
}

@media screen and (max-width: 834px) {
  .list-company-profile {
    margin-bottom: 50px;
  }
}

.list-company-profile dl {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

@media screen and (max-width: 834px) {
  .list-company-profile dl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 374px) {
  .list-company-profile dl {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.list-company-profile dl:last-child {
  border-bottom: none;
}

.list-company-profile dt {
  padding: 23px 36px 24px;
  width: 24.8%;
  background-color: #f3f3f3;
}

@media screen and (max-width: 834px) {
  .list-company-profile dt {
    min-width: 120px;
    padding: 15px;
  }
}

@media screen and (max-width: 374px) {
  .list-company-profile dt {
    min-width: 100px;
    padding: 10px;
  }
}

.list-company-profile dd {
  width: 75.2%;
  padding: 23px 50px 24px;
}

@media screen and (max-width: 834px) {
  .list-company-profile dd {
    padding: 15px;
  }
}

@media screen and (max-width: 374px) {
  .list-company-profile dd {
    padding: 10px;
  }
}

.map-company {
  height: 656px;
  margin-bottom: 213px;
}

@media screen and (max-width: 834px) {
  .map-company {
    height: 328px;
    margin-bottom: 50px;
  }
}

.map-company iframe {
  width: 100%;
  height: 100%;
}

.heading-company {
  background-color: #eeeeee;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 66px;
}

@media screen and (max-width: 834px) {
  .heading-company {
    padding: 15px;
    margin-bottom: 35px;
  }
}

.heading-company .wrap {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
}

@media screen and (max-width: 834px) {
  .heading-company .wrap {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.heading-company img {
  flex-shrink: 0;
  margin-right: 30px;
}

@media screen and (max-width: 834px) {
  .heading-company img {
    margin-right: 15px;
  }
}

.list-our-history {
  margin-bottom: 91px;
}

@media screen and (max-width: 834px) {
  .list-our-history {
    margin-bottom: 50px;
  }
}

.list-our-history dl {
  color: #000000;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

@media screen and (max-width: 834px) {
  .list-our-history dl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.list-our-history dt {
  width: 16.5%;
  padding: 15px 38px 17px;
}

@media screen and (max-width: 834px) {
  .list-our-history dt {
    padding: 15px;
    min-width: 90px;
  }
}

.list-our-history dd {
  width: 83.5%;
  padding: 15px 38px 17px;
}

@media screen and (max-width: 834px) {
  .list-our-history dd {
    padding: 15px;
  }
}

.image-company {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}

@media screen and (max-width: 834px) {
  .image-company {
    margin: 0 -7px;
  }
}

.image-company .item {
  text-align: center;
  padding: 0 16px;
  width: 33.33333%;
  margin-bottom: 32px;
}

@media screen and (max-width: 834px) {
  .image-company .item {
    padding: 0 7px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .image-company .item {
    width: 100%;
  }
}

.sec-guide {
  margin-bottom: 250px;
}

@media screen and (max-width: 834px) {
  .sec-guide {
    margin-bottom: 80px;
  }
}

.sec-guide .anchor-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 115px;
}

@media screen and (max-width: 834px) {
  .sec-guide .anchor-block {
    margin-bottom: 40px;
  }
}

.sec-guide .anchor-block .item {
  width: 31.5%;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .sec-guide .anchor-block .item {
    width: 49%;
    margin-bottom: 10px;
  }
}

.sec-guide .anchor-block .item a {
  display: block;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  padding: 15px 0;
  border: 1px solid #333333;
}

@media screen and (max-width: 990px) {
  .sec-guide .anchor-block .item a {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 834px) {
  .sec-guide .anchor-block .item a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.sec-guide .anchor-block .item a:hover {
  background: #333333;
  color: #FFF;
}

.sec-guide .anchor-block .item i {
  margin-right: 15px;
}

@media screen and (max-width: 834px) {
  .sec-guide .anchor-block .item i {
    margin-right: 5px;
  }
}

.sec-guide .intro-block {
  margin-bottom: 110px;
}

@media screen and (max-width: 834px) {
  .sec-guide .intro-block {
    margin-bottom: 55px;
  }
}

.sec-guide .intro-block .ttl {
  font-size: 24px;
  font-size: 2.4rem;
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .sec-guide .intro-block .ttl {
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 30px;
  }
}

.sec-guide .intro-block .ttl02 {
  font-size: 24px;
  font-size: 2.4rem;
  background: #94d2c5;
  padding: 10px 15px;
  margin-bottom: 30px;
  color: #FFF;
}

@media screen and (max-width: 834px) {
  .sec-guide .intro-block .ttl02 {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.sec-guide .intro-block table {
  width: 100%;
}

.sec-guide .intro-block table th, .sec-guide .intro-block table td {
  width: 50%;
  border: 1px solid #e0e0e0;
  padding: 25px 20px;
  text-align: center;
  font-size: 21px;
  font-size: 2.1rem;
}

@media screen and (max-width: 834px) {
  .sec-guide .intro-block table th, .sec-guide .intro-block table td {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 15px 5px;
  }
}

.sec-guide .intro-block table th {
  background: #f3f3f3;
  font-weight: 500;
}

.sec-guide .intro-block p {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 35px;
  line-height: 1.7;
}

@media screen and (max-width: 834px) {
  .sec-guide .intro-block p {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 25px;
  }
}

.sec-guide .intro-block .section {
  margin-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .sec-guide .intro-block .section {
    margin-bottom: 30px;
  }
}

.sec-contact {
  padding: 0 0 182px;
}

@media screen and (max-width: 834px) {
  .sec-contact {
    padding: 0 0 50px;
  }
}

.contact__btn {
  margin-bottom: 70px;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .contact__btn {
    margin-bottom: 30px;
  }
}

.contact__txt {
  text-align: center;
  font-weight: 400;
  color: #323232;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 72px;
}

@media screen and (max-width: 834px) {
  .contact__txt {
    font-size: 15px;
    font-size: 1.5rem;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 374px) {
  .contact__txt {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.common-form {
  padding: 0 4.95%;
}

@media screen and (max-width: 1200px) {
  .common-form {
    padding: 0 0;
  }
}

.common-form .list {
  margin-bottom: 141px;
}

@media screen and (max-width: 834px) {
  .common-form .list {
    margin-bottom: 20px;
  }
}

.common-form .list .item {
  display: flex;
  align-items: center;
  margin-bottom: 37px;
}

@media screen and (max-width: 834px) {
  .common-form .list .item {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.common-form .list .item__label {
  width: 27%;
  padding-left: 3.2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .common-form .list .item__label {
    padding-left: 0;
  }
}

@media screen and (max-width: 834px) {
  .common-form .list .item__label {
    width: 100%;
    margin-bottom: 15px;
    justify-content: flex-start;
  }
}

.common-form .list .item__label span {
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  font-size: 1.6rem;
  padding-right: 15px;
}

@media screen and (max-width: 834px) {
  .common-form .list .item__label span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.common-form .list .item__label small {
  display: inline-block;
  font-weight: 400;
  color: #db3146;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 1px 17px 3px;
  border-radius: 3px;
  border: 1px solid #db3146;
}

@media screen and (max-width: 834px) {
  .common-form .list .item__label small {
    padding: 2px 17px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.common-form .list .item__content {
  width: 73%;
  padding-left: 69px;
}

@media screen and (max-width: 990px) {
  .common-form .list .item__content {
    padding-left: 40px;
  }
}

@media screen and (max-width: 834px) {
  .common-form .list .item__content {
    padding-left: 0;
    width: 100%;
  }
}

.common-form .list .item__input {
  vertical-align: middle;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 3px;
  height: 57px;
  border: 2px solid #dddddd;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0 15px;
  color: #000000;
}

@media screen and (max-width: 834px) {
  .common-form .list .item__input {
    height: 47px;
    font-size: 14px;
    font-size: 1.4rem;
    border-width: 1px;
  }
}

.common-form .list .item__input::-ms-clear {
  display: none;
}

.common-form .list .item__input::placeholder {
  color: #666666;
  opacity: 1;
}

.common-form .list .item__input:-ms-input-placeholder {
  color: #666666;
}

.common-form .list .item__input::-ms-input-placeholder {
  color: #666666;
}

.common-form .capcha {
  text-align: center;
  margin-bottom: 68px;
}

@media screen and (max-width: 834px) {
  .common-form .capcha {
    margin-bottom: 30px;
  }
}

.lp-content {
  font-size: 21px;
  font-size: 2.1rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #333333;
}

@media screen and (max-width: 834px) {
  .lp-content {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .inner {
  max-width: 1130px;
}

.lp-content .mv {
  position: relative;
  background: url("../img/lp/b/mv.png") no-repeat center;
  background-size: cover;
  min-height: 660px;
  text-align: right;
}

@media screen and (max-width: 834px) {
  .lp-content .mv {
    background: none;
    min-height: inherit;
  }
}

.lp-content .mv__logo {
  position: absolute;
  top: 30px;
  left: 50px;
  max-width: 187px;
}

@media screen and (max-width: 834px) {
  .lp-content .mv__logo {
    display: none;
  }
}

.lp-content .mv__inner {
  max-width: 597px;
  float: right;
  padding-top: 190px;
}

@media screen and (max-width: 834px) {
  .lp-content .mv__inner {
    float: none;
    text-align: center;
    padding-top: 20px;
    max-width: 100%;
  }
}

.lp-content .mv__ttl {
  display: inline-block;
  text-align: left;
  font-size: 42px;
  font-size: 4.2rem;
  font-weight: bold;
  margin-bottom: 25px;
  letter-spacing: 0;
}

@media screen and (max-width: 834px) {
  .lp-content .mv__ttl {
    font-size: 24px;
    font-size: 2.4rem;
    text-align: center;
  }
}

.lp-content .mv__ttl small {
  display: block;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 15px;
}

@media screen and (max-width: 834px) {
  .lp-content .mv__ttl small {
    margin-bottom: 10px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .mv__ttl small span {
  color: #db3146;
  text-shadow: 0px 0px 3px #ffffff,0px 0px 4px #ffffff;
}

@media screen and (max-width: 834px) {
  .lp-content .mv__tag {
    width: 80%;
  }
}

.lp-content .mv .social-list {
  position: absolute;
  top: 20px;
  right: 50px;
  display: flex;
  margin: 14px 1px 0 auto;
}

@media screen and (max-width: 834px) {
  .lp-content .mv .social-list {
    margin: 15px 0 0 0;
    display: none;
  }
}

.lp-content .mv .social-list a {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-size: 1.4rem;
  background: #698dbd;
}

.lp-content .mv .social-list a:hover {
  color: #FFF !important;
}

.lp-content .mv .social-list li {
  margin-left: 5px;
}

.lp-content .mv .social-list li:nth-of-type(2) a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#912edd+0,fe2075+44,fdcd55+100 */
  background: #912edd;
  /* Old browsers */
  background: -moz-linear-gradient(top, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #912edd 0%, #fe2075 44%, #fdcd55 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#912edd', endColorstr='#fdcd55',GradientType=0 );
  /* IE6-9 */
}

.lp-content .mv .social-list li:nth-of-type(3) a {
  background: #5bb5e3;
}

.lp-content .mv .social-list li:nth-of-type(4) a {
  background: #d2122a;
}

.lp-content .sec-about {
  background: #a4dacf url("../img/lp/b/bg.png") no-repeat center top;
  background-size: 100% auto;
  padding-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about {
    padding-bottom: 45px;
  }
}

.lp-content .sec-about .concept-block {
  padding: 80px 0 70px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .concept-block {
    padding: 40px 0 30px;
  }
}

.lp-content .sec-about .concept-block__ttl {
  border-bottom: 1px solid #333;
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 45px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .concept-block__ttl {
    margin-bottom: 30px;
  }
}

.lp-content .sec-about .concept-block__ttl02 {
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.7;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .concept-block__ttl02 {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 25px;
  }
}

.lp-content .sec-about .concept-block__ttl02 small {
  display: block;
  font-size: 21px;
  font-size: 2.1rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .concept-block__ttl02 small {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.lp-content .sec-about .concept-block__txt {
  line-height: 2.2;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .concept-block__txt {
    text-align: left;
    line-height: 1.8;
  }
}

.lp-content .sec-about .kodawari-block {
  position: relative;
  background: #FFF;
  padding: 85px 60px 65px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block {
    padding: 40px 20px 10px;
  }
}

.lp-content .sec-about .kodawari-block__ttl {
  text-align: center;
  font-weight: bold;
  color: #db3146;
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__ttl {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 30px;
  }
}

.lp-content .sec-about .kodawari-block__en {
  position: absolute;
  top: -53px;
  left: 0;
  right: 0;
  text-align: center;
  color: #94d2c5;
  opacity: 0.15;
  font-size: 100px;
  font-size: 10rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__en {
    font-size: 40px;
    font-size: 4rem;
    top: -18px;
  }
}

.lp-content .sec-about .kodawari-block__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lp-content .sec-about .kodawari-block__row .item {
  text-align: center;
  width: 30.9%;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__row .item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.lp-content .sec-about .kodawari-block__row .item__content {
  border: 1px solid #dddddd;
  border-top: none;
  padding: 35px 10px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__row .item__content {
    padding: 25px 15px;
  }
}

.lp-content .sec-about .kodawari-block__row .item__img {
  position: relative;
}

.lp-content .sec-about .kodawari-block__row .item__img img {
  width: 100%;
  height: auto;
}

.lp-content .sec-about .kodawari-block__row .item__img span {
  position: absolute;
  top: -30px;
  left: -15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  color: #FFF;
  border-radius: 100px;
  background: #db3146;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__row .item__img span {
    width: 65px;
    height: 65px;
    top: -10px;
    left: -10px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.lp-content .sec-about .kodawari-block__row .item__ttl {
  display: inline;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.6;
  background: linear-gradient(transparent 60%, #ffdaad 60%);
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__row .item__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.lp-content .sec-about .kodawari-block__row .item__txt {
  text-align: left;
  line-height: 1.7;
  max-width: 230px;
  margin: 30px auto 0;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-about .kodawari-block__row .item__txt {
    margin-top: 20px;
  }
}

.lp-content .sec-ttl {
  text-align: center;
  font-size: 40px;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 25px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-ttl {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.lp-content .sec-ttl span {
  color: #db3146;
}

.lp-content .sec-ttl .sub-ttl {
  display: inline-block;
  color: #d73652;
  font-size: 25px;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-ttl .sub-ttl {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 10px;
  }
}

.lp-content .sec-ttl .sub-ttl::after, .lp-content .sec-ttl .sub-ttl::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
  width: 1px;
  height: 15px;
  background: #d73652;
}

.lp-content .sec-ttl .sub-ttl::after {
  -moz-transform: rotate(25deg);
  -webkit-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
}

.lp-content .sec-ttl .sub-ttl::before {
  -moz-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
}

.lp-content .sec-ttl small {
  display: block;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-ttl small {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.lp-content .lead {
  max-width: 700px;
  margin: 0 auto 50px;
  font-weight: bold;
  text-align: center;
  line-height: 2;
}

@media screen and (max-width: 834px) {
  .lp-content .lead {
    max-width: 100%;
    text-align: left;
    margin-bottom: 35px;
  }
}

.lp-content .lead--style02 {
  font-weight: normal;
  text-align: left;
  max-width: 660px;
}

.lp-content .lead--style02 small {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  padding-top: 10px;
}

.lp-content .sec-wakeari {
  padding: 115px 0 0;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari {
    padding: 50px 0 0;
  }
}

.lp-content .sec-wakeari .omoi-block {
  background: url("../img/lp/b/wakeari-bg.png") no-repeat center top;
  min-height: 500px;
  background-size: cover;
  padding: 45px 50px 30px 52%;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .omoi-block {
    background: none;
    padding: 0;
    min-height: inherit;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .omoi-block__img {
    margin: 0 -15px 25px;
  }
  .lp-content .sec-wakeari .omoi-block__img img {
    width: 100%;
  }
}

.lp-content .sec-wakeari .omoi-block__desc {
  margin-bottom: 30px;
  position: relative;
  left: -30px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .omoi-block__desc {
    width: 230px;
    left: 0;
    margin-bottom: 10px;
    margin-top: -50px;
  }
}

.lp-content .sec-wakeari .omoi-block__ttl {
  color: #db3146;
  font-weight: bold;
  line-height: 1.6;
  font-size: 33px;
  font-size: 3.3rem;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .omoi-block__ttl {
    font-size: 22px;
    font-size: 2.2rem;
    margin-bottom: 20px;
  }
}

.lp-content .sec-wakeari .omoi-block__txt {
  line-height: 1.9;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .omoi-block__txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .sec-wakeari .example-block {
  background: #f3efea;
  padding: 70px 45px 55px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .example-block {
    padding: 40px 20px 15px;
  }
}

.lp-content .sec-wakeari .example-block__ttl {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 55px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .example-block__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
}

.lp-content .sec-wakeari .example-block__ttl span {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 0 20px;
  background: #f3efea;
}

.lp-content .sec-wakeari .example-block__ttl::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #333;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.lp-content .sec-wakeari .example-block__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lp-content .sec-wakeari .example-block__row .item {
  text-align: center;
  width: 22.6%;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .example-block__row .item {
    width: 48%;
    margin-bottom: 30px;
  }
}

.lp-content .sec-wakeari .example-block__row .item__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  margin: 25px 0 0;
  line-height: 1.6;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-wakeari .example-block__row .item__ttl {
    font-size: 13px;
    font-size: 1.3rem;
    margin: 15px 0 0;
  }
}

.lp-content .sec-otoku {
  padding: 75px 0 65px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku {
    padding: 50px 0 30px;
  }
}

.lp-content .sec-otoku .sec-ttl {
  position: relative;
  display: inline-block;
}

.lp-content .sec-otoku .sec-ttl::after {
  position: absolute;
  right: -105px;
  bottom: 0;
  content: "";
  display: block;
  background: url(../img/lp/b/otoku-object.png) no-repeat 0 0;
  width: 79px;
  height: 120px;
  background-size: 100% auto;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .sec-ttl::after {
    display: none;
  }
}

.lp-content .sec-otoku .cost-block {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__mv {
    margin: 0 -15px;
  }
}

.lp-content .sec-otoku .cost-block__mv img {
  width: 100%;
}

.lp-content .sec-otoku .cost-block__ttl {
  position: relative;
  max-width: 615px;
  margin: -30px auto 55px;
  background: #db3146;
  color: #FFF;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.7;
  padding: 25px 0;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__ttl {
    margin-top: 0;
    max-width: inherit;
    margin: 0 -15px 50px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.lp-content .sec-otoku .cost-block__ttl small {
  display: block;
  font-size: 21px;
  font-size: 2.1rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__ttl small {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .sec-otoku .cost-block__ttl::after {
  position: absolute;
  left: 50%;
  bottom: -22px;
  margin-left: -11px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 11.5px 0 11.5px;
  border-color: #db3145 transparent transparent transparent;
}

.lp-content .sec-otoku .cost-block__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__row {
    padding: 0 20px;
  }
}

.lp-content .sec-otoku .cost-block__row .item {
  width: 255px;
  text-align: left;
}

@media screen and (max-width: 990px) {
  .lp-content .sec-otoku .cost-block__row .item {
    width: 33.333%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__row .item {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
}

.lp-content .sec-otoku .cost-block__row .item img {
  display: block;
  width: 251px;
  margin: 0 auto 25px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__row .item img {
    margin-bottom: 20px;
  }
}

.lp-content .sec-otoku .cost-block__row .item__ttl {
  font-weight: bold;
  color: #db3146;
  font-size: 21px;
  font-size: 2.1rem;
  margin-bottom: 15px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__row .item__ttl {
    margin-bottom: 10px;
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.lp-content .sec-otoku .cost-block__row .item__ttl span {
  color: #94d2c5;
  font-size: 30px;
  font-size: 3rem;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  margin-right: 10px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-otoku .cost-block__row .item__ttl span {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.lp-content .sec-otoku .cost-block__row .item__txt {
  line-height: 1.8;
}

.lp-content .sec-episode {
  background: url("../img/lp/b/episode-bg.png") no-repeat center;
  background-size: cover;
  padding: 55px 0 70px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode {
    padding: 55px 0 50px;
  }
}

.lp-content .sec-episode .box {
  position: relative;
  padding: 65px 80px 55px;
  background: rgba(255, 255, 255, 0.85);
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .box {
    padding: 40px 20px 30px;
  }
}

.lp-content .sec-episode .img-ttl {
  max-width: 288px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  top: -20px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .img-ttl {
    max-width: 230px;
  }
}

.lp-content .sec-episode .lead {
  max-width: inherit;
}

.lp-content .sec-episode .intro-block {
  margin-bottom: 40px;
}

.lp-content .sec-episode .intro-block__content {
  background: url("../img/lp/b/episode-bg02.png") no-repeat center;
  background-size: cover;
  padding: 45px 55px 50px;
  color: #FFF;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .intro-block__content {
    padding: 30px 15px 35px;
  }
}

.lp-content .sec-episode .intro-block__ttl {
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.8;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .intro-block__ttl {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.lp-content .sec-episode .intro-block__ttl small {
  display: block;
  font-size: 21px;
  font-size: 2.1rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .intro-block__ttl small {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.lp-content .sec-episode .intro-block__txt {
  line-height: 1.9;
}

.lp-content .sec-episode .point-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.lp-content .sec-episode .point-block__ttl {
  width: 235px;
}

@media screen and (max-width: 990px) {
  .lp-content .sec-episode .point-block__ttl {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .point-block__ttl {
    margin: 0 auto 25px;
    right: -20px;
    position: relative;
  }
}

.lp-content .sec-episode .point-block__row {
  width: calc(100% - 235px);
  padding-left: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 990px) {
  .lp-content .sec-episode .point-block__row {
    width: 100%;
    padding-left: 0;
  }
}

.lp-content .sec-episode .point-block__row .item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 31%;
  background: #db3146;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FFF;
  border-radius: 100%;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.8;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .point-block__row .item {
    width: 100%;
    font-size: 18px;
    font-size: 1.8rem;
    border-radius: 30px;
    padding: 10px;
    line-height: 1.6;
    margin-bottom: 10px;
    height: 80px;
  }
}

.lp-content .sec-episode .point-block__row .item::after {
  content: "";
  display: block;
  padding-top: 100%;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .point-block__row .item::after {
    display: none;
  }
}

.lp-content .sec-episode .point-block__row .item small {
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-episode .point-block__row .item small {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.lp-content .sec-episode .point-block__row .item__txt {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.lp-content .sec-voice {
  padding: 100px 0 90px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice {
    padding: 50px 0;
  }
}

.lp-content .sec-voice .sec-ttl {
  position: relative;
  display: inline-block;
  margin-bottom: 45px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .sec-ttl {
    margin-bottom: 30px;
  }
}

.lp-content .sec-voice .sec-ttl::after {
  position: absolute;
  left: -105px;
  bottom: 0;
  content: "";
  display: block;
  background: url(../img/lp/b/voice-ico.png) no-repeat 0 0;
  width: 86px;
  height: 65px;
  background-size: 100% auto;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .sec-ttl::after {
    display: none;
  }
}

.lp-content .sec-voice .voice-block {
  background: #f4f8fa;
  padding: 50px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .voice-block {
    padding: 35px 20px 10px;
  }
}

.lp-content .sec-voice .voice-block__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lp-content .sec-voice .voice-block__row .item {
  width: 48%;
  border: 1px solid #c9c9c9;
  background: #FFF;
  margin-bottom: 40px;
  padding: 40px;
  text-align: left;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .voice-block__row .item {
    width: 100%;
    padding: 25px;
    margin-bottom: 25px;
  }
}

.lp-content .sec-voice .voice-block__row .item__head {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9d9092;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .voice-block__row .item__head {
    flex-wrap: wrap;
  }
}

.lp-content .sec-voice .voice-block__row .item__head .img {
  width: 76px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .voice-block__row .item__head .img {
    margin: 0 auto;
  }
}

.lp-content .sec-voice .voice-block__row .item__head .img span {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  padding-top: 10px;
}

.lp-content .sec-voice .voice-block__row .item__head .ttl {
  width: calc(100% - 76px);
  color: #db3146;
  padding-left: 30px;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  padding-top: 10px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-voice .voice-block__row .item__head .ttl {
    width: 100%;
    font-size: 18px;
    font-size: 1.8rem;
    padding-left: 0;
  }
}

.lp-content .sec-voice .voice-block__row .item__txt {
  line-height: 1.9;
}

.lp-content .sec-item-search {
  padding-bottom: 100px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search {
    padding-bottom: 60px;
  }
}

.lp-content .sec-item-search .ttl {
  background: #482f32;
  padding: 20px 0;
  font-size: 24px;
  font-size: 2.4rem;
  color: #FFF;
  text-align: center;
  margin-bottom: 90px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search .ttl {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 15px 0;
    margin-bottom: 30px;
  }
}

.lp-content .sec-item-search .category-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e4e4e4;
  align-items: center;
}

.lp-content .sec-item-search .category-block__img {
  width: 53.6%;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search .category-block__img {
    width: 100%;
    margin-bottom: 25px;
  }
}

.lp-content .sec-item-search .category-block__content {
  width: 46.4%;
  padding-left: 45px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search .category-block__content {
    width: 100%;
    padding-left: 0;
  }
}

.lp-content .sec-item-search .category-block__ttl {
  font-size: 40px;
  font-size: 4rem;
  font-weight: bold;
  color: #db3146;
  margin-bottom: 25px;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search .category-block__ttl {
    font-size: 22px;
    font-size: 2.2rem;
    margin-bottom: 15px;
  }
}

.lp-content .sec-item-search .category-block__txt {
  line-height: 1.8;
  margin-bottom: 40px;
}

.lp-content .sec-item-search .category-block__btn a {
  position: relative;
  display: block;
  border-radius: 40px;
  background: #db3146;
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  padding: 20px 0;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search .category-block__btn a {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .sec-item-search .category-block__btn a:hover {
  color: #FFF !important;
}

.lp-content .sec-item-search .category-block__btn a::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  display: block;
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 17px;
  font-size: 1.7rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sec-item-search .category-block__btn a::after {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.lp-content .more-btn {
  max-width: 530px;
  margin: 0 auto;
}

.lp-content .more-btn a {
  position: relative;
  display: block;
  background: #482f32;
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  height: 113px;
  line-height: 113px;
}

@media screen and (max-width: 834px) {
  .lp-content .more-btn a {
    height: 80px;
    line-height: 80px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .more-btn a:hover {
  color: #FFF !important;
}

.lp-content .more-btn a::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  display: block;
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .more-btn a::after {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.page-lp_index .lp-content .mv {
  background-image: url("../img/lp/index/mv.png");
}

@media screen and (max-width: 834px) {
  .page-lp_index .lp-content .mv {
    background: none;
  }
}

.page-lp_index .lp-content .mv__inner {
  max-width: 611px;
  padding-top: 160px;
}

@media screen and (max-width: 834px) {
  .page-lp_index .lp-content .mv__inner {
    max-width: 100%;
    padding: 0;
  }
}

.page-lp_index .lp-content .mv__txt {
  max-width: 490px;
  text-align: left;
  float: right;
  padding-right: 50px;
  line-height: 1.8;
  margin-top: 20px;
}

@media screen and (max-width: 834px) {
  .page-lp_index .lp-content .mv__txt {
    max-width: 100%;
    float: none;
    padding-right: 0;
  }
}

.page-lp_index .lp-content .mv__made {
  position: absolute;
  top: 25px;
  right: 20px;
}

@media screen and (max-width: 834px) {
  .page-lp_index .lp-content .mv__img {
    position: relative;
    margin-bottom: 30px;
  }
}

.page-lp_index .lp-content .mv .object {
  position: absolute;
  bottom: -100px;
  right: 50px;
  width: 205px;
}

@media screen and (max-width: 834px) {
  .page-lp_index .lp-content .mv .object {
    bottom: -40px;
    width: 102px;
    right: 15px;
  }
}

.page-lp_index .lp-content .intro-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.lp-content .btn-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.lp-content .btn-block .more-btn {
  max-width: 100%;
  width: 48%;
  font-family: "Noto Serif JP", serif;
  margin: 0;
}

@media screen and (max-width: 990px) {
  .lp-content .btn-block .more-btn {
    width: 100%;
    margin-bottom: 15px;
  }
}

.lp-content .sheet-about {
  padding: 70px 0 60px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about {
    padding: 50px 0 40px;
  }
}

.lp-content .sheet-about .inner {
  position: relative;
}

.lp-content .sheet-about .intro-block {
  position: relative;
  max-width: 1000px;
  margin: 0 auto 60px;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block {
    margin-bottom: 40px;
  }
}

.lp-content .sheet-about .intro-block__img {
  width: 44.7%;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__img {
    position: relative;
    width: 85%;
    margin: 0 auto 30px;
  }
  .lp-content .sheet-about .intro-block__img img {
    width: 100%;
  }
}

.lp-content .sheet-about .intro-block__content {
  width: 55.3%;
  padding-left: 50px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__content {
    width: 100%;
    padding-left: 0;
  }
}

.lp-content .sheet-about .intro-block__ttl {
  font-family: "Noto Serif JP", serif;
  margin-bottom: 45px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__ttl {
    margin-bottom: 30px;
  }
}

.lp-content .sheet-about .intro-block__ttl small {
  display: block;
  font-size: 21px;
  font-size: 2.1rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__ttl small {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .sheet-about .intro-block__ttl span {
  display: inline;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.6;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, #ffdaad));
  background: -o-linear-gradient(transparent 60%, #ffdaad 60%);
  background: linear-gradient(transparent 60%, #ffdaad 60%);
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__ttl span {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.lp-content .sheet-about .intro-block__sub-ttl {
  display: inline-block;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Noto Serif JP", serif;
  padding-bottom: 8px;
  border-bottom: 1px dotted #333;
  margin-bottom: 25px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__sub-ttl {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

.lp-content .sheet-about .intro-block__txt {
  line-height: 1.9;
}

.lp-content .sheet-about .intro-block__en {
  position: absolute;
  left: -30px;
  bottom: -50px;
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-size: 100px;
  font-size: 10rem;
  color: #94d2c5;
  opacity: 0.15;
  font-weight: 900;
  z-index: 1;
  line-height: 1;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-about .intro-block__en {
    font-size: 40px;
    font-size: 4rem;
    left: 0;
    bottom: -30px;
  }
}

.lp-content .sheet-choice {
  background: #94d2c5;
  padding: 90px 0 100px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice {
    padding: 50px 0;
  }
}

.lp-content .sheet-choice .sec-ttl {
  color: #FFF;
  font-family: "Noto Serif JP", serif;
}

.lp-content .sheet-choice .sec-ttl .sub-ttl {
  color: #FFF;
}

.lp-content .sheet-choice .sec-ttl .sub-ttl::after, .lp-content .sheet-choice .sec-ttl .sub-ttl::before {
  background: #FFF;
}

.lp-content .sheet-choice .lead {
  color: #FFF;
}

.lp-content .sheet-choice .choice-block {
  max-width: 1000px;
  margin: 0 auto 50px;
  background: #bee4dc;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .choice-block {
    margin-bottom: 35px;
  }
}

.lp-content .sheet-choice .choice-block__content {
  padding: 50px 70px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .choice-block__content {
    padding: 30px 20px;
  }
}

.lp-content .sheet-choice .choice-block__ttl {
  width: 280px;
  font-family: "Noto Serif JP", serif;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.7;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .choice-block__ttl {
    width: 100%;
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
}

.lp-content .sheet-choice .choice-block__txt {
  width: calc(100% - 280px);
  padding-left: 70px;
  line-height: 1.8;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .choice-block__txt {
    width: 100%;
    padding-left: 0;
  }
}

.lp-content .sheet-choice .circle-block {
  max-width: 1000px;
  margin: 0 auto 230px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .circle-block {
    margin-bottom: 70px;
  }
}

.lp-content .sheet-choice .circle-block .item {
  position: relative;
  width: 23.5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #FFF;
  text-align: center;
  border-radius: 100%;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .circle-block .item {
    width: 48.5%;
    margin-bottom: 20px;
  }
}

.lp-content .sheet-choice .circle-block .item--01 {
  background-image: url("../img/lp/index/circle-img01.png");
}

.lp-content .sheet-choice .circle-block .item--02 {
  background-image: url("../img/lp/index/circle-img02.png");
}

.lp-content .sheet-choice .circle-block .item--03 {
  background-image: url("../img/lp/index/circle-img03.png");
}

.lp-content .sheet-choice .circle-block .item--04 {
  background-image: url("../img/lp/index/circle-img04.png");
}

.lp-content .sheet-choice .circle-block .item::after {
  content: "";
  display: block;
  padding-top: 100%;
}

.lp-content .sheet-choice .circle-block .item__ttl {
  font-size: 21px;
  font-size: 2.1rem;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Noto Serif JP", serif;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .circle-block .item__ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.lp-content .sheet-choice .circle-block .item__ttl span {
  background: #482f32;
  display: inline-block;
  color: #FFF;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 5px 15px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .circle-block .item__ttl span {
    font-size: 13px;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
}

.lp-content .sheet-choice .point-block {
  position: relative;
  background: #FFF;
  margin-bottom: 215px;
  min-height: 355px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block {
    flex-wrap: wrap;
    min-height: inherit;
    margin-bottom: 60px;
  }
}

.lp-content .sheet-choice .point-block:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block::after {
    content: "";
    display: block;
    width: 19%;
    height: 100%;
    background: #94d2c5;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--left {
    margin-right: 45px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--left::after {
    left: 0;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--left .point-block__content {
    padding-right: 170px;
    left: -25px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--left .point-block__en {
    right: 25px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--left .point-block__object {
    right: 45px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--left .point-block__txt {
    float: right;
    margin-right: 25px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--right {
    margin-left: 45px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--right::after {
    right: 0;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--right .point-block__img {
    order: 2;
    position: absolute;
    right: 0;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--right .point-block__content {
    order: 1;
    width: 100%;
    padding-left: 225px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--right .point-block__en {
    left: 25px;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .sheet-choice .point-block--right .point-block__object {
    left: 45px;
  }
}

.lp-content .sheet-choice .point-block__img {
  position: relative;
  z-index: 2;
  top: -125px;
  width: 43%;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__img {
    width: 100%;
    top: -35px;
  }
  .lp-content .sheet-choice .point-block__img img {
    width: 100%;
  }
}

.lp-content .sheet-choice .point-block__content {
  position: relative;
  z-index: 3;
  width: 57%;
  top: -100px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__content {
    width: 100%;
    top: -45px;
    padding: 0 20px;
  }
}

.lp-content .sheet-choice .point-block__object {
  width: 135px;
  position: absolute;
  z-index: 1;
  top: 35px;
}

.lp-content .sheet-choice .point-block__en {
  position: absolute;
  bottom: 10px;
  font-family: "Crimson Text", serif;
  font-size: 100px;
  font-size: 10rem;
  color: #94d2c5;
  font-weight: 400;
  opacity: 0.15;
  line-height: 1;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__en {
    font-size: 40px;
    font-size: 4rem;
    left: 0;
  }
}

.lp-content .sheet-choice .point-block__point {
  position: relative;
  background: #FFF;
  display: inline-block;
  padding: 5px 30px 5px 80px;
  border-radius: 20px;
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__point {
    box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 0.6);
  }
}

.lp-content .sheet-choice .point-block__point span {
  position: absolute;
  top: 50%;
  margin-top: -33px;
  left: -5px;
  display: block;
  width: 67px;
  height: 67px;
  text-align: center;
  background: #FFF;
  color: #db3146;
  border-radius: 100px;
  font-size: 36px;
  font-size: 3.6rem;
  font-family: "Crimson Text", serif;
  line-height: 1.2;
  padding-top: 10px;
  font-weight: 600;
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 0.6);
}

.lp-content .sheet-choice .point-block__point span small {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  font-size: 1.4rem;
}

.lp-content .sheet-choice .point-block__ttl {
  margin-bottom: 25px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__ttl {
    margin-bottom: 20px;
  }
}

.lp-content .sheet-choice .point-block__ttl span {
  display: inline-block;
  background: #db3146;
  padding: 0 15px;
  color: #FFF;
  line-height: 1.6;
  font-family: "Noto Serif JP", serif;
  font-size: 40px;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 5px;
}

@media screen and (max-width: 990px) {
  .lp-content .sheet-choice .point-block__ttl span {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__ttl span {
    font-size: 19px;
    font-size: 1.9rem;
    padding: 0 10px;
  }
}

.lp-content .sheet-choice .point-block__txt {
  max-width: 290px;
  line-height: 1.8;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-choice .point-block__txt {
    max-width: 100%;
  }
}

.lp-content .sheet-life {
  padding: 60px 0 95px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-life {
    padding: 40px 0 0;
  }
}

.lp-content .sheet-life .sec-ttl {
  position: relative;
  font-family: "Noto Serif JP", serif;
  padding-top: 50px;
}

.lp-content .sheet-life .sec-ttl::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 30%;
  background: url("../img/lp/index/start-ttl.png") no-repeat 0 0;
  background-size: 100% auto;
  width: 163px;
  height: 48px;
}

.lp-content .sheet-life .sec-ttl small {
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-life .sec-ttl small {
    font-size: 20px;
    font-size: 2rem;
  }
}

.lp-content .sheet-life .life-block {
  padding: 0 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lp-content .sheet-life .life-block .item {
  width: 29.25%;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-life .life-block .item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.lp-content .sheet-life .life-block .item__img {
  position: relative;
}

.lp-content .sheet-life .life-block .item__ttl {
  display: inline-block;
  line-height: 1.6;
  background: -o-linear-gradient(transparent 60%, #ffdaad 60%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, #ffdaad));
  background: linear-gradient(transparent 60%, #ffdaad 60%);
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 990px) {
  .lp-content .sheet-life .life-block .item__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.lp-content .sheet-life .life-block .item__content {
  background: url("../img/lp/index/life-bg.png") no-repeat 0 0;
  background-size: cover;
  padding: 40px 35px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-life .life-block .item__content {
    padding: 35px 20px;
  }
}

.lp-content .sheet-life .life-block .item__desc {
  position: absolute;
  bottom: -40px;
  left: -15px;
  background: url("../img/lp/index/life-bl.png") no-repeat 0 0;
  background-size: cover;
  width: 126px;
  height: 117px;
  color: #f5b86e;
  font-weight: bold;
  line-height: 1.3;
  padding-top: 30px;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-life .life-block .item__desc {
    font-size: 16px;
    font-size: 1.6rem;
    padding-top: 35px;
  }
}

.lp-content .sheet-life .life-block .item__txt {
  text-align: left;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.8;
}

.lp-content .sheet-info {
  padding: 60px 0 95px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info {
    padding: 30px 0 50px;
  }
}

.lp-content .sheet-info .ttl {
  position: relative;
  padding: 40px 0;
  background: #482f32;
  color: #FFF;
  text-align: center;
  background: #482f32;
  font-size: 36px;
  font-size: 3.6rem;
  margin-bottom: 85px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .ttl {
    font-size: 24px;
    font-size: 2.4rem;
    padding: 25px 0;
    margin-bottom: 50px;
  }
}

.lp-content .sheet-info .ttl span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  font-size: 100px;
  font-size: 10rem;
  font-family: "Crimson Text", serif;
  font-weight: 600;
  opacity: 0.05;
  line-height: 1;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .ttl span {
    font-size: 40px;
    font-size: 4rem;
    bottom: -10px;
  }
}

.lp-content .sheet-info .intro-block {
  position: relative;
  max-width: 835px;
  margin: 0 auto 115px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .intro-block {
    margin-bottom: 70px;
  }
}

.lp-content .sheet-info .intro-block__img {
  width: 43.9%;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .intro-block__img {
    width: 70%;
    margin: 0 auto;
  }
}

.lp-content .sheet-info .intro-block__content {
  width: 56.1%;
  max-width: 380px;
  padding-left: 50px;
  padding-top: 50px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .intro-block__content {
    width: 100%;
    padding-left: 0;
  }
}

.lp-content .sheet-info .intro-block__ttl {
  font-family: "Noto Serif JP", serif;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .intro-block__ttl {
    margin-bottom: 20px;
  }
}

.lp-content .sheet-info .intro-block__ttl span {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 23px;
  font-size: 2.3rem;
  padding: 0 10px;
  font-weight: bold;
  line-height: 1.7;
  display: inline;
  background: #db3146;
  color: #FFF;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-info .intro-block__ttl span {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.lp-content .sheet-info .intro-block__txt {
  line-height: 1.8;
}

.lp-content .lineup {
  position: relative;
  background: #f3efea;
  padding: 65px 0 85px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup {
    padding: 40px 0;
  }
}

.lp-content .lineup__ttl {
  display: inline-block;
  font-size: 21px;
  font-size: 2.1rem;
  background: #FFF;
  border: 1px solid #c5c4c2;
  border-radius: 20px;
  font-weight: bold;
  width: 250px;
  position: absolute;
  height: 45px;
  line-height: 43px;
  top: -23px;
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.lp-content .lineup__ttl02 {
  color: #FFF;
  font-size: 36px;
  font-size: 3.6rem;
  font-family: "Crimson Text", serif;
  margin-bottom: 45px;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup__ttl02 {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 25px;
  }
}

.lp-content .lineup .lineup-item {
  padding: 45px 45px 70px;
  background: #FFF;
  text-align: left;
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item {
    padding: 35px 25px 45px;
    margin-bottom: 35px;
  }
}

.lp-content .lineup .lineup-item__head {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

@media screen and (min-width: 835px) {
  .lp-content .lineup .lineup-item__head.type02 .lineup-item__ttl {
    width: 40%;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .lineup .lineup-item__head.type02 .lineup-item__spec {
    width: 60%;
    padding: 15px 20px;
  }
}

.lp-content .lineup .lineup-item__ttl {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__ttl {
    width: 100%;
    margin-bottom: 25px;
  }
}

.lp-content .lineup .lineup-item__ttl span {
  display: inline-block;
  border-bottom: 1px solid #333;
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "Crimson Text", serif;
  margin-bottom: 15px;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__ttl span {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.lp-content .lineup .lineup-item__ttl strong {
  display: inline-block;
  vertical-align: middle;
  font-size: 40px;
  font-size: 4rem;
  color: #db3146;
  font-family: "Noto Serif JP", serif;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__ttl strong {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.lp-content .lineup .lineup-item__ttl small {
  display: inline-block;
  vertical-align: middle;
  color: #db3146;
  font-size: 21px;
  font-size: 2.1rem;
  margin-left: 10px;
  font-family: "Crimson Text", serif;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__ttl small {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lp-content .lineup .lineup-item__spec {
  width: 50%;
  border: 1px solid #dadada;
  padding: 15px 65px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__spec {
    width: 100%;
    padding: 10px 20px;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.lp-content .lineup .lineup-item__spec dt {
  float: left;
  width: 80px;
  background: #f0f0f0;
  text-align: center;
  margin-right: 15px;
  padding: 3px 0;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__spec dt {
    float: none;
    margin-bottom: 10px;
  }
}

.lp-content .lineup .lineup-item__spec dd {
  overflow: hidden;
  padding-top: 3px;
}

.lp-content .lineup .lineup-item__txt {
  margin-bottom: 40px;
}

.lp-content .lineup .lineup-item__list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__list {
    margin-bottom: 25px;
  }
}

.lp-content .lineup .lineup-item__list li {
  position: relative;
  width: 50%;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__list li {
    width: 100%;
  }
}

.lp-content .lineup .lineup-item__list li span {
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  width: 85px;
  height: 85px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__list li span {
    width: 65px;
    height: 65px;
    font-size: 12px;
    font-size: 1.2rem;
    top: 15px;
    left: 15px;
  }
}

.lp-content .lineup .lineup-item__price {
  font-size: 30px;
  font-size: 3rem;
  color: #482f32;
  text-align: center;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  margin-bottom: 35px;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__price {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 25px;
  }
}

.lp-content .lineup .lineup-item__price span {
  color: #db3146;
}

.lp-content .lineup .lineup-item__price small {
  color: #db3146;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__price small {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.lp-content .lineup .lineup-item__btn {
  max-width: 550px;
  text-align: center;
  margin: 0 auto;
}

.lp-content .lineup .lineup-item__btn a {
  display: block;
  color: #FFF;
  border-radius: 50px;
  font-size: 36px;
  font-size: 3.6rem;
  background: #db3146;
  font-family: "Noto Serif JP", serif;
  padding: 25px 0;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__btn a {
    font-size: 20px;
    font-size: 2rem;
    padding: 20px 0;
  }
}

.lp-content .lineup .lineup-item__btn a i {
  margin-right: 10px;
}

.lp-content .lineup .lineup-item__btn a:hover {
  color: #FFF !important;
}

@media screen and (min-width: 835px) {
  .lp-content .lineup .lineup-item__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 835px) {
  .lp-content .lineup .lineup-item__row .item {
    width: 50%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .lineup-item__row .item:first-of-type {
    margin-bottom: 30px;
  }
}

.lp-content .lineup .lineup-item__row .item .lineup-item__price br {
  display: block !important;
}

@media screen and (min-width: 835px) {
  .lp-content .lineup .lineup-item__row .item .lineup-item__btn {
    max-width: 445px;
  }
}

.lp-content .lineup .btn-block {
  margin-top: 90px;
}

@media screen and (max-width: 834px) {
  .lp-content .lineup .btn-block {
    margin-top: 50px;
  }
}

.lp-content .sheet-type {
  padding-bottom: 125px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-type {
    padding-bottom: 20px;
  }
}

.lp-content .sheet-type .sec-ttl {
  font-size: 27px;
  font-size: 2.7rem;
  font-family: "Noto Serif JP", serif;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-type .sec-ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.lp-content .sheet-type .lead {
  max-width: inherit;
}

.lp-content .sheet-type .type-block {
  max-width: 1000px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lp-content .sheet-type .type-block .item {
  width: 31.2%;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-type .type-block .item {
    width: 100%;
    margin-bottom: 35px;
  }
}

.lp-content .sheet-type .type-block .item__ttl {
  color: #482f32;
  font-size: 19px;
  font-size: 1.9rem;
  font-family: "Noto Serif JP", serif;
  padding: 25px 0 20px;
}

@media screen and (max-width: 834px) {
  .lp-content .sheet-type .type-block .item__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 15px 0;
  }
}

.lp-content .sheet-type .type-block .item__ttl span {
  font-weight: bold;
}

.lp-content .sheet-type .type-block .item__btn {
  max-width: 550px;
  text-align: center;
  margin: 0 auto;
}

.lp-content .sheet-type .type-block .item__btn a {
  display: block;
  color: #FFF;
  border-radius: 50px;
  font-size: 18px;
  font-size: 1.8rem;
  background: #db3146;
  font-family: "Noto Serif JP", serif;
  line-height: 53px;
}

.lp-content .sheet-type .type-block .item__btn a i {
  margin-right: 10px;
}

.lp-content .sheet-type .type-block .item__btn a:hover {
  color: #FFF !important;
}
