.sec-product-detail {
	.ttl-comp04 {
		@include mq(md-min) {
			padding-left: 39px;
			padding-right: 39px;
		}
	}
	.list-product {
		margin-bottom: -20px;
	}
	.ttl-comp04--green {
		background-color: #94D1C5;
	}
}

.product-detail {
	&__img-for {
		.slick-list {
			margin: 0 -13px 15px;
			@include mq {
				margin: 0 -7px 15px;
			}
		}
		.item {
			padding: 0;
			margin-bottom: 20px;
			@include mq {
				padding: 0 7px;
				margin-bottom: 15px;
			}
			&:focus {
				outline: none;
			}
			position: relative;
			overflow: hidden;
			&::after{
				content: "";
				display: block;
				padding-top: 55%;
			}
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: auto;
				max-height: inherit;
				height: 100%;
			}
		}
	}
	&__img-nav {
		.slick-list {
			margin: 0 -13px 15px;
			@include mq {
				margin: 0 0 15px;
			}
		}
		.slick-track {
			display: flex;
			flex-wrap: wrap;
			width: 100% !important;
			transform: none!important;
			&:before, &:after {
				display: none;
			}
		}
		.item {
			width: 23.5% !important;
			margin-bottom: 25px;
			position: relative;
			overflow: hidden;
			margin-right: 2%;
			float: none;
			height: auto;
			&:nth-child(4n){
				margin-right: 0;
			}
			&::after{
				content: "";
				display: block;
				padding-top: 65%;
			}
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;'
			}
			@include mq {
				width: 32% !important;
				margin-bottom: 15px;
				&:nth-child(4n){
					margin-right: 1.9%;
				}
				&:nth-child(3n){
					margin-right: 0;
				}
			}
			@include mq(sm) {
				
			}
			&:focus {
				outline: none;
			}
		}
	}
	&__value {
		display: flex;
		flex-wrap: wrap;
		@include font-size(16);
		margin: 0 12px 63px -24px;
		@include mq {
			margin: 0 -7px 20px;
		}
		.item {
			display: flex;
			padding: 0 24px;
			margin-bottom: 8px;
			width: 50%;
			@include mq {
				padding: 0 7px;
				margin-bottom: 10px;
			}
			@include mq(sm) {
				width: 100%;
			}
			&--lg {
				width: 100%;
			}
			&__label {
				color: #323232;
				font-weight: 500;
				@include font-size(16);
				text-align: center;
				padding: 20px 10px 22px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #e6e6e6;
				width: 80px;
				@include mq {
					@include font-size(14);
				}
			}
			&__content {
				width: calc(100% - 80px);
				padding-left: 19px;
				display: flex;
				align-items: center;
			}
			&__price {
				color: #db3146;
				@include font-size(24);
				font-weight: 500;
				padding: 0 0 10px 2px;
				@include mq {
					@include font-size(20);
					padding: 0 0 0 0;
				}
				small {
					@include font-size(14);
					@include mq {
						@include font-size(12);
					}
				}
			}
			&__select {
				width: 100%;
				border: 1px solid #e6e6e6;
				background-color: #FFFFFF;
				padding: 0 44px 0 23px;
				height: 40px;
				vertical-align: middle;
				color: #666666;
				@include font-size(18);
				font-weight: 500;
				background: url("../img/detail/ico-select-dropdown.png") no-repeat right center;
				@include mq {
					@include font-size(16);
				}
				&::-ms-expand {
					display: none;
				}
				&--number {
					width: auto;
					padding: 16px 44px 20px 9px;
				}
			}
			input[type="text"]{
				width: 100px;
				border: 1px solid #e6e6e6;
				background-color: #FFFFFF;
				padding: 0 44px 0 23px;
				height: 40px;
				vertical-align: middle;
				color: #666666;
				@include font-size(18);
				font-weight: 500;
				@include mq {
					@include font-size(16);
				}
			}
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 -10px 78px;
		@include mq {
			margin: 0 -7px 50px;
		}
		@include mq(sm) {
			flex-wrap: wrap;
			margin-bottom: 40px;
		}
		.item {
			width: 100%;
			max-width: 340px;
			padding: 0 10px;
			@include mq {
				padding: 0 7px;
			}
			@include mq(sm) {
				max-width: none;
				margin-bottom: 10px;
			}
			button,a{
				width: 100%;
			}
		}
	}
	&__content {
		border: 1px solid #e6e6e6;
		padding: 46px 67px 57px;
		color: #333333;
		margin-bottom: 75px;
		@include font-size(21);
		@include mq {
			padding: 30px 15px;
			margin-bottom: 50px;
			@include font-size(16);
		}
		.ttl {
			text-align: center;
			@include font-size(24);
			line-height: 1.25;
			font-weight: 500;
			margin-bottom: 27px;
			@include mq {
				@include font-size(20);
				margin-bottom: 30px;
			}
		}
		.txt {
			font-weight: 300;
			@include font-size(18);
			line-height: 1.56;
			margin-bottom: 30px;
			@include mq {
				@include font-size(16);
			}
		}
		.list {
			br{
				display: none;
			}
			span {
				font-weight: 300;
				@include font-size(18);
				line-height: 1.56;
				display: block;
				margin-bottom: 6px;
				@include mq {
					@include font-size(16);
				}
			}
			dl {
				display: flex;
				margin-bottom: 2px;
				font-weight: 300;
				@include font-size(18);
				background-color: #f7f7f7;
				@include mq {
					@include font-size(16);
				}
			}
			dt {
				width: 34%;
				text-align: center;
				background-color: #e5e5e5;
				padding: 8px 8px 7px;
				@include mq {
					padding: 8px 8px 9px;
				}
			}
			dd {
				width: 66%;
				padding: 8px 10% 7px;
				@include mq {
					padding: 8px 10px;
				}
			}
		}
	}
}