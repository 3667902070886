
body {
	color: #323232;
	font-family: $font-notoSanJp;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
}


//header
.header {
	height: 290px;
	@include font-size(18);
	line-height: 1.4;
	padding-top: 23px;
	background: white;
	position: relative;
	z-index: 999;
	@include mq(xl) {
		@include font-size(15);
	}
	@include mq(ipd) {
		@include font-size(12);
	}
	@include mq {
		padding: 10px 0;
		height: 60px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}
	&__img{
		@include font-size(12);
		font-weight: 500;
		span{
			display: block;
			text-align: center;
		}
	}
	&__top {
		display: flex;
		align-items: center;
		margin-bottom: 21px;
		@include mq {
			margin-bottom: 0;
		}
	}
	&__logo {
		padding-right: 15px;
		color: #000;
		text-decoration: none;
		@include mq {
			img{
				width: 254px;
			}
		}
		span{
			display: block;
			margin-bottom: 20px;
			@include mq {
				margin-bottom: 5px;
				font-size: 10px;
			}
		}
	}
	&-search {
		margin-left: auto;
		width: 100%;
		max-width: 291px;
		position: relative;
		margin-top: 10px;
		@include mq {
			margin: 30px auto;
		}
		&__inp {
			display: block;
			width: 100%;
			border-radius: 100px;
			height: 54px;
			background: #f4f4f4;
			border: none;
			padding: 3px 60px 5px 27px;
			color: #878787;
			font-weight: 500;
			font-family: inherit;
			outline: none;
			@include mq {
				height: 40px;
			}
		}
		&__btn {
			position: absolute;
			top: 51%;
			transform: translateY(-50%);
			right: 24px;
			@include font-size(24);
			color: #999999;
			background: transparent;
			border: none;
			@include smooth-transition;
			cursor: pointer;
			@include mq(md-min) {
				&:hover {
					opacity: 0.7;
				}
			}
			@include mq {
				@include font-size(20);
				right: 10px;
			}
		}
	}
	&__nav {
		@include mq(md-min) {
			display: flex;
			align-items: flex-start;
		}
		@include mq {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			top: 60px;
			background: white;
			padding: 0 15px;
			overflow-x: hidden;
			overflow-y: auto;
			opacity: 0;
			pointer-events: none;
			@include smooth-transition;
			z-index: 999;
			&.on {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	&__img {
		@include mq {
			text-align: center;
			margin-bottom: 30px;
			display: none;
		}
	}
	&-menu {
		display: flex;
		margin-left: auto;
		text-align: center;
		padding-top: 20px;
		white-space: nowrap;
		@include mq(ipd) {
			padding-top: 30px;
		}
		@include mq {
			padding: 0;
			margin: 0 -15px 50px;
			flex-wrap: wrap;
			align-items: stretch;
			border-top: 1px solid #323232;
			border-bottom: 1px solid #323232;
		}
		&__item {
			margin-left: 25px;
			@include mq(xl) {
				margin-left: 20px;
			}
			@include mq {
				width: 50%;
				margin: 0;
				border-bottom: 1px solid #323232;
				&:nth-child(odd) {
					border-right: 1px solid #323232;
				}
			}
			a {
				@include mq {
					display: block;
					width: 100%;
					height: 100%;
					padding: 10px 10px 10px 10px;
				}
				&:hover {
					opacity: 1;
					.ico {
						color: #db3146;
					}
				}
				.ico {
					@include smooth-transition;
					display: block;
					margin-bottom: 2px;
					@include font-size(24);
					@include mq(xl) {
						@include font-size(20);
					}
					@include mq(ipd) {
						@include font-size(16);
					}
				}
			}
			&.active {
				a {
					.ico {
						color: #db3146;
					}
				}
			}
		}
	}
	&__control {
		display: block;
		width: 30px;
		height: 20px;
		position: relative;
		margin-left: auto;
		@include mq(md-min) {
			display: none;
		}
		span {
			position: absolute;
			width: 100%;
			height: 2px;
			background: #323232;
			@include smooth-transition;
			&:first-child {
				top: 0;
			}
			&:nth-of-type(2) {
				top: 46%;
			}
			&:last-child {
				bottom: 0;
			}
		}
		&.close {
			span {
				&:first-child {
					transform: rotate(45deg);
					top: 46%;
				}
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:last-child {
					transform: rotate(-45deg);
					bottom: 46%;
				}
			}
		}
	}
	.fnav-list{
		@include mq(md-min) {
			display: none;
		}
		.fnav-list__item{
			@include mq {
				min-height: 75px;
			}
		}
		.fnav-list__wrap{
			@include mq {
			    padding: 10px 5px 5px;
			}
		}
		.fnav-list__wrap .img{
			@include mq {
				min-height: 20px;
			}
		}
		.fnav-list__wrap .txt{
			@include mq {
				@include font-size(13);
			}
				
		}
	}
}

// main-page
.main-page {
	@include mq {
		margin-top: 60px;
	}
}


//sec-info
.sec-info {
	padding: 132px 0 123px;
	border-top: 1px solid #dddddd;
	@include mq(xl) {
		padding: 90px 0;
	}
	@include mq {
		padding: 50px 0;
	}
}
.info {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		@include font-size(16);
		line-height: 1.875;
		@include mq(xl) {
			@include font-size(14);
			//margin-bottom: 90px;
		}
		@include mq {
		//	margin-bottom: 50px;
		}
		.txt-lg {
			@include font-size(18);
			line-height: 2;
			font-weight: bold;
			display: block;
			margin-bottom: 2px;
			@include mq(xl) {
				@include font-size(16);
			}
		}
		&__item {
			width: calc(33.33% - 32px);
			margin-right: 48px;
			@include mq(ipd-min) {
				&:nth-child(3n+3) {
					margin-right: 0;
				}
			}
			@include mq(xl) {
				width: calc(33.33% - 10px);
				margin-right: 15px;
			}
			@include mq(ipd) {
				width: calc(50% - 10px);
				margin-right: 20px;
				margin-bottom: 50px;
				&:nth-child(2n+2) {
					margin-right: 0;
				}
				&:last-child {
					width: 100%;
					margin-right: 0;
					margin-bottom: 0;
				}
			}
			@include mq {
				width: 100%;
				margin-right: 0;
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
				.fc {
					table {
						@include font-size(16);
					}
					.fc-col-header-cell-cushion {
						padding: 6px;
					}
				}
			}
		}
	}
	&-new {
		&__item {
			border-bottom: 1px dotted #cccccc;
			padding-bottom: 32px;
			margin-bottom: 31px;
			@include mq {
				padding-bottom: 15px;
				margin-bottom: 15px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			a {
				display: block;
			}
			.date {
				display: block;
				font-family: $font-lato;
				color: #db3146;
				margin-bottom: 8px;
			}
		}
	}
	&-mail {
		&__txt {
			margin-bottom: 41px;
			@include mq {
				margin-bottom: 15px;
			}
		}
		&__form {
			position: relative;
			.inp {
				@include font-size(18);
				font-weight: 500;
				line-height: 1.6;
				font-family: $font-notoSanJp;
				width: 100%;
				display: block;
				background: white;
				border: 1px solid #bbbbbb;
				height: 67px;
				color: #878787;
				padding: 5px 135px 6px 25px;
				border-radius: 0;
				@include mq(xl) {
					@include font-size(14);
				}
				@include mq {
					height: 45px;
				}
			}
			.btn {
				@include font-size(18);
				font-weight: 500;
				line-height: 1.6;
				font-family: $font-notoSanJp;
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				border: none;
				background: #333333;
				color: white;
				text-align: center;
				width: 129px;
				cursor: pointer;
				@include smooth-transition;
				@include mq(xl) {
					@include font-size(14);
					width: 100px;
				}
				&:hover {
					opacity: 0.7;
				}
				&.mac {
					top: -2px;
					bottom: -2px;
				}
			}
		}
	}
}
.fnav-list {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	text-align: center;
	margin-bottom: 125px;
		@include mq(xl) {
			margin-bottom: 90px;
		}
		@include mq {
		margin-bottom: 50px;
		}
	&__item {
		width: 20%;
		border-bottom: 1px solid #dddddd;
		border-right: 1px solid #dddddd;
		min-height: 120px;
		@include mq {
			width: 25%;
			min-height: 100px;
		}
		@include mq(sm) {
			width: 50%;
		}
	}
	&__wrap {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 5px 11px;
		@include mq(xl) {
			flex-wrap: wrap;
		}
		.img {
			margin-right: 12px;
			@include mq(xl) {
				width: 100%;
				min-height: 54px;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				margin-right: 0;
			}
			@include mq {
				min-height: 30px;
			}
		}
		.txt {
			@include font-size(21);
			font-weight: bold;
			line-height: 1.4;
			@include mq(xl) {
				@include font-size(18);
				width: 100%;
			}
			@include mq {
				@include font-size(16);
			}
			.en {
				font-family: $font-lato;
				@include font-size(12);
				font-weight: 500;
				display: block;
				margin-top: 0;
				@include mq(xl) {
					@include font-size(10);
				}
			}
		}
	}
}


//footer
.footer{
	@include font-size(18);
	line-height: 1.67;
	&__inner {
		width: 100%;
		max-width: 1614px;
		margin: 0 auto;
		padding: 0 15px;
		@include mq(mac) {
			@include font-size(16);
		}
		@include mq(xl) {
			@include font-size(14);
		}
	}
	&__top {
		background: #f9f9f9;
		border-top: 1px solid #dddddd;
		padding: 99px 0 15px;
		@include mq {
			padding: 50px 0 15px;
		}
		.footer__inner {
			display: flex;
			flex-wrap: wrap;
			.item {
				width: calc(33.33% - 28px);
				margin-right: 42px;
				@include mq(mac) {
					width: calc(33.33% - 10px);
					margin-right: 15px;
				}
				@include mq(xl-min) {
					&:nth-of-type(3n+3) {
						margin-right: 0;
					}
				}
				@include mq(xl) {
					width: calc(50% - 10px);
					margin-right: 20px;
					margin-bottom: 30px;
					&:nth-of-type(2n+2) {
						margin-right: 0;
					}
				}
				@include mq(sm) {
					width: 100%;
					margin-right: 0;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.ttl {
					@include font-size(21);
					line-height: 1.4;
					font-weight: 500;
					border-bottom: 1px solid #b7b6b6;
					padding-bottom: 11px;
					margin-bottom: 38px;
					@include mq(mac) {
						@include font-size(19);
					}
					@include mq(xl) {
						@include font-size(17);
						margin-bottom: 20px;
					}
				}
				.list {
					li {
						margin-bottom: 47px;
						@include mq {
							margin-bottom: 20px;
						}
						&:last-child {
							margin-bottom: 0;
						}
						.txt-lg {
							@include font-size(21);
							line-height: 1.72;
							font-weight: 500;
							display: block;
							@include mq(mac) {
								@include font-size(19);
							}
							@include mq(xl) {
								@include font-size(17);
							}
							& + img {
								margin: 9px 0 26px 2px;
								display: block;
							}
						}
					}
				}
			}
		}
	}
	&__bottom {
		background: #db3146;
		color: white;
		padding:41px 0 48px;
		@include mq {
			padding: 50px 0;
		}
		.footer__inner {
			max-width: 1660px;
			@include mq(mac-min) {
				padding-left: 38px;
			}
			@include mq(lp-min) {
				display: flex;
				align-items: flex-start;
			}
			@include mq(lp) {
				text-align: center;
			}
			.left {
				@include mq(lp) {
					margin: 0 0 30px;
				}
				.logo-f {
					margin: 0 0 33px 8px;
					display: block;
					@include mq(lp) {
						margin: 0 0 30px;
					}
				}
				.info {
					&[href^="tel"] {
						@include mq(ipd-min){
							@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
								display: inline-block;
							}
						}
					}
				}
			}
			.right {
				margin-left: auto;
				@include font-size(16);
				line-height: 1.2;
				font-weight: 500;
				padding-top: 26px;
				@include mq(mac) {
					@include font-size(14);
				}
				@include mq(lp) {
					padding: 0;
				}
				@include mq(xl) {
					@include font-size(12);
				}
				.menu {
					display: flex;
					margin-bottom: 117px;
					@include mq(mac) {
						margin-bottom: 100px;
					}
					@include mq(lp) {
						justify-content: center;
						flex-wrap: wrap;
						margin-bottom: 40px;
					}
					li {
						margin-left: 21px;
						@include mq(mac) {
							margin-left: 10px;
						}
						@include mq(lp) {
							margin: 0 5px 10px;
						}
						a {
							display: block;
							padding: 6px 15px 6px 12px;
							border-radius: 2px;
							@include mq(md-min) {
								&:hover {
									opacity: 1;
									background: white;
									color: #db3146 !important;
								}
							}
						}
						&.active {
							a {
								opacity: 1;
								background: white;
								color: #db3146 !important;
							}
						}
					}
				}
				.copyright {
					font-family: $font-lato;
					text-align: right;
					letter-spacing: 0.2px;
					color: #eeeeee;
					@include mq(lp) {
						text-align: center;
					}
				}
			}
		}
	}
	&__txt {
		margin-top: 50px;
		@include mq(mac) {
			@include font-size(16);
		}
		@include mq(xl) {
			@include font-size(14);
		}
		a {
			text-decoration: underline;
			text-underline-position: under;
			&:hover {
				text-decoration: underline !important;
			}
		}
	}
}

