.page-lp_index{
	.lp-content{
		.mv{
			background-image: url("../img/lp/index/mv.png");
			@include mq() {
				background: none;
			}
			&__inner{
				max-width: 611px;
				padding-top: 160px;
				@include mq() {
					max-width: 100%;
					padding: 0;
				}
			}
			&__txt{
				max-width: 490px;
				text-align: left;
				float: right;
				padding-right: 50px;
				line-height: 1.8;
				margin-top: 20px;
				@include mq() {
					max-width: 100%;
					float: none;
					padding-right: 0;
				}
			}
			&__made{
				position: absolute;
				top: 25px;
				right: 20px;
			}
			&__img{
				@include mq() {
					position: relative;
					margin-bottom: 30px;
				}
			}
			.object{
				position: absolute;
				bottom: -100px;
				right: 50px;
				width: 205px;
				@include mq() {
					bottom: -40px;
					width: 102px;
					right: 15px;
				}
			}
		}
		.intro-block{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
		}
	}
}

.lp-content{
	.btn-block{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		justify-content: space-between;
		flex-wrap: wrap;
		.more-btn{
			max-width: 100%;
			width: 48%;
			font-family: $font-notoSerifJp;
			margin: 0;
			@include mq(lg) {
				width: 100%;
				margin-bottom: 15px;
			}
		}
	}
	.sheet-about{
		padding: 70px 0 60px;
		@include mq() {
			padding: 50px 0 40px;
		}
		.inner{
			position: relative;
		}
		.intro-block{
			position: relative;
			max-width: 1000px;
			margin: 0 auto 60px;
			align-items: center;
			@include mq() {
				margin-bottom: 40px;
			}
			&__img{
				width: 44.7%;
				z-index: 2;
				@include mq() {
					position: relative;
					width: 85%;
					margin: 0 auto 30px;
					img{
						width: 100%;
					}
				}
			}
			&__content{
				width: 55.3%;
				padding-left: 50px;
				position: relative;
				z-index: 2;
				@include mq() {
					width: 100%;
					padding-left: 0;
				}
			}
			&__ttl{
				font-family: $font-notoSerifJp;
				margin-bottom: 45px;
				@include mq() {
					margin-bottom: 30px;
				}
				small{
					display: block;
					@include font-size(21);
					@include mq() {
						@include font-size(16);
					}
				}
				span{
					display: inline;
					@include font-size(30);
					font-weight: bold;
					line-height: 1.6;
					background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, #ffdaad));
					background: -o-linear-gradient(transparent 60%, #ffdaad 60%);
					background: linear-gradient(transparent 60%, #ffdaad 60%);
					@include mq() {
						@include font-size(24);
					}
				}
			}
			&__sub-ttl{
				display: inline-block;
				@include font-size(18);
				font-family: $font-notoSerifJp;
				padding-bottom: 8px;
				border-bottom: 1px dotted #333;
				margin-bottom: 25px;
				@include mq() {
					@include font-size(16);
					margin-bottom: 15px
				}
			}
			&__txt{
				line-height: 1.9;
			}
			&__en{
				position: absolute;
				left: -30px;
				bottom: -50px;
				font-family: $font-crimson;
				font-weight: 400;
				@include font-size(100);
				color: #94d2c5;
				opacity: 0.15;
				font-weight: 900;
				z-index: 1;
				line-height: 1;
				@include mq() {
					@include font-size(40);
					left: 0;
					bottom: -30px;
				}
			}
		}
	}
	.sheet-choice{
		background: #94d2c5;
		padding: 90px 0 100px;
		@include mq() {
			padding: 50px 0 ;
		}
		.sec-ttl{
			color: #FFF;
			font-family: $font-notoSerifJp;
			.sub-ttl{
				color: #FFF;
				&::after,
				&::before{
					background: #FFF;
				}
			}
		}
		.lead{
			color: #FFF;
		}
		.choice-block{
			max-width: 1000px;
			margin: 0 auto 50px;
			background: #bee4dc;
			@include mq() {
				margin-bottom: 35px;
			}
			&__content{
				padding: 50px 70px;
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				@include mq() {
					padding: 30px 20px;
				}
			}
			&__ttl{
				width: 280px;
				font-family: $font-notoSerifJp;
				@include font-size(21);
				line-height: 1.7;
				@include mq() {
					width: 100%;
					@include font-size(18);
					margin-bottom: 15px;
				}
			}
			&__txt{
				width: calc(100% - 280px);
				padding-left: 70px;
				line-height: 1.8;
				@include mq() {
					width: 100%;
					padding-left: 0;
				}
			}
		}
		.circle-block{
			max-width: 1000px;
			margin: 0 auto 230px;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			justify-content: space-between;
			@include mq() {
				margin-bottom: 70px;
			}
			.item{
				position: relative;
				width: 23.5%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				color: #FFF;
				text-align: center;
				border-radius: 100%;
				@include mq() {
					width: 48.5%;
					margin-bottom: 20px;
				}
				&--01{
					background-image: url("../img/lp/index/circle-img01.png");
				}
				&--02{
					background-image: url("../img/lp/index/circle-img02.png");
				}
				&--03{
					background-image: url("../img/lp/index/circle-img03.png");
				}
				&--04{
					background-image: url("../img/lp/index/circle-img04.png");
				}
				&::after{
					content: "";
					display: block;
					padding-top: 100%;
				}
				&__ttl{
					@include font-size(21);
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					margin: auto;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					font-family: $font-notoSerifJp;
					@include mq() {
						@include font-size(15);
					}
					span{
						background: #482f32;
						display: inline-block;
						color: #FFF;
						border-radius: 20px;
						margin-bottom: 20px;
						padding: 5px 15px;
						font-family: $font-notoSanJp;
						@include font-size(18);
						@include mq() {
							@include font-size(13);
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		.point-block{
			position: relative;
			background: #FFF;
			margin-bottom: 215px;
			min-height: 355px;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			justify-content: space-between;
			@include mq() {
				flex-wrap: wrap;
				min-height: inherit;
				margin-bottom: 60px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			&::after{
				@include mq(md-min) {
					content: "";
					display: block;
					width: 19%;
					height: 100%;
					background: #94d2c5;
					position: absolute;
					top: 0;
					z-index: 1;
				}
			}
			&--left{
				@include mq(md-min) {
					margin-right: 45px;
				}
				&::after{
					@include mq(md-min) {
						left: 0;
					}
				}
				.point-block__content{
					@include mq(md-min) {
						padding-right: 170px;
						left: -25px;
					}
				}
				.point-block__en{
					@include mq(md-min) {
						right: 25px;
					}
				}
				.point-block__object{
					@include mq(md-min) {
						right: 45px;
					}
				}
				.point-block__txt{
					@include mq(md-min) {
						float: right;
						margin-right: 25px;
					}
				}
				
			}
			&--right{
				@include mq(md-min) {
					margin-left: 45px;
				}
				&::after{
					@include mq(md-min) {
						right: 0;
					}
				}
				.point-block__img{
					@include mq(md-min) {
						order: 2;
						position: absolute;
						right: 0;
					}
				}
				.point-block__content{
					@include mq(md-min) {
						order: 1;
						width: 100%;
						padding-left: 225px;
					}
				}
				.point-block__en{
					@include mq(md-min) {
						left: 25px;
					}
				}
				.point-block__object{
					@include mq(md-min) {
						left: 45px;
					}
				}
			}
			&__img{
				position: relative;
				z-index: 2;
				top: -125px;
				width: 43%;
				@include mq() {
					width: 100%;
					top: -35px;
					img{
						width: 100%;
					}
				}
			}
			&__content{
				position: relative;
				z-index: 3;
				width: 57%;
				top: -100px;
				@include mq() {
					width: 100%;
					top: -45px;
					padding: 0 20px;
				}
			}
			&__object{
				width: 135px;
				position: absolute;
				z-index: 1;
				top: 35px;
			}
			&__en{
				position: absolute;
				bottom: 10px;
				font-family: $font-crimson;
				@include font-size(100);
				color: #94d2c5;
				font-weight: 400;
				opacity: 0.15;
				line-height: 1;
				z-index: 1;
				@include mq() {
					@include font-size(40);
					left: 0;
				}
			}
			&__point{
				position: relative;
				background: #FFF;
				display: inline-block;
				padding: 5px 30px 5px 80px;
				border-radius: 20px;
				font-family: $font-notoSerifJp;
				@include font-size(18);
				margin-bottom: 30px;
				@include mq() {
					box-shadow: 0px 0px 9px -6px rgba(0,0,0,0.6);
				}
				span{
					position: absolute;
					top: 50%;
					margin-top: -33px;
					left: -5px;
					display: block;
					width: 67px;
					height: 67px;
					text-align: center;
					background: #FFF;
					color: #db3146;
					border-radius: 100px;
					@include font-size(36);
					font-family: $font-crimson;
					line-height: 1.2;
					padding-top: 10px;
					font-weight: 600;
					box-shadow: 0px 0px 9px -6px rgba(0,0,0,0.6);
					small{
						display: block;
						width: 100%;
						font-weight: 400;
						@include font-size(14);
					}
				}
			}
			&__ttl{
				margin-bottom: 25px;
				@include mq() {
					margin-bottom: 20px;
				}
				span{
					display: inline-block;
					background: #db3146;
					padding: 0 15px;
					color: #FFF;
					line-height: 1.6;
					font-family: $font-notoSerifJp;
					@include font-size(40);
					font-weight: bold;
					margin-bottom: 5px;
					@include mq(lg) {
						@include font-size(30);
					}
					@include mq() {
						@include font-size(19);
						padding: 0 10px;
					}
				}
			}
			&__txt{
				max-width: 290px;
				line-height: 1.8;
				@include mq() {
					max-width: 100%;
				}
			}
		}
	}
	.sheet-life{
		padding: 60px 0 95px;
		@include mq() {
			padding: 40px 0 0;
		}
		.sec-ttl{
			position: relative;
			font-family: $font-notoSerifJp;
			padding-top: 50px;
			&::before{
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 30%;
				background: url("../img/lp/index/start-ttl.png") no-repeat 0 0;
				background-size: 100% auto;
				width: 163px;
				height: 48px;
			}
			small{
				@include font-size(30);
				@include mq() {
					@include font-size(20);
				}
			}
		}
		.life-block{
			padding: 0 20px;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			justify-content: space-between;
			.item{
				width: 29.25%;
				text-align: center;
				@include mq() {
					width: 100%;
					margin-bottom: 30px;
				}
				&__img{
					position: relative;
				}
				&__ttl{
					display: inline-block;
					line-height: 1.6;
					background: -o-linear-gradient(transparent 60%, #ffdaad 60%);
					background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, #ffdaad));
					background: linear-gradient(transparent 60%, #ffdaad 60%);
					@include font-size(24);
					font-family: $font-notoSerifJp;
					font-weight: bold;
					margin-bottom: 20px;
					@include mq(lg) {
						@include font-size(18);
					}
				}
				&__content{
					background: url("../img/lp/index/life-bg.png") no-repeat 0 0;
					background-size: cover;
					padding: 40px 35px;
					@include mq() {
						padding: 35px 20px;
					}
				}
				&__desc{
					position: absolute;
					bottom: -40px;
					left: -15px;
					background: url("../img/lp/index/life-bl.png") no-repeat 0 0;
					background-size: cover;
					width: 126px;
					height: 117px;
					color: #f5b86e;
					font-weight: bold;
					line-height: 1.3;
					padding-top: 30px;
					@include font-size(18);
					@include mq() {
						@include font-size(16);
						padding-top: 35px;
					}
				}
				&__txt{
					text-align: left;
					@include font-size(14);
					line-height: 1.8;
				}
			}
		}
	}
	.sheet-info{
		padding: 60px 0 95px;
		@include mq() {
			padding: 30px 0 50px;
		}
		.ttl{
			position: relative;
			padding: 40px 0;
			background: #482f32;
			color: #FFF;
			text-align: center;
			background: #482f32;
			@include font-size(36);
			margin-bottom: 85px;
			@include mq() {
				@include font-size(24);
				padding: 25px 0;
				margin-bottom: 50px;
			}
			span{
				position: absolute;
				left: 0;
				right: 0;
				bottom: -35px;
				@include font-size(100);
				font-family: $font-crimson;
				font-weight: 600;
				opacity: 0.05;
				line-height: 1;
				@include mq() {
					@include font-size(40);
					bottom: -10px;
				}
			}
		}
		.intro-block{
			position: relative;
			max-width: 835px;
			margin: 0 auto 115px;
			@include mq() {
				margin-bottom: 70px;
			}
			&__img{
				width: 43.9%;
				position: relative;
				z-index: 2;
				@include mq() {
					width: 70%;
					margin: 0 auto ;
				}
			}
			&__content{
				width: 56.1%;
				max-width: 380px;
				padding-left: 50px;
				padding-top: 50px;
				position: relative;
				z-index: 2;
				@include mq() {
					width: 100%;
					padding-left: 0;
				}
			}
			&__ttl{
				font-family: $font-notoSerifJp;
				margin-bottom: 35px;
				@include mq() {
					margin-bottom: 20px;
				}
				span{
					display: inline-block;
					margin-bottom: 5px;
					@include font-size(23);
					padding: 0 10px;
					font-weight: bold;
					line-height: 1.7;
					display: inline;
    				background: #db3146;
					color: #FFF;
					@include mq() {
						@include font-size(18);
					}
				}
			}
			&__txt{
				line-height: 1.8;
			}
		}
	}
	.lineup{
		position: relative;
		background: #f3efea;
		padding: 65px 0 85px;
		text-align: center;
		@include mq() {
			padding: 40px 0 ;
		}
		&__ttl{
			display: inline-block;
			@include font-size(21);
			background: #FFF;
			border: 1px solid #c5c4c2;
			border-radius: 20px;
			font-weight: bold;
			width: 250px;
			position: absolute;
			height: 45px;
			line-height: 43px;
			top: -23px;
			left: 0;
			right: 0;
			margin: auto;
			@include mq() {
				@include font-size(18);
			}
		}
		&__ttl02{
			color: #FFF;
			@include font-size(36);
			font-family: $font-crimson;
			margin-bottom: 45px;
			@include mq() {
				@include font-size(24);
				margin-bottom: 25px;
			}
		}
		.lineup-item{
			padding: 45px 45px 70px;
			background: #FFF;
			text-align: left;
			margin-bottom: 50px;
			@include mq() {
				padding: 35px 25px 45px;
				margin-bottom: 35px;
			}
			&__head{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				align-items: center;
				margin-bottom: 30px;
				&.type02{
					.lineup-item__ttl{
						@include mq(md-min) {
							width: 40%;
						}
					}
					.lineup-item__spec{
						@include mq(md-min) {
							width: 60%;
							padding: 15px 20px;
						}
					}
				}
			}
			&__ttl{
				width: 50%;
				@include mq() {
					width: 100%;
					margin-bottom: 25px;
				}
				span{
					display: inline-block;
					border-bottom: 1px solid #333;
					@include font-size(24);
					font-family: $font-crimson;
					margin-bottom: 15px;
					@include mq() {
						@include font-size(18);
					}
				}
				strong{
					display: inline-block;
					vertical-align: middle;
					@include font-size(40);
					color: #db3146;
					font-family: $font-notoSerifJp;
					@include mq() {
						@include font-size(22);
					}
				}
				small{
					display: inline-block;
					vertical-align: middle;
					color: #db3146;
					@include font-size(21);
					margin-left: 10px;
					font-family: $font-crimson;
					@include mq() {
						@include font-size(16);
					}
				}
			}
			&__spec{
				width: 50%;
				border: 1px solid #dadada;
				padding: 15px 65px;
				@include font-size(14);
				@include mq() {
					width: 100%;
					padding: 10px 20px;
					@include font-size(12);
				}
				dt{
					float: left;
					width: 80px;
					background: #f0f0f0;
					text-align: center;
					margin-right: 15px;
					padding: 3px 0;
					@include mq() {
						float: none;
						margin-bottom: 10px;
					}
				}
				dd{
					overflow: hidden;
					padding-top: 3px;
				}
			}
			&__txt{
				margin-bottom: 40px;
			}
			&__list{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				margin-bottom: 50px;
				@include mq() {
					margin-bottom: 25px;
				}
				li{
					position: relative;
					width: 50%;
					@include mq() {
						width: 100%;
					}
					span{
						position: absolute;
						top: 20px;
						left: 20px;
						background: rgba(255,255,255,0.5);
						border-radius: 100px;
						width: 85px;
						height: 85px;
						display:-webkit-box; // old
						display:-moz-box; // old
						display:-ms-flexbox; // ie
						display:-webkit-flex; // new
						display:flex; // new
						align-items: center;
						justify-content: center;
						text-align: center;
						font-family: $font-notoSerifJp;
						font-weight: bold;
						@include font-size(16);
						@include mq() {
							width: 65px;
							height: 65px;
							@include font-size(12);
							top: 15px;
							left: 15px;
						}
					}
				}
			}
			&__price{
				@include font-size(30);
				color: #482f32;
				text-align: center;
				font-family: $font-notoSerifJp;
				font-weight: bold;
				margin-bottom: 35px;
				@include mq() {
					@include font-size(24);
					margin-bottom: 25px;
				}
				span{
					color: #db3146;
				}
				small{
					color: #db3146;
					@include font-size(18);
					@include mq() {
						@include font-size(14);
					}
				}
			}
			&__btn{
				max-width: 550px;
				text-align: center;
				margin: 0 auto;
				a{
					display: block;
					color: #FFF;
					border-radius: 50px;
					@include font-size(36);
					background: #db3146;
					font-family: $font-notoSerifJp;
					padding: 25px 0;
					@include mq() {
						@include font-size(20);
						padding: 20px 0;
					}
					i{
						margin-right: 10px;
					}
					&:hover{
						color: #FFF !important;
					}
				}
			}
			&__row{
				@include mq(md-min) {
					display:-webkit-box; // old
					display:-moz-box; // old
					display:-ms-flexbox; // ie
					display:-webkit-flex; // new
					display:flex; // new
					flex-wrap: wrap;
				}
				.item{
					@include mq(md-min) {
						width: 50%;
						padding: 0 15px;
					}
					@include mq() {
						&:first-of-type{
							margin-bottom: 30px;
						}
					}
					.lineup-item__price{
						br{
							display: block !important;
						}
					}
					.lineup-item__btn{
						@include mq(md-min) {
							max-width: 445px;
						}
					}
				}
			}
		}		
		.btn-block{
			margin-top: 90px;
			@include mq() {
				margin-top: 50px;
			}
		}
	}
	.sheet-type{
		padding-bottom: 125px;
		@include mq() {
			padding-bottom: 20px;
		}
		.sec-ttl{
			@include font-size(27);
			font-family: $font-notoSerifJp;
			@include mq() {
				@include font-size(20);
			}
		}
		.lead{
			max-width: inherit;
		}
		.type-block{
			max-width: 1000px;
			margin: 0 auto;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			justify-content: space-between;
			.item{
				width: 31.2%;
				text-align: center;
				@include mq() {
					width: 100%;
					margin-bottom: 35px;
				}
				&__ttl{
					color: #482f32;
					@include font-size(19);
					font-family: $font-notoSerifJp;
					padding: 25px 0 20px;
					@include mq() {
						@include font-size(16);
						padding: 15px 0;
					}
					span{
						font-weight: bold;
					}
				}
				&__btn{
					max-width: 550px;
					text-align: center;
					margin: 0 auto;
					a{
						display: block;
						color: #FFF;
						border-radius: 50px;
						@include font-size(18);
						background: #db3146;
						font-family: $font-notoSerifJp;
						line-height: 53px;
						i{
							margin-right: 10px;
						}
						&:hover{
							color: #FFF !important;
						}
					}
				}
			}
		}
	}
}