
.inner {
	display: block;
	width: 100%;
	max-width: 1230px;
	padding: 0 15px;
	margin: 0 auto;
}

.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a{
	color: inherit;
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
		color: inherit !important;
		text-decoration: none !important;
	}
	&[href^="tel"] {
		@include mq(ipd-min){
			pointer-events: none;
		}
	}
}


.pc{
	@include mq(){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs-min){
			display: none !important;
		}
	}
	&-375 {
		@media screen and (min-width: 376px) {
			display: none !important;
		}
	}
}


//ttl
.ttl-comp{
	&01{
		border-bottom: 1px solid #333333;
		display: flex;
		align-items: center;
		@include font-size(14);
		line-height: 1.4;
		padding-bottom: 13px;
		margin-bottom: 41px;
		@include mq {
			@include font-size(12);
			padding-bottom: 10px;
			margin-bottom: 20px;
		}
		.en {
			font-family: $font-lato;
			@include font-size(25);
			font-weight: bold;
			color: #db3146;
			margin-right: 18px;
			@include mq {
				@include font-size(20);
				margin-right: 10px;
			}
		}
	}
	&02{
		@include font-size(18);
		line-height: 1.2;
		@include mq(xl) {
			@include font-size(16);
		}
		@include mq {
			@include font-size(14);
		}
		@include mq(xs) {
			@include font-size(12);
		}
		.en {
			font-family: $font-lato;
			@include font-size(48);
			line-height: 1;
			font-weight: bold;
			color: #db3146;
			display: block;
			margin-bottom: 10px;
			@include mq(xl) {
				@include font-size(40);
			}
			@include mq {
				@include font-size(30);
			}
			@include mq(xs) {
				@include font-size(26);
			}
		}
		&--line {
			position: relative;
			margin-bottom: 36px;
			@include mq {
				margin-bottom: 30px;
			}
			&:before {
				position: absolute;
				content: '';
				height: 1px;
				background: #333333;
				left: 0;
				right: 0;
				top: 48.5%;
			}
			.ttl-comp02 {
				&__wrap {
					background: white;
					position: relative;
					z-index: 1;
					display: inline-flex;
					align-items: center;
					padding-right: 17px;
					@include mq(sm) {
						display: block;
						padding: 0;
					}
					.en {
						margin-right: 17px;
						margin-bottom: 0;
						@include mq(sm) {
							margin-right: 0;
							margin-bottom: 5px;
						}
					}
				}
			}
			.txt-more {
				position: absolute;
				padding-left: 15px;
				background: white;
				right: 0;
				top: 46%;
				transform: translateY(-50%);
				z-index: 1;
				@include mq(sm) {
					bottom: 0;
					top: auto;
					transform: translate(0);
					//position: inherit;
					//top: 0;
					//right: 0;
					//transform: none;
					//margin-top: 15px;
					//display: inline-block;
					//padding-left: 0;
				}
				&:hover{
					opacity: 1;
				}
			}
		}
		&--ico {
			display: flex;
			justify-content: center;
			text-align: center;
			margin-bottom: 50px;
			.ttl-wrap {
				position: relative;
				.ico {
					position: absolute;
					left: 0;
					top: -12px;
					transform: translateX(-124%);
				}
			}
		}
	}
	&03{
		padding-left: 20px;
		color: #000000;
		@include font-size(24);
		font-weight: 500;
		line-height: 1.33;
		margin-bottom: 31px;
		position: relative;
		@include mq {
			@include font-size(20);
			padding-left: 15px;
			margin-bottom: 20px;
		}
		&:before {
			content: '';
			position: absolute;
			width: 6px;
			top: 4px;
			bottom: 1px;
			left: 0;
			background-color: #db3146;
			z-index: 1;
			@include mq {
				width: 3px;
			}
		}
	}
	&04 {
		color: #ffffff;
		font-weight: 500;
		@include font-size(22);
		line-height: 1.46;
		padding: 9px 31px 11px;
		margin-bottom: 39px;
		@include mq {
			@include font-size(18);
			padding: 6px 15px 8px;
			margin-bottom: 30px;
		}
		&--green {
			background-color: #95d2c6;
		}
		&--yellow {
			background-color: #eabb82;
		}
		&--red {
			background-color: #e98585;
		}
		&--blue {
			background-color: #a5c0d2;
		}
	}
	&05 {
		color: #FFFFFF;
		text-align: center;
		font-family: $font-lato;
		font-weight: bold;
		@include font-size(30);
		@include mq {
			@include font-size(22);
		}
		small {
			display: block;
			font-family: $font-notoSanJp;
			font-weight: 400;
			@include font-size(14);
			margin: 11px 0 13px;
			@include mq {
				@include font-size(12);
				margin: 4px 0 2px;
			}
		}
	}
	&06 {
		text-align: center;
		color: #000000;
		font-weight: 500;
		@include font-size(24);
		margin-bottom: 59px;
		@include mq {
			@include font-size(20);
			margin-bottom: 30px;
		}
	}
	&07 {
		color: #000000;
		@include font-size(24);
		font-weight: 500;
		padding-bottom: 18px;
		border-bottom: 1px solid #000000;
		margin-bottom: 49px;
		@include mq {
			padding-bottom: 10px;
			@include font-size(20);
			margin-bottom: 30px;
		}
	}
}
.second-head{
	background: #db3146;
	height: 240px;
	color: #FFF;
	text-align: center;
	display:-webkit-box; // old
	display:-moz-box; // old
	display:-ms-flexbox; // ie
	display:-webkit-flex; // new
	display:flex; // new
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	@include mq(){
		height: 120px;
	}
	&__ttl{
		@include font-size(30);
		line-height: 1.2;
		@include mq(){
			@include font-size(20);
		}
		span{
			display: block;
			&.en{
				font-weight: bold;
			}
		}
		small{
			@include font-size(14);
		}
	}
}

//text
.txt{
	&-basic{
		line-height: 2;
		@include font-size(21);
		letter-spacing: -0.5px;
		@include mq(xl){
			@include font-size(18);
		}
		@include mq(){
			@include font-size(16);
			line-height: 1.6;
		}
		@include mq(xs){
			@include font-size(14);
		}
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-left {
		text-align: left;
	}
	&-more {
		@include font-size(18);
		font-weight: 500;
		@include mq(xl) {
			@include font-size(16);
		}
		@include mq() {
			@include font-size(14);
		}
		.fas {
			margin-left: 6px;
			@include font-size(17);
			@include mq(xl) {
				@include font-size(15);
			}
			@include mq() {
				@include font-size(13);
			}
		}
	}
}



//btn
.btn {
	&-comp03 {
		cursor: pointer;
		display: block;
		color: #ffffff;
		border: none;
		border-radius: 2px;
		text-align: center;
		font-weight: 400;
		@include font-size(18);
		line-height: 1.4;
		padding: 13px 10px 14px;
		background-color: #dd3042;
		display: block;
		font-family: $font-notoSanJp;
		border: none;
		cursor: pointer;
		@include smooth-transition;
		&:hover {
			opacity: 0.7;
			color: #FFF !important;
		}
		@include mq {
			@include font-size(16);
			padding: 8px 5px 10px;
		}
		@include mq(xs) {
			@include font-size(14);
		}
		&--md {
			@include mq(md-min) {
				padding: 18px 10px 19px;
			}
		}
		&--xl {
			@include font-size(24);
			padding: 48px;
			display: inline-block;
			width: 100%;
			border-radius: 3px;
			max-width: 610px;
			@include mq {
				padding: 20px;
			}
		}
		&--black {
			background-color: #333333;
		}
	}
	&-comp04 {
		display: inline-block;
		border-radius: 6px;
		color: #323232;
		@include font-size(16);
		background-color: #eeeeee;
		padding: 11px 25px 13px;
		@include mq {
			@include font-size(14);
			padding: 11px 25px 12px;
		}
		i {
			margin-left: 7px;
		}
	}
}


