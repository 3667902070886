.sec-contact {
	padding: 0 0 182px;
	@include mq {
		padding: 0 0 50px;
	}
}

.contact {
	&__btn {
		margin-bottom: 70px;
		text-align: right;
		@include mq {
			margin-bottom: 30px;
		}
	}
	&__txt {
		text-align: center;
		font-weight: 400;
		color: #323232;
		@include font-size(18);
		line-height: 2;
		margin-bottom: 72px;
		@include mq {
			@include font-size(15);
			margin-bottom: 50px;
		}
		@include mq(xs) {
			@include font-size(13);
		}
	}
}

.common-form {
	padding: 0 4.95%;
	@include mq(xl) {
		padding: 0 0;
	}
	.list {
		margin-bottom: 141px;
		@include mq {
			margin-bottom: 20px;
		}
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 37px;
			@include mq {
				flex-wrap: wrap;
				margin-bottom: 30px;
			}
			&__label {
				width: 27%;
				padding-left: 3.2%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include mq(xl) {
					padding-left: 0;
				}
				@include mq {
					width: 100%;
					margin-bottom: 15px;
					justify-content: flex-start;
				}
				span {
					font-weight: 400;
					color: #000000;
					@include font-size(16);
					padding-right: 15px;
					@include mq {
						@include font-size(14);
					}
				}
				small {
					display: inline-block;
					font-weight: 400;
					color: #db3146;
					@include font-size(16);
					padding: 1px 17px 3px;
					border-radius: 3px;
					border: 1px solid #db3146;
					@include mq {
						padding: 2px 17px;
						@include font-size(14);
					}
				}
			}
			&__content {
				width: 73%;
				padding-left: 69px;
				@include mq(lg) {
					padding-left: 40px;
				}
				@include mq {
					padding-left: 0;
					width: 100%;
				}
			}
			&__input {
				vertical-align: middle;
				border: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				width: 100%;
				border-radius: 3px;
				height: 57px;
				//line-height: 55px;
				border: 2px solid #dddddd;
				@include font-size(16);
				padding: 0 15px;
				color: #000000;
				@include mq {
					height: 47px;
					@include font-size(14);
					//line-height: 44px;
					border-width: 1px;
				}
				&::-ms-clear {
					display: none;
				}
				&::placeholder {
					color: #666666;
					opacity: 1;
				}
				&:-ms-input-placeholder {
					color: #666666;
				}
				&::-ms-input-placeholder {
					color: #666666;
				}
			}
		}
	}
	.capcha {
		text-align: center;
		margin-bottom: 68px;
		@include mq {
			margin-bottom: 30px;
		}
	}
}