/* ====================================================
Font
==================================================== */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&display=swap');


$font-notoSanJp: 'Noto Sans JP', sans-serif;
$font-notoSerifJp: 'Noto Serif JP', serif;
$font-lato: 'Lato', sans-serif;
$font-crimson: 'Crimson Text', serif;

@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / 10) + rem;
}


@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}



/* ====================================================
Media Quary
==================================================== */
@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

$breakpoint-sm: 640px !default;
$breakpoint-md: 834px !default;
$breakpoint-lg: 990px !default;

$breakpoints: (
		'xs': 'screen and (max-width: 374px)',
		'sm': 'screen and (max-width: 640px)',
		'md': 'screen and (max-width: 834px)',
		'lg': 'screen and (max-width: 990px)',
		'ipd': 'screen and (max-width: 1024px)',
		'xl': 'screen and (max-width: 1200px)',
		'lp': 'screen and (max-width: 1440px)',
		'mac': 'screen and (max-width: 1770px)',
		'xs-min': 'screen and (min-width: 375px)',
		'sm-min': 'screen and (min-width: 641px)',
		'md-min': 'screen and (min-width: 835px)',
		'lg-min': 'screen and (min-width: 991px)',
		'ipd-min': 'screen and (min-width: 1025px)',
		'xl-min': 'screen and (min-width: 1201px)',
		'lp-min': 'screen and (min-width: 1441px)',
		'mac-min': 'screen and (min-width: 1771px)',
) !default;


//marginPC and SP
@for $i from 1 through 100 {
	@include mq(md-min){
		.mbpc-#{$i} {
			margin-bottom: $i + px !important;
		}
	}
	@include mq(){
		.mbsp-#{$i} {
			margin-bottom: $i + px !important;
		}
	}
}