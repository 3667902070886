.sec-company {
	padding: 80px 0 208px;
	@include mq {
		padding: 10px 0 35px;
	}
}

.list-company-profile {
	border: 1px solid #e0e0e0;
	margin-bottom: 73px;
	@include mq {
		margin-bottom: 50px;
	}
	dl {
		color: #000000;
		font-weight: 400;
		@include font-size(16);
		line-height: 2;
		display: flex;
		border-bottom: 1px solid #e0e0e0;
		@include mq {
			@include font-size(14);
		}
		@include mq(xs) {
			@include font-size(13);
		}
		&:last-child {
			border-bottom: none;
		}
	}
	dt {
		padding: 23px 36px 24px;
		width: 24.8%;
		background-color: #f3f3f3;
		@include mq {
			min-width: 120px;
			padding: 15px;
		}
		@include mq(xs) {
			min-width: 100px;
			padding: 10px;
		}
	}
	dd {
		width: 75.2%;
		padding: 23px 50px 24px;
		@include mq {
			padding: 15px;
		}
		@include mq(xs) {
			padding: 10px;
		}
	}
}

.map-company {
	height: 656px;
	margin-bottom: 213px;
	@include mq {
		height: 328px;
		margin-bottom: 50px;
	}
	iframe {
		width: 100%;
		height: 100%;
	}
}

.heading-company {
	background-color: #eeeeee;
	padding: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 66px;
	@include mq {
		padding: 15px;
		margin-bottom: 35px;
	}
	.wrap {
		display: flex;
		align-items: center;
		color: #000000;
		font-weight: 400;
		@include font-size(16);
		line-height: 2;
		@include mq {
			@include font-size(14);
		}
	}
	img {
		flex-shrink: 0;
		margin-right: 30px;
		@include mq {
			margin-right: 15px;
		}
	}
}

.list-our-history {
	margin-bottom: 91px;
	@include mq {
		margin-bottom: 50px;
	}
	dl {
		color: #000000;
		@include font-size(16);
		font-weight: 400;
		line-height: 2;
		display: flex;
		border-bottom: 1px solid #e0e0e0;
		@include mq {
			@include font-size(14);
		}
	}
	dt {
		width: 16.5%;
		padding: 15px 38px 17px;
		@include mq {
			padding: 15px;
			min-width: 90px;
		}
	}
	dd {
		width: 83.5%;
		padding: 15px 38px 17px;
		@include mq {
			padding: 15px;
		}
	}
}

.image-company {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -16px;
	@include mq {
		margin: 0 -7px;
	}
	.item {
		text-align: center;
		padding: 0 16px;
		width: 33.33333%;
		margin-bottom: 32px;
		@include mq {
			padding: 0 7px;
			margin-bottom: 15px;
		}
		@include mq(sm) {
			width: 100%;
		}
	}
}