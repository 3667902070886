.sec-mv {
	z-index: 1;
	position: relative;
}
.mv {
	&-ttl {
		text-align: center;
		padding: 15px 35px 19px 15px;
		background: #db3146;
		color: white;
		letter-spacing: -0.4px;
		@include font-size(18);
		line-height: 1.6;
		@include mq(xl) {
			@include font-size(16);
			padding: 10px 15px;
		}
		@include mq() {
			@include font-size(14);
		}
		@include mq(xs) {
			@include font-size(12);
		}
		strong {
			font-weight: bold;
			margin: 0 10px 0 24px;
			letter-spacing: 1.25px;
		}
		.txt-lg {
			@include font-size(30);
			line-height: 1.2;
			@include mq(xl) {
				@include font-size(24);
				margin: 0 10px;
			}
			@include mq {
				@include font-size(20);
			}
			@include mq(sm) {
				margin: 0;
			}
			@include mq(xs) {
				@include font-size(18);
			}
		}
		.txt-lg02 {
			@include font-size(24);
			line-height: 1.4;
			@include mq(xl) {
				@include font-size(20);
			}
			@include mq() {
				@include font-size(17);
			}
			@include mq(xs) {
				@include font-size(15);
			}
		}
		small {
			@include font-size(16);
			line-height: 1.7;
			letter-spacing: 0;
			@include mq(xl) {
				@include font-size(14);
			}
			@include mq() {
				@include font-size(12);
			}
		}
	}
	&-slider{
		&:before, &:after {
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			width: 16.15%;
			z-index: 1;
			background: rgba(#3c3c3c, 0.5);
			@include mq {
				width: 8%;
			}
			@include mq(sm) {
				width: 40px;
			}
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 0;
		}
		.slick {
			&-arrow {
				z-index: 2;
				width: 21px;
				height: 33px;
				top: 49.6%;
				background-size: 100% auto;
				background-repeat: no-repeat;
				@include mq {
					width: 15px;
					height: 24px;
				}
				&:before {
					display: none;
				}
			}
			&-prev {
				left: 13.35%;
				background-image: url("../img/index/mv-arrow-prev.png");
				@include mq {
					left: 5%;
				}
				@include mq(sm) {
					left: 20px;
				}
			}
			&-next {
				right: 13.65%;
				background-image: url("../img/index/mv-arrow-next.png");
				@include mq {
					right: 5%;
				}
				@include mq(sm) {
					right: 20px;
				}
			}
			&-dots {
				bottom: -45px;
				@include mq {
					bottom: -30px;
				}
				li {
					margin: 0 11px 0 10px;
					@include mq(xl) {
						margin: 0 7px;
					}
					button {
						padding: 0;
						width: 14px;
						height: 14px;
						border-radius: 50%;
						background: #c8c8c8;
						@include mq {
							width: 10px;
							height: 10px;
						}
						&:before {
							display: none;
						}
					}
					&.slick-active {
						button {
							background: #666666;
						}
					}
				}
			}
		}
		
		&__item{
			outline: none !important;
			img {
				display: block;
				width: 100%;
			}
		}
	}
}

.sec-advertise {
	background: #eeeeee;
	padding: 180px 0 72px;
	overflow: hidden;
	@include mq {
		padding: 80px 0 50px;
	}
}
.advertise {
	&-sale {
		margin-bottom: 52px;
		@include mq {
			margin-bottom: 50px;
		}
		&__item {
			margin-bottom: 35px;
			@include mq {
				margin-bottom: 30px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&-list {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin: 0 -17.5px;
		@include mq(ipd) {
			margin: 0 -10px;
		}
		@include mq(sm) {
			margin: 0;
		}
		&__item {
			width: 33.33%;
			padding: 0 17.5px;
			margin-bottom: 35px;
			min-height: 240px;
			@include mq(ipd) {
				padding: 0 10px;
				margin-bottom: 20px;
			}
			@include mq(lg) {
				width: 50%;
			}
			@include mq() {
				width: 48%;
				padding: 0;
				margin-right: 4%;
				margin-bottom: 30px;
				&:nth-of-type(2n){
					margin-right: 0;
				}
			}
			&--second {
				.advertise-list__wrap {
					@include mq(md-min) {
						display: flex;
						align-items: center;
						justify-content: center;
						@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
							position: relative;
							display: block;
						}
					}
					.content {
						@include mq(md-min) {
							width: 90%;
							max-width: 298px;
							height: 63.5%;
							margin: 0 auto;
							text-align: center;
							align-items: center;
							justify-content: center;
							padding: 0 10px 10px 10px;
							@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
							.txt {
								min-height: auto;
								margin-bottom: 0;
							}
						}
						.txt{
							@include mq() {
								top: 6.5vw;
								position: relative;
							}
						}
					}
				}
			}
		}
		&__wrap {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
			@include mq() {
				overflow: hidden;
			}
			.img {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: -1;
				@include mq() {
					position: static;
				}
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}
			.content {
				width: 70%;
				max-width: 205px;
				margin-left: auto;
				display: flex;
				align-items: flex-end;
				padding: 10px 20px 35px 34px;
				height: 100%;
				flex-wrap: wrap;
				background: rgba(#94d2c5, 0.8);
				@include mq() {
					width: 100%;
					max-width: 100%;
					text-align: center;
					padding: 15px 15px 20px;
					display: block;
				}
				&--orange {
					background: rgba(#f5b86e, 0.8);
				}
				&--pink {
					background: rgba(#e06383, 0.8);
				}
				&--grayish {
					background: rgba(#a39b9b, 0.8);
				}
				&--blue {
					background: rgba(#95b6c9, 0.8);
				}
				&--red {
					background: rgba(#db3146, 0.8);
				}
				.txt {
					width: 100%;
					font-weight: bold;
					color: white;
					@include font-size(21);
					line-height: 1.43;
					margin-bottom: 20px;
					min-height: 96px;
					@include mq(xl) {
						@include font-size(18);
					}
					@include mq() {
						min-height: inherit;
					}
					small {
						@include font-size(16);
						line-height: 1.875;
						display: block;
						margin-bottom: 2px;
						@include mq(xl) {
							@include font-size(13);
						}
					}
					&-lg {
						@include font-size(25);
						line-height: 1.2;
						@include mq(xl) {
							@include font-size(21);
						}
					}
				}
				.btn {
					display: block;
					width: 100%;
					max-width: 137px;
					border: 1px solid white;
					text-align: center;
					font-family: $font-lato;
					@include font-size(16);
					font-weight: 500;
					color: white;
					line-height: 1.4;
					padding: 10px 10px 10px 10px;
					background: none;
					border-radius: 0;
					@include mq() {
						margin: 0 auto;
					}
					&:hover {
						color: white !important;
					}
				}
			}
		}
	}
}

.sec-making {
	padding: 94px 0 91px;
	@include mq {
		padding: 50px 0;
	}
}
.making {
	&-bnr {
		margin-bottom: 56px;
		@include mq(xl) {
			margin-bottom: 50px;
		}
		@include mq {
			margin-bottom: 20px;
		}
		.img {
			margin-bottom: 22px;
			@include mq {
				margin-bottom: 15px;
			}
		}
		.txt {
			@include font-size(21);
			font-weight: 500;
			text-align: center;
			@include mq(xl) {
				@include font-size(18);
			}
			@include mq {
				@include font-size(15);
			}
		}
	}
	&__item {
		margin-bottom: 141px;
		@include mq {
			margin-bottom: 50px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		.list-product {
			@include mq(sm) {
				margin-bottom: -30px;
			}
		}
	}
}
.recommend {
	&-box {
		&__item {
			margin-bottom: 70px;
			@include mq {
				margin-bottom: 50px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__head {
			display: flex;
			align-items: center;
			margin-bottom: 50px;
			@include mq {
				margin-bottom: 30px;
			}
			.txt-more {
				margin-left: auto;
			}
			.ttl {
				@include font-size(25);
				line-height: 1;
				font-weight: bold;
				display: flex;
				align-items: center;
				@include mq(xl) {
					@include font-size(21);
				}
				@include mq {
					@include font-size(17);
				}
				.en {
					margin-left: 10px;
					font-family: $font-lato;
					@include font-size(14);
					font-weight: 500;
					@include mq {
						@include font-size(12);
					}
				}
			}
		}
	}
}
//.ranking {
//	&-box {
//
//	}
//}
.list-product {
	&.list-product--ranking {
		counter-reset: ranking;
		.item {
			&__img {
				margin-bottom: 44px;
				@include mq {
					margin-bottom: 30px;
				}
				&:before {
					counter-increment: ranking;
					content: counter(ranking);
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					font-family: "Lato", sans-serif;
					@include font-size(24);
					font-weight: bold;
					line-height: 1;
					color: white;
					width: 48px;
					height: 48px;
					background: rgba(219, 49, 70, 0.9);
					border-radius: 50%;
					position: absolute;
					z-index: 1;
					bottom: -21px;
					left: -3px;
				}
			}
			&:first-child, &:nth-of-type(2), &:nth-of-type(3) {
				.item__img {
					&:before {
						border-radius: 0;
						background-color: transparent;
						width: 47px;
						background-position: top left;
						background-repeat: no-repeat;
						left: -1px;
						bottom: -18px;
						padding-top: 5px;
						height: 43px;
					}
				}
			}
			&:first-child {
				.item__img {
					&:before {
						background-image: url(../img/index/list-product-ranking-01.png);
					}
				}
			}
			&:nth-of-type(2) {
				.item__img {
					&:before {
						background: url(../img/index/list-product-ranking-02.png) no-repeat;
					}
				}
			}
			&:nth-of-type(3) {
				.item__img {
					&:before {
						background: url(../img/index/list-product-ranking-03.png) no-repeat;
					}
				}
			}
		}
	}
}

.sec-about {

}
.about {
	&-head {
		padding: 121px 0 139px;
		background: url("../img/index/about-head-bg.jpg") no-repeat;
		background-size: cover;
		@include mq(xl) {
			padding: 100px 0;
		}
		@include mq {
			padding: 50px 0;
		}
		&__box {
			width: 100%;
			max-width: 600px;
			.flex {
				display: flex;
				margin-bottom: 60px;
				@include mq(sm) {
					display: block;
					margin-bottom: 30px;
				}
				.social-list {
					display: flex;
					margin: 14px 1px 0 auto;
					@include mq {
						margin: 15px 0 0 0;
					}
					a{
						display: block;
						width: 52px;
						height: 52px;
						border-radius: 52px;
						display:-webkit-box; // old
						display:-moz-box; // old
						display:-ms-flexbox; // ie
						display:-webkit-flex; // new
						display:flex; // new
						flex-wrap: wrap;
						justify-content: center;
						align-items: center;
						color: #FFF;
						@include font-size(24);
						background: #698dbd;
						&:hover{
							color: #FFF;
						}
					}
					li {
						margin-left: 13px;
						&:nth-of-type(2){
							a{
								/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#912edd+0,fe2075+44,fdcd55+100 */
								background: #912edd; /* Old browsers */
								background: -moz-linear-gradient(top,  #912edd 0%, #fe2075 44%, #fdcd55 100%); /* FF3.6-15 */
								background: -webkit-linear-gradient(top,  #912edd 0%,#fe2075 44%,#fdcd55 100%); /* Chrome10-25,Safari5.1-6 */
								background: linear-gradient(to bottom,  #912edd 0%,#fe2075 44%,#fdcd55 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#912edd', endColorstr='#fdcd55',GradientType=0 ); /* IE6-9 */

							}
						}
						&:nth-of-type(3){
							a{
								background: #5bb5e3;
							}
						}
						&:nth-of-type(4){
							a{
								background: #d2122a;
							}
						}
					}
				}
			}
		}
	}
	
	&__body {
		background: #eeeeee;
		padding: 123px 0 0;
		@include mq(xl) {
			padding-top: 100px;
		}
		@include mq {
			padding-top: 50px;
		}
		.inner {
			padding-bottom: 280px;
			position: relative;
			z-index: 1;
			@include mq {
				padding-bottom: 210px;
			}
			.about-object {
				position: absolute;
				bottom: -3px;
				right: 32px;
				z-index: -1;
				@include mq(xl) {
					width: calc(100% - 30px);
					max-width: 360px;
				}
				@include mq(sm) {
					right: 15px;
				}
			}
		}
	}
	
	&-list {
		&__item {
			display: flex;
			flex-wrap: wrap;
			align-items: stretch;
			margin-bottom: 89px;
			@include mq {
				margin-bottom: 50px;
			}
			@include mq(sm) {
				display: block;
				width: 100%;
				max-width: 500px;
				margin: 0 auto 30px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:nth-child(even) {
				@include mq(sm-min) {
					flex-direction: row-reverse;
					.about-list__content {
						margin-left: 0;
						margin-right: auto;
						padding: 7px 0 0 0;
					}
				}
			}
		}
		&__img {
			width: 49.85%;
			@include mq(sm) {
				width: 100%;
				margin-bottom: 15px;
			}
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
		}
		&__content {
			width: 45.45%;
			margin-left: auto;
			padding: 19px 0 0 0;
			@include mq(xl) {
				width: 48%;
				padding: 0;
			}
			@include mq(sm) {
				width: 100%;
			}
			.ttl {
				@include font-size(25);
				line-height: 1.6;
				font-weight: bold;
				color: #db3146;
				margin-bottom: 29px;
				@include mq(xl) {
					@include font-size(21);
				}
				@include mq {
					@include font-size(18);
				}
				@include mq {
					margin-bottom: 15px;
				}
			}
		}
	}
}

.sec-review {
	padding: 160px 0 100px;
	@include mq(xl) {
		padding: 100px 0;
	}
	@include mq {
		padding: 50px 0;
	}
}
.review {
	&-list {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin: 0 -9px;
		@include font-size(18);
		line-height: 1.8;
		@include mq(xl) {
			@include font-size(16);
		}
		@include mq {
			@include font-size(14);
		}
		&__item {
			width: 33.33%;
			padding: 0 9px;
			margin-bottom: 22px;
			@include mq(lg) {
				width: 50%;
			}
			@include mq(sm) {
				width: 100%;
			}
		}
		&__wrap {
			border: 1px solid #dddddd;
			padding: 20px 36px 72px 29px;
			width: 100%;
			height: 100%;
			position: relative;
			@include mq(xl) {
				padding: 15px 15px 60px;
			}
		}
		&__head {
			display: flex;
			align-items: stretch;
			margin-bottom: 26px;
		}
		&__img {
			width: 121px;
			@include mq(xl) {
				width: 100px;
			}
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
		}
		&__r {
			width: calc(100% - 121px);
			padding: 7px 0 17px 23px;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			@include mq(xl) {
				width: calc(100% - 100px);
			}
			&:before {
				position: absolute;
				content: '';
				height: 1px;
				right: 0;
				left: 20px;
				background: #e7e7e7;
				bottom: 1px;
			}
			.ttl {
				display: block;
				display: -webkit-box;
				@include font-size(21);
				font-weight: bold;
				line-height: 1.45;
				height: 63px;
				max-width: 100%;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				@include mq(xl) {
					@include font-size(18);
					height: 55px;
				}
				@include mq {
					@include font-size(16);
					height: 49px;
				}
			}
			.evaluation {
				display: flex;
				white-space: nowrap;
				width: 100%;
				margin-top: auto;
				.txt {
					position: relative;
					top: 3px;
				}
				.start {
					display: flex;
					margin-left: auto;
					@include mq(sm) {
						margin-left: 0;
					}
					li {
						margin-left: 5px;
						@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
		&__txt {
			letter-spacing: -0.5px;
		}
		&__btn {
			@include font-size(24);
			position: absolute;
			bottom: 21px;
			left: 30px;
			right: 34px;
			background: #eeeeee;
			color: #bbbbbb;
			text-align: center;
			padding: 2px 10px 3px;
			line-height: 1;
			cursor: pointer;
			@include smooth-transition;
			@include mq(xl) {
				bottom: 15px;
				left: 15px;
				right: 15px;
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	&-popup {
		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(#333333, 0.29);
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		pointer-events: none;
		@include smooth-transition;
		&__wrap {
			width: 90%;
			max-width: 757px;
			max-height: 90%;
			background: white;
			position: relative;
			box-shadow: 0 0 13px rgba(#0b0508, 0.08);
		}
		&__content {
			display: block;
			width: 100%;
			max-height: 90vh;
			overflow-y: auto;
		}
		&__close {
			position: absolute;
			background: #bbbbbb;
			color: white;
			width: 59px;
			height: 59px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@include font-size(23);
			top: -29px;
			right: -31px;
			@include mq {
				width: 40px;
				height: 40px;
				right: -15px;
				top: -15px;
			}
		}
		&__item {
			display: none;
			&.active {
				display: block;
			}
		}
		&__list {
			width: 100%;
			padding: 57px 15px 135px;
			max-width: 672px;
			margin: 0 auto;
			@include mq {
				padding: 50px 15px;
			}
			.review-list {
				&__head {
					margin-bottom: 46px;
					@include mq {
						margin-bottom: 30px;
					}
				}
				&__img {
					width: 28.2%;
				}
				&__r {
					width: 68.6%;
					padding-top: 4px;
					display: block;
					.ttl {
						margin-bottom: -4px;
					}
					.evaluation {
						padding-left: 7px;
						.start {
							margin-left: -2px;
						}
					}
				}
			}
		}
		&.on {
			opacity: 1;
			pointer-events: auto;
		}
	}
}
